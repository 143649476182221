window.onbeforeunload = (e: BeforeUnloadEvent) => {
  // ...
};

const warnMessage = (e: BeforeUnloadEvent) => {
  const message = '入力内容が保存されない可能性があります。ページを離れますか？';
  e.preventDefault();
  e.returnValue = message;
  return message;
};

export function warnReloading() {
  window.addEventListener('beforeunload', warnMessage);
  window.onbeforeunload = (e: BeforeUnloadEvent) => {
    // ...
  };
}

export function removeWarnReloading() {
  window.removeEventListener('beforeunload', warnMessage);
  window.onbeforeunload = (e: BeforeUnloadEvent) => {
    // ...
  };
}

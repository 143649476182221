import ConnectionError from '../errors/connection-error';
import TimeoutError from '../errors/timeout-error';
import BackendClient from '../utils/backend-client';

/**
 * バックエンドを経由してCOGNITO_APIの呼出し処理を行うサービスクラス。
 */
export type CognitoBackendServiceRequestParams = {
  body?: any, // リクエスト内容
  endpoint?: string, // APIのエンドポイント（オリジンとバージョン以降を指定）
};

/**
 * バックエンドに関する処理を行うサービスクラス。
 */
export default class CognitoBackendService {
  /**
   * バックエンドのAPIへリクエストを送信する。
   * @param params リクエスト設定
   * @returns JSON形式のレスポンス
   */
  static request = async (params: CognitoBackendServiceRequestParams) => {
    try {
      // 送信
      const response = await BackendClient.httpPost({ body: params.body, endpoint: params.endpoint });
      // JSON変換
      const json = await response.json();
      if (!response.ok) {
        throw json;
      }
      return json;
    } catch (err) {
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        throw new ConnectionError({ from: 'FRONTEND' });
      } else if (err instanceof DOMException && err.name === 'AbortError') {
        throw new TimeoutError({ from: 'FRONTEND' });
      } else {
        throw err;
      }
    }
  }
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * ダイアログのステート
 */
export type DialogType = {
  open: boolean, // 開閉状態
  title: string, // タイトル
  message: string, // メッセージ
}

/**
 * アプリケーション全体のステート
 */
export type StateType = {
  loading: boolean, // ロード状態
  dialog: DialogType, // ダイアログのステート
};

// 初期値
export const initialState: StateType = {
  loading: false,
  dialog: {
    open: false,
    title: '',
    message: '',
  },
};

/**
 * アプリケーション全体のステートを管理する部品
 */
const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    /**
     * ロード状態を変更する。
     * @param state ステート
     * @param action アクション
     */
    isLoading: (state: StateType, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    /**
     * ダイアログのステートを変更する。
     * @param state ステート
     * @param action アクション
     * @returns ステート
     */
    updateDialog: (state: StateType, action: PayloadAction<DialogType>) => ({ ...state, dialog: action.payload }),

    /**
     * アプリケーションのステートを変更する。
     * @param state ステート
     * @param action アクション
     * @returns ステート
     */
    updateState: (state: StateType, action: PayloadAction<StateType>) => ({ ...state, ...action.payload }),

    /**
     * アプリケーションのステートを初期化する。
     * @param state ステート
     * @returns ステート
     */
    clearState: (state: StateType) => ({ ...state, ...initialState }),
  },
});

export const {
  isLoading,
  updateDialog,
  updateState,
  clearState,
} = slice.actions;

export default slice;

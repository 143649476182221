/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BorrowingStateType,
  ValidationErrorType,
  SimulationBankInfoType,
  SimulationTopType,
  simulationResultType,
  StateType,
  SimulationValidationErrorType,
  BorrowingResultType,
  BorrowingConfirmType,
} from './types';

// 初期値
const initialState: StateType = {
  borrowing: {
    会員番号: '',
    口座枝番: '',
    商品名: '',
    利用可能額: '',
    残高: '',
    毎回額: '',
    ＷＥＢ明細対象: '',
    自宅住所漢字: '',
    勤務先住所漢字: '',
    銀行名漢字: '',
    支店名漢字: '',
    口座番号: '',
    預金種目: '',
    振込年月日: '',
    振込時間: '',
    メールアドレス: '',
    処理日時: '',
  },
  borrowingConfirm: {
    借入後の残高: '',
    借入後の毎回額: '',
    メールアドレス: '',
    処理日時: '',
  },
  borrowingResult: {
    振込年月日: '',
    振込時間: '',
    処理日時: '',
  },
  validationError: {
    振込金額: '',
    メールアドレス: '',
    メールアドレス確認: '',
    送付先区分: '',
  },
  requestedAmountOfMoney: '',
  transferPersonNameClassification: '',
  usageStatementDeliveryAddress: '1',
  completionDeliveryMailAddress: '',
  completionDeliveryMailAddressConfirm: '',
  simulationValidationError: {
    入金額: '',
    入金回数: '',
  },
  simulationTop: {
    会員番号: '',
    口座情報: [],
  },
  simulationBankInfo: {
    口座枝番: '',
    商品名: '',
    約定利率: '',
    毎回額: '',
    入金回数: '',
    残高: '',
    支払区分: '',
    完済金額: '',
    契約年月日: '',
    契約額: '',
    損害金利率: '',
    約定日: '',
  },
  simulationResult: {
    会員番号: '',
    口座枝番: '',
    毎回額: '',
    完済金額: '',
    返済回数: '',
    入金額: '',
    入金回数: '',
    残り返済回数: '',
    支払利息額合計: '',
    返済完了予定日: '',
    返済予定表: [],
  },
  simulationMoney: '',
  simulationNumber: '',
  isCheckScheduleOfRepaymentDate: false,
  isChangeMailAddress: false,
};

const slice = createSlice({
  name: 'borrowing',
  initialState,
  reducers: {
    updateBorrowing: (state: StateType, action: PayloadAction<BorrowingStateType>) => {
      state.borrowing = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateBorrowingConfirm: (state: StateType, action: PayloadAction<BorrowingConfirmType>) => {
      state.borrowingConfirm = action.payload;
    },
    updateBorrowingResult: (state: StateType, action: PayloadAction<BorrowingResultType>) => {
      state.borrowingResult = action.payload;
    },
    updateRequestedAmountOfMoney: (state: StateType, action: PayloadAction<string>) => {
      state.requestedAmountOfMoney = action.payload;
    },
    updateUsageStatementDeliveryAddress: (state: StateType, action: PayloadAction<string>) => {
      state.usageStatementDeliveryAddress = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateTransferPersonNameClassification: (state: StateType, action: PayloadAction<string>) => {
      state.transferPersonNameClassification = action.payload;
    },
    updateIsCheckScheduleOfRepaymentDate: (state: StateType, action: PayloadAction<boolean>) => {
      state.isCheckScheduleOfRepaymentDate = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateProcessingDateTime: (state: StateType, action: PayloadAction<string>) => {
      state.borrowing.処理日時 = action.payload;
    },
    clearBorrowingInputValue: (state: StateType) => {
      state.requestedAmountOfMoney = initialState.requestedAmountOfMoney;
      state.usageStatementDeliveryAddress = initialState.usageStatementDeliveryAddress;
      state.transferPersonNameClassification = initialState.transferPersonNameClassification;
      state.completionDeliveryMailAddress = initialState.completionDeliveryMailAddress;
      state.completionDeliveryMailAddressConfirm = initialState.completionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    // 返済シミュレータ
    updateSimulationValidationError: (state: StateType, action: PayloadAction<SimulationValidationErrorType>) => {
      state.simulationValidationError = action.payload;
    },
    clearSimulationValidationError: (state: StateType) => {
      state.simulationValidationError = initialState.simulationValidationError;
    },
    updateSimulationTop: (state: StateType, action: PayloadAction<SimulationTopType>) => {
      state.simulationTop = action.payload;
    },
    updateSimulationBankInfo: (state: StateType, action: PayloadAction<SimulationBankInfoType>) => {
      state.simulationBankInfo = action.payload;
    },
    updateSimulationResult: (state: StateType, action: PayloadAction<simulationResultType>) => {
      state.simulationResult = action.payload;
    },
    updateSimulationMoney: (state: StateType, action: PayloadAction<string>) => {
      state.simulationMoney = action.payload;
    },
    updateSimulationNumber: (state: StateType, action: PayloadAction<string>) => {
      state.simulationNumber = action.payload;
    },
  },
});

export default slice;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StateType = {
  directAccess: boolean,
  preURL: string,
};

// 初期値
export const initialState: StateType = {
  directAccess: false,
  preURL: '',
};

const slice = createSlice({
  name: 'transition',
  initialState,
  reducers: {
    updateDirectAccess: (state: StateType, action: PayloadAction<boolean>) => {
      state.directAccess = action.payload;
    },
    clearDirectAccess: (state: StateType) => {
      state.directAccess = initialState.directAccess;
    },
    updatePreURL: (state: StateType, action: PayloadAction<string>) => {
      state.preURL = action.payload;
    },
    clearPreURL: (state: StateType) => {
      state.preURL = initialState.preURL;
    },
  },
});

export const {
  updateDirectAccess,
  clearDirectAccess,
  updatePreURL,
  clearPreURL,
} = slice.actions;

export default slice;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  JType,
  StateType,
  ValidationErrorType,
  mouthResistrationComplateType,
  automaticWithdrawalChangeComplateType,
  InternetBankAccountReceptionServiceInfoType,
  ConfirmValuesType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    約定日: '',
    次回支払年月日: '',
    自振請求日: '',
    自振請求金額: '',
    自振漢字銀行名: '',
    自振現状区分: '',
    自振状況区分: '',
    メールアドレス: '',
    口座数: '',
    最大遅れ: '',
    新サービス一連番号: '',
  },
  inputCompletionDeliveryMailAddress: '',
  inputCompletionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  inputTradeDate: '',
  validationError: {
    引落し日: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  confirmValues: {
    会員番号: '',
    メールアドレス: '',
    引落日: '',
    新サービス一連番号: '',
    次回支払年月日: '',
    初回引落し予定日: '',
    初回引落し金額: '',
  },
  mouthResistrationComplate: {
    口座枝番: '',
    引落日: '',
    メールアドレス: '',
    kfresponse: '',
    自振引落金額: '',
    自振次回引落日: '',
    次回支払年月日: '',
  },
  automaticWithdrawalChangeComplate: {
    口座枝番: '',
    kfresponse: '',
    自振現状区分: '',
    自振引落金額: '',
    自振次回引落日: '',
    引落日: '',
  },
  internetBankAccountReceptionServiceInfo: {
    ＷＥＢ自振一連番号: '',
    接続先URL: '',
    kfrequest: '',
  },
  returnKfresponse: '',
  forJ004Flag: '',
};

const slice = createSlice({
  name: 'j',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<JType>) => {
      state.top = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateTradeDate: (state: StateType, action: PayloadAction<string>) => {
      state.inputTradeDate = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateConfirmValues: (state: StateType, action: PayloadAction<ConfirmValuesType>) => {
      state.confirmValues = action.payload;
    },
    // 自動引落しのお申込み完了
    updateMouthResistrationComplate: (state: StateType, action: PayloadAction<mouthResistrationComplateType>) => {
      state.mouthResistrationComplate = action.payload;
    },
    // 自動引落しの引落し日変更完了
    updateAutomaticWithdrawalChangeComplate: (state: StateType, action: PayloadAction<automaticWithdrawalChangeComplateType>) => {
      state.automaticWithdrawalChangeComplate = action.payload;
    },
    updateInternetBankAccountReceptionServiceInfo: (state: StateType, action: PayloadAction<InternetBankAccountReceptionServiceInfoType>) => {
      state.internetBankAccountReceptionServiceInfo = action.payload;
    },
    clearInputValues: (state: StateType) => {
      state.inputCompletionDeliveryMailAddress = initialState.inputCompletionDeliveryMailAddress;
      state.inputCompletionDeliveryMailAddressConfirm = initialState.inputCompletionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
      state.inputTradeDate = initialState.inputTradeDate;
    },
    clearinternetBankAccountReceptionServiceInfo: (state: StateType) => {
      state.internetBankAccountReceptionServiceInfo = initialState.internetBankAccountReceptionServiceInfo;
    },
    // kfresponse
    updateReturnKfresponse: (state: StateType, action: PayloadAction<string>) => {
      state.returnKfresponse = action.payload;
    },
    clearReturnKfresponse: (state: StateType) => {
      state.returnKfresponse = initialState.returnKfresponse;
    },
    // J004の初期処理用のフラグ
    updateForJ004Flag: (state: StateType, action: PayloadAction<string>) => {
      state.forJ004Flag = action.payload;
    },
    clearForJ004Flag: (state: StateType) => {
      state.forJ004Flag = initialState.returnKfresponse;
    },
  },
});

export default slice;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import {
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { isLoading } from '../../modules/app';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { handleCommonError } from '../../utils/handle-errors';
import { updateStylePattern, updateViewPattern } from '../../modules/pattern';
import xSlice from '../../modules/x-login/module';
import topSlice from '../../modules/t-top/module';
import { loadJson } from '../../utils/load-json';

import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import userSlice from '../../modules/user';
import SfApiException from '../../errors/sf-api-exception';

import CognitoBackendService from '../../services/cognito-backend-service';
import AwsAuthService from '../../services/aws-auth-service';
import { isKonyApp } from '../../utils/device';
import { transferToNativeMethod } from '../../utils/transferToNativeMethod';

let cognitoUser: CognitoUser;
let sessionUserAttributes: any;

// X011
const SettingIDAndPasswordComplete: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const nextUrl = new URLSearchParams(location.search).get('next');

  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const {
    loggedIn,
    deviceInfo,
    memberInfo,
  } = useSelector((state: RootState) => state.user);
  const {
    applicationParams,
    tempCognitoUserInfo,
  } = useSelector((state: RootState) => state.xLogin);

  const {
    updateCognitoUserInfo,
  } = xSlice.actions;

  const {
    informations,
  } = useSelector((state: RootState) => state.top);

  const {
    updateTop,
    updateBankInfo,
    updateInformations,
  } = topSlice.actions;

  const {
    updateLoggedIn,
    updateDeviceInfo,
    updateLoginInfo,
    updateTempLoginInfo,
    updateMemberInfo,
  } = userSlice.actions;

  const [errorMessage, setErrorMessage] = useState('');

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  useEffect(() => {
    initiate();
    return () => {
      dispatch(isLoading(false));
    };
  }, []);

  const initiate = async () => {
    await loadInformations();
  };

  const loadInformations = async () => {
    const jsonData = await loadJson('/notice/information.json');
    dispatch(updateInformations(jsonData));
  };

  const handleClickHome = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    if (isKonyApp()) {
      const transferInfo = [];
      transferInfo.push(tempCognitoUserInfo.userName, tempCognitoUserInfo.password);
      transferToNativeMethod('LOGIN_TO_APPHOME', transferInfo);
    } else {
      if (!tempCognitoUserInfo.userName) {
        history.push(urls.LOGIN);
        return;
      }
      if (!tempCognitoUserInfo.password) {
        history.push(urls.LOGIN);
        return;
      }
      await cognitoLogin(tempCognitoUserInfo.userName, tempCognitoUserInfo.password);
    }
  };

  /**
   * ログインユーザーの情報と端末情報を確認する。
   */
  const loginUser = async (session: any, loginId: string, loginPassword: string) => {
    // IEのトークが取得できない問題の対応
    dispatch(updateMemberInfo({ ...memberInfo, memberNo: await AwsAuthService.getUsername(), token: session.getIdToken().getJwtToken() }));

    // 認証コードの送信
    try {
      const apiCommon: ApiCommon = new ApiCommon('X001', 'X-02', urls.X002, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '');
      // const dataPart = await apiCommon.getLoginInfoDataPart(tempLoginInfo.tempLoginId, tempLoginInfo.tempPassword);
      const dataPart = await apiCommon.getLoginInfoDataPart(loginId, loginPassword);
      if (applicationParams) {
        // アプリ固有 ID が渡されている場合はそれをデータ部にセットする
        const keys = Object.keys(applicationParams);
        for (let i = 0, l = keys.length; i < l; i += 1) {
          // @ts-ignore
          dataPart.データ部[keys[i]] = applicationParams[keys[i]];
        }
      }

      const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: json.共通部.配信ID, fromUrl: urls.X002 }));
      dispatch(updateViewPattern(json.共通部.ABテストパターン判定));
      dispatch(updateLoginInfo(json.データ部));
      dispatch(updateTempLoginInfo({
        tempLoginId: '',
        tempPassword: '',
      }));

      // Cognito のユーザー情報を保持しておく
      dispatch(updateCognitoUserInfo({
        memberId: tempCognitoUserInfo.memberId,
        userName: loginId,
        lastLoginDate: '',
      }));

      // 処理日時を Cognito に保存
      await setLastLoginDateTime(tempCognitoUserInfo.memberId, json.共通部.処理日時);
      // ログイン失敗回数クリア
      await setFailureCount(tempCognitoUserInfo.memberId, 0);

      // ログインステータスを更新しレンダリング
      dispatch(updateLoggedIn(true));

      if (window.ReactNativeWebView) {
        // アプリ用にトップの情報を取得してレスポンスの JSON を postMessage
        const postJson = await nativeTopUserInfo(json.共通部.ABテストパターン判定);
        dispatch(updateTop({
          ...postJson.データ部,
          処理日時: postJson.共通部.処理日時,
        }));
        dispatch(updateBankInfo(postJson.データ部.口座情報[0]));
        window.ReactNativeWebView.postMessage(JSON.stringify(postJson));
        // return postJson;
      } else {
        if (json.データ部.和解客区分 === '1' || json.データ部.ログイン停止区分 === '1') {
          history.push(urls.Q001);
          return;
        }
        history.push(urls.TOP);
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * Cognitoへの認証処理。
   */
  const cognitoLogin = async (loginId: string, loginPassword: string) => {
    dispatch(isLoading(true));
    const awsAuthService = new AwsAuthService();
    const authenticationDetails = new AuthenticationDetails({
      Username: loginId,
      Password: loginPassword,
      ClientMetadata: { authKey: loginId },
    });
    cognitoUser = new CognitoUser({
      Username: loginId,
      Pool: awsAuthService.userPool,
      Storage: sessionStorage,
    });
    cognitoUser.setAuthenticationFlowType(AwsAuthService.AUTH_FLOW.USER_SRP_AUTH);
    cognitoUser.authenticateUser(authenticationDetails, {
      async onSuccess(session) {
        // 認証成功
        loginUser(session, loginId, loginPassword)
          .catch((err) => {
            handleErrors(err);
          });
      },
      async onFailure(err: any) {
        // 認証失敗
        setErrorMessage(err.message);
        dispatch(isLoading(false));
      },
      newPasswordRequired(userAttributes: any) {
        // 初回ログインの仮パスワード変更時
        sessionUserAttributes = userAttributes;
        delete sessionUserAttributes.email_verified;
        history.push({ pathname: urls.X002, state: [loginId, loginPassword] });
      },
    });
  };

  /**
   * cognitoの前回ログイン時間を更新する。
   */
  const setLastLoginDateTime = async (memberId: string, time: string) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: memberId,
          customAttributeValue: time || '',
          customAttribute: process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_1,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
  };

  /**
   * Cognitoのログイン失敗回数をセットする
   */
  const setFailureCount = async (memberId: string, failureCount: number) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: memberId,
          customAttributeValue: failureCount.toString(),
          customAttribute: process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_2,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
    return failureCount;
  };

  /**
   * エラー用ハンドラー
   * @param err Error
   */
  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  const nativeTopUserInfo = async (viewPattern: string) => {
    try {
      dispatch(isLoading(true));
      const apiCommon: ApiCommon = new ApiCommon('T001', 'T-10', urls.X002, viewPattern);
      const commonPart = await apiCommon.getCommonPart();
      let dataPart;
      if (informations !== null) {
        dataPart = await apiCommon.getUserInfoNativeDataPart(informations.重要なお知らせ更新日時, informations.お知らせ更新日時);
      } else {
        dataPart = await apiCommon.getUserInfoNativeDataPart('', '');
      }
      return await McsBackendService.request(Object.assign(commonPart, dataPart));
    } catch (err) {
      // handleErrors(err);
      return '';
    } finally {
      dispatch(isLoading(false));
    }
  };

  // ネイティブアプリからのアクセス用
  if (window.ReactNativeWebView) {
    return (
      <>
        <Route component={Header} />
        <main className="l-main">
          <section>
            <div className="blockMain mb05gd">
              <div className="blockNarrow">
                <div className="ttlH1 mtFLAT mbFLAT">
                  <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                  <h1>ID/パスワード設定完了</h1>
                </div>
              </div>
            </div>
            <div className="blockSub mb05gd mtFLAT">
              <div className="blockNarrow">
                <p className="mtFLAT mbXL">
                  メールまたはSMSで完了通知を送付しました。<br />
                  「ログイン」ボタンより、会員ページをご利用ください。
                </p>
                <p className="error-comment">{errorMessage}</p>
                <div className="mbFLAT flexC">
                  <a href="/" className="btn btn--cv" onClick={handleClickHome}>ログイン</a>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Route component={Footer} />
      </>
    );
  }

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>ID/パスワード設定完了</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd mtFLAT">
                <div className="blockNarrow">
                  <p className="mtFLAT mbXL">
                    メールまたはSMSで完了通知を送付しました。<br />
                    「ログイン」ボタンより、会員ページをご利用ください。
                  </p>
                  <p className="error-comment">{errorMessage}</p>
                  <div className="mbFLAT flexC">
                    <a href="/" className="btn btn--cv" onClick={handleClickHome}>ログイン</a>
                  </div>
                </div>
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default SettingIDAndPasswordComplete;

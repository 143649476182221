import * as AWS from 'aws-sdk';
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

AWS.config.region = process.env.REACT_APP_COGNITO_REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '',
});

/**
 * AWS認証に関する処理を行うサービスクラス。
 */
export default class AwsAuthService {
  userPool: CognitoUserPool;

  cognitoUser?: CognitoUser;

  static AUTH_FLOW = {
    USER_SRP_AUTH: 'USER_SRP_AUTH',
    REFRESH_TOKEN_AUTH: 'REFRESH_TOKEN_AUTH',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    CUSTOM_AUTH: 'CUSTOM_AUTH',
    ADMIN_NO_SRP_AUTH: 'ADMIN_NO_SRP_AUTH',
    USER_PASSWORD_AUTH: 'USER_PASSWORD_AUTH',
    ADMIN_USER_PASSWORD_AUTH: 'ADMIN_USER_PASSWORD_AUTH',
  };

  constructor() {
    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
      Storage: sessionStorage,
    });
  }

  /**
   * CognitoUserのインスタンスを生成する。
   * @param username ユーザ名
   * @returns CognitoUserのインスタンス
   */
  createCognitoUser(username: string) {
    return new CognitoUser({
      Username: username,
      Pool: this.userPool,
      Storage: sessionStorage,
    });
  }

  /**
   *
   * @returns
   */
  static async signOut() {
    const cognitoUser = await AwsAuthService.getCurrentUser();
    if (cognitoUser === null) return;
    cognitoUser.signOut();
  }

  /**
   * ログイン中のユーザを取得する。
   * @returns ログイン中のユーザ
   */
  static async getCurrentUser() {
    const cognitoUser = await AwsAuthService.createCognitoUserPool().getCurrentUser();
    cognitoUser?.getSession(() => { });
    return cognitoUser;
  }

  static async getUsername() {
    const cognitoUser = await AwsAuthService.getCurrentUser();
    return cognitoUser?.getUsername() || '';
  }

  static async getWebId() {
    const cognitoUser = await AwsAuthService.getCurrentUser();
    return new Promise((resolve, reject) => {
      cognitoUser?.getUserAttributes((err, result) => {
        if (result !== null && result !== undefined) {
          for (let i = 0; i < result.length; i += 1) {
            const element = result[i];
            if (element.Name === 'preferred_username') {
              resolve(element.Value);
            }
          }
        } else {
          const str = 'Unknown';
          reject(str);
        }
      });
    });
  }

  /**
   * ログアウトする。
   * @returns 処理結果
   */
  static async globalSignOut() {
    const cognitoUser = await AwsAuthService.getCurrentUser();
    return new Promise((resolve, reject) => {
      cognitoUser?.globalSignOut({
        onSuccess(msg: string) {
          resolve(msg);
        },
        onFailure(err: Error) {
          reject(err);
        },
      });
    });
  }

  /**
   * ログイン中のユーザからIDトークンを取得する。
   * @returns IDトークン
   */
  static async getIdToken(): Promise<string> {
    const userPool = await AwsAuthService.createCognitoUserPool();
    const cognitoUser = userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (!cognitoUser) {
        reject(new Error('CognitoUser is null.'));
        return;
      }
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(session.getIdToken().getJwtToken());
      });
    });
  }

  static createCognitoUserPool() {
    return new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
      Storage: sessionStorage,
    });
  }

  /**
   * ログイン中のユーザによるパスワードの変更を行う。
   * @param oldPassword 現在のパスワード
   * @param newPassword 新しいパスワード
   * @returns 処理結果
   */
  static async changePassword(oldPassword: string, newPassword: string) {
    const cognitoUser = await AwsAuthService.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (!cognitoUser) {
        resolve(null);
        return;
      }
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }

  /**
   * ログイン中であるか判断する。ログイン中の場合はtrue、それ以外はfalseを返す。
   * @returns 真偽値
   */
  static async isAuthenticated(): Promise<boolean> {
    const cognitoUser = await AwsAuthService.getCurrentUser();
    return new Promise((resolve) => {
      if (cognitoUser === null) {
        resolve(false);
        return;
      }
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) return resolve(false);
        if (session.isValid()) return resolve(true);
        return resolve(false);
      });
    });
  }
}

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import urls from '../utils/urls';

// 依頼済み 画面
const Analytics: React.FC = () => {
  const location = useLocation();
  let dataLayerTag: HTMLScriptElement;
  let gtmTag: HTMLScriptElement;
  let mailCheckTag: HTMLScriptElement;
  let gtmNoScriptTag: HTMLElement;
  const isTop = location.pathname === urls.TOP;
  const xUrls = ['/', '/X002', '/X003', '/X004', '/X005', '/X006', '/X007', '/X008', '/X009', '/X010', '/X011', '/X012', '/X013', '/X014', '/X015', '/X016', '/X017'];
  const isLogin = xUrls.includes(location.pathname);
  const isMailForm = location.pathname === urls.K002;
  useEffect(() => {
    if (isTop) {
      // scriptタグ作成
      dataLayerTag = document.createElement('script');
      gtmTag = document.createElement('script');
      // noscriptタグ作成
      gtmNoScriptTag = document.createElement('noscript');
      // タグの内容
      const dataLayerScript = document.createTextNode(`
      // dataLayer の初期化処理
      window.dataLayer = window.dataLayer || [];
      // 会員ログイン状態計測用 dataLayer
      dataLayer.push({
      'login': '会員'
      });`);
      dataLayerTag.appendChild(dataLayerScript);

      const gtmScript = document.createTextNode(`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MVLRRRV');`);
      gtmTag.appendChild(gtmScript);

      gtmNoScriptTag.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVLRRRV"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
      // フラグメントにタグを追加していく
      const fragment = document.createDocumentFragment();
      fragment.appendChild(dataLayerTag);
      fragment.appendChild(gtmTag);
      fragment.appendChild(gtmNoScriptTag);
      // bodyに挿入
      const body = document.getElementsByTagName('body')[0] as HTMLElement;
      body.appendChild(fragment);
    } else if (isLogin) {
      gtmTag = document.createElement('script');
      gtmNoScriptTag = document.createElement('noscript');
      const gtmScript = document.createTextNode(`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MVLRRRV');`);
      gtmTag.appendChild(gtmScript);

      gtmNoScriptTag.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVLRRRV"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
      const fragment = document.createDocumentFragment();
      fragment.appendChild(gtmTag);
      fragment.appendChild(gtmNoScriptTag);
      const body = document.getElementsByTagName('body')[0] as HTMLElement;
      body.appendChild(fragment);
    } else {
      gtmTag = document.createElement('script');
      if (isMailForm) {
        mailCheckTag = document.createElement('script');
        mailCheckTag.type = 'text/javascript';
        mailCheckTag.src = '//formassist.jp/FormAssist_tag2.js?user=shinseif02&num=7001';
      }
      gtmNoScriptTag = document.createElement('noscript');
      const gtmScript = document.createTextNode(`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MVLRRRV');`);
      gtmTag.appendChild(gtmScript);

      gtmNoScriptTag.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVLRRRV"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
      const fragment = document.createDocumentFragment();
      fragment.appendChild(gtmTag);
      fragment.appendChild(gtmNoScriptTag);
      if (isMailForm) {
        fragment.appendChild(mailCheckTag);
      }
      const body = document.getElementsByTagName('body')[0] as HTMLElement;
      body.appendChild(fragment);
    }

    return () => {
      if (isTop) {
        document.body.removeChild(dataLayerTag);
        document.body.removeChild(gtmTag);
        document.body.removeChild(gtmNoScriptTag);
      } else if (isLogin) {
        document.body.removeChild(gtmTag);
        document.body.removeChild(gtmNoScriptTag);
      } else {
        document.body.removeChild(gtmTag);
        document.body.removeChild(gtmNoScriptTag);
        if (mailCheckTag) {
          document.body.removeChild(mailCheckTag);
        }
      }
    };
  }, [isTop, isMailForm]);

  return (<></>);
};

export default Analytics;

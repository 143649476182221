import Joi from 'joi';
import AbstractError from '../errors/abstract-error';
import ValidationError, { ValidationErrorDetail } from '../errors/validation-error';
import { updateDialog } from '../modules/app';
import urls from './urls';
import { store } from '../store';
import history from './history';
import { Message } from './messages';
import { convertSfValidationErrorDetail, convValidationErrorDetail } from './validation-helper';
import { transferToNativeMethod } from './transferToNativeMethod';
import { isKonyApp } from './device';

export type commonErrorOptions = {
  validationErrorState?: any, // バリデーションエラーを管理しているステート
  updateValidationError?: any, // バリデーションエラーステートを更新する関数
  transitionUrl?: string, // 遷移先
};

/**
 * 共通エラーのハンドリングを行う。
 * @param err エラー
 * @param options 設定
 */
export const handleCommonError = (err: any, options?: commonErrorOptions) => {
  if (err.共通部) {
    // 共通部あり
    handleCustomError(err, options);
  } else if (err instanceof Joi.ValidationError) {
    // JoiのValidationError
    handleJoiValidationError(err, options);
  } else {
    // その他のエラー
    handleNativeError(err);
  }
};

/**
 * CustomのValidationErrorをトラップする
 * @param err Error
 * @param options Validationのエラー処理
 */
export const handleValidationError = (error: ValidationError, options?: commonErrorOptions) => {
  setValidationState(error.details, options);
};

/**
 * CustomのValidationErrorをトラップする
 * @param err Error
 * @param options Validationのエラー処理
 */
export const handleSfValidationError = (error: object, options?: commonErrorOptions) => {
  setValidationState(convertSfValidationErrorDetail(error), options);
};
/**
 * JoiのValidationErrorをトラップする
 * @param err Error
 * @param options Validationのエラー処理
 */
export const handleJoiValidationError = (error: Joi.ValidationError, options?: commonErrorOptions) => {
  setValidationState(convValidationErrorDetail(error), options);
};

/**
 * Customエラーのハンドル処理
 * @param err Error
 * @param options Validationのエラー処理
 */
const handleCustomError = (err: any, options?: commonErrorOptions): void => {
  const app = Object.getOwnPropertyDescriptor(store.getState(), 'app')?.value;
  if (['4220'].includes(err.共通部.エラーコード)) {
    // フロントエンドまたはバックエンドによるバリデーションエラーパターン
    handleValidationError(err as ValidationError, options);
    if (options?.transitionUrl) {
      history.push(options.transitionUrl);
    }
  } else if (['WAG10007'].includes(err.共通部.エラーコード)) {
    // SFによるバリデーションエラーパターン
    handleSfValidationError(err.エラー部, options);
  } else if (['WAG10402'].includes(err.共通部.エラーコード)) {
    // SFによる認証トークンの有効期限切れ
    // history.push(urls.Z001, { message: err.共通部.エラーメッセージ, code: '5000' });
    history.push(urls.LOGIN);
  } else if (['5001', '4000', '4010', '5000', '4011', 'WAG20100'].includes(err.共通部.エラーコード)) {
    // エラー画面に遷移するパターン
    const error = err as AbstractError;
    history.push(urls.Z001, { message: error.共通部.エラーメッセージ, code: error.共通部.エラーコード });
  } else if (['WAG10013', 'WAG20015', 'WAG20033', 'WAG20098', 'WAG20099', '5002'].includes(err.共通部.エラーコード)) {
    // ダイアログでエラーを表示するパターン
    // WAG10013 共通エラー
    // WAG20015 WAG20033(E002) 画面固有エラー
    store.dispatch(updateDialog({
      ...app.dialog,
      title: '',
      message: err.共通部.エラーメッセージ,
      open: true,
    }));
    if (options?.transitionUrl) {
      history.push(options.transitionUrl);
    }
  } else if (['ESGXX001'].includes(err.共通部.エラーコード)) {
    history.push(urls.X007, { message: err.共通部.エラーメッセージ });
  } else {
    // console.log('想定外のエラー', err);
    history.push(urls.Z001, { message: err.共通部.エラーメッセージ, code: err.共通部.エラーコード });
  }
};

/**
 * 通常のErrorに対する処理
 * @param err Error
 * @param options Validationエラー時の対応
 */
const handleNativeError = (err: any): void => {
  const app = Object.getOwnPropertyDescriptor(store.getState(), 'app')?.value;
  // Cognito
  if (err.name === 'NotAuthorizedException') {
    if (err.message === 'Refresh Token has expired') {
      // RefreshTokenの有効期限切れ
      if (isKonyApp()) {
        const transferInfo = [];
        transferInfo.push('4011', Message.wM002());
        transferToNativeMethod('ERROR_LOGOUT', transferInfo);
      } else {
        history.push(urls.Z001, { message: Message.wM002(), code: '4011' });
      }
      return;
    }
    if (err.message === 'Invalid Access Token') {
      // 無効なAccessToken
      if (isKonyApp()) {
        const transferInfo = [];
        transferInfo.push('4011', Message.wM002());
        transferToNativeMethod('ERROR_LOGOUT', transferInfo);
      } else {
        history.push(urls.Z001, { message: Message.wM002(), code: '4011' });
      }
      return;
    }
    if (err.message === 'Incorrect username or password.') {
      // ダイアログでエラーを表示するパターン
      store.dispatch(updateDialog({
        ...app.dialog,
        title: '',
        message: Message.vM007('現在のパスワード'),
        open: true,
      }));
      return;
    }
  } else if (err.code === 'AliasExistsException') {
    if (err.message === 'Already found an entry for the provided username.') {
      // ダイアログでエラーを表示するパターン
      store.dispatch(updateDialog({
        ...app.dialog,
        title: '',
        message: '既に使用されています',
        open: true,
      }));
      return;
    }
  }
  // console.log('想定外のエラー', err);
  history.push(urls.Z001, { message: 'システムエラーが発生しました。', code: '5000' });
};

/**
 * Validationをステートへセットする
 * @param err Error
 * @param options Validationエラー時の対応
 */
const setValidationState = (details?: ValidationErrorDetail[], options?: commonErrorOptions) => {
  const filteredError = details?.filter((detail) => !!detail.field);

  const errorMessages: { [key: string]: string } = {};
  filteredError?.forEach((detail) => {
    const { field, message } = detail;
    errorMessages[field!] = message;
  }, errorMessages);
  store.dispatch(options?.updateValidationError({ ...options?.validationErrorState, ...errorMessages }));
};

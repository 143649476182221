import { createBrowserHistory } from 'history';

/**
 * 画面遷移部品。
 * useHistoryを使用できない場合にこの部品で画面遷移ができる。
 */
const history = createBrowserHistory();

export function getState(state: any, name: string): string {
  return (state as any)?.[name] ?? '';
}

export default history;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  Route,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { AppDispatch } from '../../store';

type ErrorLocation = {
  message?: string,
  code?: string,
}

// X007
const Maintenance: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation<ErrorLocation>();
  // const nextUrl = new URLSearchParams(location.search).get('next');
  const { stylePattren } = useSelector((state: RootState) => state.pattern);

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        <section>
          <div className="blockMain mb05gd">
            <div className="blockNarrow">
              <div className="ttlH1 mtFLAT mbFLAT">
                <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                <h1>メンテナンス中</h1>
              </div>
            </div>
          </div>
          <div className="blockSub mb05gd">
            <div className="blockNarrow">
              <div className="card bg-light-alsalightgreen mb05gd mtFLAT">
                <p className="mtFLAT mbFLAT aC">
                  【メンテナンス日時】<br />
                  <span className="font-red">{location.state?.message}</span>
                </p>
              </div>
              <p className="mbFLAT">ただいまメンテナンス中のため、上記時間でサービスを停止しております。<br />
                大変ご不便をおかけしますが、メンテナンス終了までしばらくお待ちください。
              </p>
            </div>
          </div>
        </section>
      </main>
      <Route component={Footer} />
    </>
  );
};

export default Maintenance;

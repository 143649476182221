/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GType,
  BankInfoType,
  StateType,
  ValidationErrorType,
  BankType,
  BranchType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    メールアドレス: '',
    顧客銀行名: '',
    顧客支店名: '',
    顧客預金種目: '',
    顧客預金口座番号: '',
    顧客銀行コード: '',
    顧客支店コード: '',
    顧客口座名義人: '',
    振込人名義: '',
  },
  bankInfo: {
    会員番号: '',
    銀行コード: '',
    支店コード: '',
    預金種目: '',
    預金口座番号: '',
    メールアドレス: '',
    預り金フラグ: '',
    借入れ可能フラグ: '',
  },
  validationError: {
    預金口座番号: '',
    預金種目: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  inputAccountNumber: '',
  inputKinds: '1',
  completionDeliveryMailAddress: '',
  completionDeliveryMailAddressConfirm: '',
  isChangeBank: false,
  isChangeMailAddress: false,
  bankAccountValidationErrorMessage: '',
  selectedBank: {
    銀行コード: '',
    カナ銀行名: '',
    漢字銀行名: '',
  },
  selectedBranch: {
    支店コード: '',
    カナ支店名: '',
    漢字支店名: '',
  },
};

const slice = createSlice({
  name: 'g',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<GType>) => {
      state.top = action.payload;
    },
    updateBankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.bankInfo = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
      state.bankAccountValidationErrorMessage = initialState.bankAccountValidationErrorMessage;
    },
    updateInputAccountNumber: (state: StateType, action: PayloadAction<string>) => {
      state.inputAccountNumber = action.payload;
    },
    updateInputKinds: (state: StateType, action: PayloadAction<string>) => {
      state.inputKinds = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeBank: (state: StateType, action: PayloadAction<boolean>) => {
      state.isChangeBank = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateBankAccountValidationErrorMessage: (state: StateType, action: PayloadAction<string>) => {
      state.bankAccountValidationErrorMessage = action.payload;
    },
    updateSelectedBank: (state: StateType, action: PayloadAction<BankType>) => {
      state.selectedBank = action.payload;
    },
    updateSelectedBranch: (state: StateType, action: PayloadAction<BranchType>) => {
      state.selectedBranch = action.payload;
    },
    clearInputValues: (state: StateType) => {
      state.isChangeBank = initialState.isChangeBank;
      state.selectedBank = initialState.selectedBank;
      state.selectedBranch = initialState.selectedBranch;
      state.inputAccountNumber = initialState.inputAccountNumber;
      state.inputKinds = initialState.inputKinds;
      state.completionDeliveryMailAddress = initialState.completionDeliveryMailAddress;
      state.completionDeliveryMailAddressConfirm = initialState.completionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
  },
});

export default slice;

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  open: boolean
  mode?: string
  onClose?: React.ReactEventHandler<{}>
};
const SimpleModal: React.FC<Props> = (props) => {
  const {
    open, mode, onClose, ...elementProps
  } = props;

  const fadeSetting = {
    enter: 1000,
    exit: 0,
  };

  return (
    <Modal
      {...elementProps}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={onClose}
      // disableEnforceFocus
      // disableBackdropClick={false}
      className={`modaal-wrapper modaal-inline ${mode === 'full' ? 'modal--full' : ''}`}
    >
      <Fade in={open} timeout={fadeSetting}>
        <div className="modaal-outer-wrapper">
          <div className="modaal-inner-wrapper">
            <div className="modaal-container">
              <div className="modaal-content">
                <div className="modaal-content-container">
                  {props.children}
                </div>
              </div>
              {onClose && (
                <button className="modaal-close" onClick={onClose}><span>close</span></button>
              )}
            </div>
          </div>
          {mode === 'full' && (
            <button className="modaal-close" onClick={onClose} style={{ display: 'inline-block' }}><span>close</span></button>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default SimpleModal;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AlertDialog from '../basics/AlertDialog';
import Progress from '../basics/Progress';
import { RootState } from '../rootReducer';
import { AppDispatch } from '../store';
import appSlice from '../modules/app';

// フッタの定義
function SPFooter() {
  const dispatch: AppDispatch = useDispatch();
  const { loading, dialog } = useSelector((state: RootState) => state.app);
  const { updateDialog } = appSlice.actions;
  const handleCloseDialog = () => {
    dispatch(updateDialog({ ...dialog, open: false }));
  };

  return (
    <footer className="footer">
      <Progress open={loading} />
      <AlertDialog open={dialog.open} title={dialog.title} message={dialog.message} handleClose={handleCloseDialog} />
    </footer>
  );
}

export default SPFooter;

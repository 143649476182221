import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import urls from '../../utils/urls';
import { createLinkUrlGeneral } from '../../utils/create-link-url';

// X画面のよくある質問一覧
export const LoginQA: React.FC<{ codeResendFunction?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => Promise<void>, pathName?: string }> = ({ codeResendFunction, pathName }) => (
  <div className="blockNarrow blockNarrow--S">
    <div className="accordionList accordionList--noTopBd mbFLAT">
      {pathName && (pathName === urls.X009 || pathName === urls.X013) ? (
        <Accordion className="accordionList__item">
          <AccordionSummary className="accordionList__item__ttl">
            <span className="icon--Q">認証コードのSMSが届きません</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="icon--A">
              <p>
                届かない場合は、以下の方法でも認証コードを確認できます。
              </p>
              <ul className="list mb05gd">
                <li className="list__item">自動音声で認証コードを確認する</li>
              </ul>
              <p>SMSが届かない場合や、登録済み電話番号が固定電話のみの場合は、お電話による認証が可能です。</p>
              <div className="aR mbM">
                <Link to={urls.X003} className="link linkArrowRight">自動音声で確認する</Link>
              </div>
              <ul className="list mb05gd">
                <li className="list__item">認証コードのSMSを再送する</li>
              </ul>
              <p>
                お名前、生年月日、登録済み電話番号をご確認のうえ、認証コードの再送をお願いします。
              </p>
              <div className="aR mbM">
                <a href="###" className="link linkArrowRight" onClick={codeResendFunction}>認証コードを再送する</a>
              </div>
              <p>
                ご登録のお客さま情報の確認・変更はお電話にて承ります。フリーダイヤル<a href="tel:0120-09-09-09" className="forSP">0120-09-09-09</a><span className="forPC">0120-09-09-09</span>にお問合せください。
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Accordion className="accordionList__item">
        <AccordionSummary className="accordionList__item__ttl">
          <span className="icon--Q">会員ページへのログイン方法を教えてください。</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="icon--A">
            <p className="mbFLAT">
              初回ログイン時に、IDとパスワードの設定が必要になります。<br />
              会員ログインページの<Link to={urls.X008}>ID/パスワード設定</Link>よりお手続きをお願いします。
            </p>
            <p>
              2回目以降のログインの場合は、初回ログイン時に設定いただいたIDとパスワードでログイン可能です。
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordionList__item">
        <AccordionSummary className="accordionList__item__ttl">
          <span className="icon--Q">会員ページへログインできません。</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="icon--A">
            <ul className="list mbFLAT">
              <li className="list__item">
                はじめてお申込みのお客さま
              </li>
            </ul>
            <p className="mtFLAT">
              ご契約が完了後、ログインが可能になります。<br />
              会員ログインページの<Link to={urls.X008}>ID/パスワード設定</Link>よりIDとパスワードの設定をお願いします。
            </p>
            <ul className="list mbFLAT">
              <li className="list__item">
                会員のお客さま
              </li>
            </ul>
            <p className="mtFLAT">
              設定済みにもかかわらずログインできない、設定したIDとパスワードをお忘れの場合は会員ログインページの<Link to={urls.X008}>ID/パスワード設定</Link>より再設定をお願いします。
            </p>
            <p className="mbFLAT">
              ログインでお困りの場合は、<a href={createLinkUrlGeneral('user/login/')} target="_blank" rel="noreferrer">会員ログインでお困りのお客さま</a>をご確認ください。
            </p>
            <ul className="listNote">
              <li className="listNote__item">
                システムメンテナンスによりログインできない場合がございます。<a href={createLinkUrlGeneral('info/maintenance/')} target="_blank" rel="noreferrer">メンテナンス情報</a>よりご確認ください。
              </li>
              <li className="listNote__item">
                一定回数ログイン失敗されますとセキュリティ保護のためアカウントがロックされます。
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordionList__item">
        <AccordionSummary className="accordionList__item__ttl">
          <span className="icon--Q">会員ページでできる手続きやサービスについて教えてください。</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="icon--A">
            <p>
              会員のみなさまに安心してご利用いただくための便利な会員サービスを提供しています。
            </p>
            <ul className="list">
              <li className="list__item">
                ご契約内容の確認
              </li>
              <li className="list__item">
                お取引状況の確認
              </li>
              <li className="list__item">
                ご利用限度額の増額、お振込み依頼
              </li>
              <li className="list__item">
                振込み金融機関口座の登録・変更
              </li>
              <li className="list__item">
                住所などの登録・変更
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  </div>
);

export default LoginQA;

import React from 'react';
import { isIphone } from '../utils/device';

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
  href: string
};

/**
 * iphoneアプリのwebviewでtarget="_blank"があると正常に動作しないため
 * iphoneの場合はtarget="_blank"を使用しない
 */
const BlankLink: React.FC<Props> = (props) => {
  const { href, ...elementProps } = props;

  if (window.ReactNativeWebView && isIphone()) {
    return (
      <a href={href} {...elementProps}>
        {props.children}
      </a>
    );
  }
  return (
    <a href={href} {...elementProps} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
};

export default BlankLink;

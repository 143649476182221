import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, CircularProgressProps } from '@material-ui/core';

/** Progress */
type ProgressProps = {
  open: boolean
}

const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#BED400',
    background: '#000000',
    opacity: '0.5 !important',
  },
}));

/** SimpleProgress */
type SimpleProgressProp = CircularProgressProps & {
  execute: boolean
  size?: string
  color?: string
}

export const SimpleProgress: React.FC<SimpleProgressProp> = (props: SimpleProgressProp) => {
  const {
    execute, size, color, ...elementProps
  } = props;
  if (props.execute) {
    return <CircularProgress color={color} size={size} {...elementProps} />;
  }
  return (<></>);
};

SimpleProgress.defaultProps = {
  size: '30px',
  color: 'inherit',
};

export default Progress;

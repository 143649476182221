/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoginTopType,
  LoginValidationErrorType,
  PasswordValidationErrorType,
  ExistingMembersValidationErrorType,
  UnmannedContractMachineValidationErrorType,
  VerificationCodeValidationErrorType,
  TelVerificationCodeValidationErrorType,
  PasswordResetValidationErrorType,
  PasswordRegisterValidationErrorType,
  IdValidationErrorType,
  IdAndPasswordValidationErrorType,
  ResponseUserInfo,
  InputUserInfoValidationErrorType,
  StateType,
  CognitoUserInfo,
  TempCognitoUserInfo,
  ApplicationParams,
} from './types';

// 初期値
const initialState: StateType = {
  loginTop: {
    メンテナンス情報: [],
  },
  loginValidationError: {
    ログインID: '',
    パスワード: '',
  },
  passwordValidationError: {
    パスワード: '',
    パスワード確認用: '',
    設定完了通知送付先: '',
  },
  existingMembersValidationError: {
    会員番号およびカード番号: '',
  },
  unmannedContractMachineValidationError: {
    ログインID: '',
    パスワード: '',
    検証コード: '',
  },
  verificationCodeValidationError: {
    認証コード: '',
  },
  telVerificationCodeValidationError: {
    認証コード: '',
    ご希望の連絡先: '',
  },
  passwordResetValidationError: {
    メールアドレス: '',
  },
  passwordRegisterValidationError: {
    検証コード: '',
    パスワード: '',
    パスワード確認用: '',
  },
  idValidationError: {
    ログインID: '',
    設定完了通知送付先: '',
  },
  idAndPasswordValidationError: {
    ログインID: '',
    パスワード: '',
    パスワード確認用: '',
    設定完了通知送付先: '',
  },
  responseUserInfo: {
    カナ氏名: '',
    生年月日: '',
    電話番号: '',
    会員番号: '',
    メールアドレス: '',
    配信ID: '',
    希望設定項目: '',
  },
  inputUserInfo: {
    カナ氏名: '',
    生年月日: '',
    生年月日年: '',
    生年月日月: '',
    生年月日日: '',
    電話番号: '',
  },
  inputUserInfoValidationError: {
    カナ氏名: '',
    生年月日: '',
    生年月日年: '',
    生年月日月: '',
    生年月日日: '',
    電話番号: '',
  },
  inputSettingItem: '3',
  deviceRegistered: '',
  webIdRegistered: '',
  notifyTarget: '1',
  inputPhoneType: '3',
  loginFailureCount: '0',
  startPointScreenId: '',
  cognitoUserInfo: {
    memberId: '',
    userName: '',
    lastLoginDate: '',
  },
  tempCognitoUserInfo: {
    memberId: '',
    userName: '',
    password: '',
    loginFailureCount: '0',
  },
  applicationParams: {},
  registeredEMailAddress: '',
};

const slice = createSlice({
  name: 'x-login',
  initialState,
  reducers: {
    updateLoginTop: (state: StateType, action: PayloadAction<LoginTopType>) => {
      state.loginTop = action.payload;
    },
    updateInputSettingItem: (state: StateType, action: PayloadAction<string>) => {
      state.inputSettingItem = action.payload;
    },
    updateInputPhoneType: (state: StateType, action: PayloadAction<string>) => {
      state.inputPhoneType = action.payload;
    },
    updateLoginFailureCount: (state: StateType, action: PayloadAction<string>) => {
      state.loginFailureCount = action.payload;
    },
    updateStartPointScreenId: (state: StateType, action: PayloadAction<string>) => {
      state.startPointScreenId = action.payload;
    },
    updateCognitoUserInfo: (state: StateType, action: PayloadAction<CognitoUserInfo>) => {
      state.cognitoUserInfo = action.payload;
    },
    updateTempCognitoUserInfo: (state: StateType, action: PayloadAction<TempCognitoUserInfo>) => {
      state.tempCognitoUserInfo = action.payload;
    },
    updateDeviceRegistered: (state: StateType, action: PayloadAction<string>) => {
      state.deviceRegistered = action.payload;
    },
    updateWebIdRegistered: (state: StateType, action: PayloadAction<string>) => {
      state.webIdRegistered = action.payload;
    },
    updateNotifyTarget: (state: StateType, action: PayloadAction<string>) => {
      state.notifyTarget = action.payload;
    },
    updateRegisteredEMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.registeredEMailAddress = action.payload;
    },
    updateLoginValidationError: (state: StateType, action: PayloadAction<LoginValidationErrorType>) => {
      state.loginValidationError = action.payload;
    },
    updatePasswordValidationError: (state: StateType, action: PayloadAction<PasswordValidationErrorType>) => {
      state.passwordValidationError = action.payload;
    },
    updateExistingMembersValidationError: (state: StateType, action: PayloadAction<ExistingMembersValidationErrorType>) => {
      state.existingMembersValidationError = action.payload;
    },
    updateUnmannedContractMachineValidationError: (state: StateType, action: PayloadAction<UnmannedContractMachineValidationErrorType>) => {
      state.unmannedContractMachineValidationError = action.payload;
    },
    updateVerificationCodeValidationError: (state: StateType, action: PayloadAction<VerificationCodeValidationErrorType>) => {
      state.verificationCodeValidationError = action.payload;
    },
    updateTelVerificationCodeValidationError: (state: StateType, action: PayloadAction<TelVerificationCodeValidationErrorType>) => {
      state.telVerificationCodeValidationError = action.payload;
    },
    updatePasswordResetValidationError: (state: StateType, action: PayloadAction<PasswordResetValidationErrorType>) => {
      state.passwordResetValidationError = action.payload;
    },
    updatePasswordRegisterValidationError: (state: StateType, action: PayloadAction<PasswordRegisterValidationErrorType>) => {
      state.passwordRegisterValidationError = action.payload;
    },
    updateIdValidationError: (state: StateType, action: PayloadAction<IdValidationErrorType>) => {
      state.idValidationError = action.payload;
    },
    updateIdAndPasswordValidationError: (state: StateType, action: PayloadAction<IdAndPasswordValidationErrorType>) => {
      state.idAndPasswordValidationError = action.payload;
    },
    updateResponseUserInfo: (state: StateType, action: PayloadAction<ResponseUserInfo>) => {
      state.responseUserInfo = action.payload;
    },
    updateInputUserInfoNameKana: (state: StateType, action: PayloadAction<string>) => {
      state.inputUserInfo.カナ氏名 = action.payload;
    },
    updateInputUserInfoBirthday: (state: StateType, action: PayloadAction<string>) => {
      state.inputUserInfo.生年月日 = action.payload;
    },
    updateInputUserInfoBirthYear: (state: StateType, action: PayloadAction<string>) => {
      state.inputUserInfo.生年月日年 = action.payload;
    },
    updateInputUserInfoBirthMonth: (state: StateType, action: PayloadAction<string>) => {
      state.inputUserInfo.生年月日月 = action.payload;
    },
    updateInputUserInfoBirthDate: (state: StateType, action: PayloadAction<string>) => {
      state.inputUserInfo.生年月日日 = action.payload;
    },
    updateInputUserInfoPhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.inputUserInfo.電話番号 = action.payload;
    },
    updateInputUserInfoValidationError: (state: StateType, action: PayloadAction<InputUserInfoValidationErrorType>) => {
      state.inputUserInfoValidationError = action.payload;
    },
    updateApplicationParams: (state: StateType, action: PayloadAction<ApplicationParams>) => {
      state.applicationParams = action.payload;
    },
    clearRegisteredEMailAddress: (state: StateType) => {
      state.registeredEMailAddress = initialState.registeredEMailAddress;
    },
    clearLoginValidationError: (state: StateType) => {
      state.loginValidationError = initialState.loginValidationError;
    },
    clearPasswordValidationError: (state: StateType) => {
      state.passwordValidationError = initialState.passwordValidationError;
    },
    clearExistingMembersValidationError: (state: StateType) => {
      state.existingMembersValidationError = initialState.existingMembersValidationError;
    },
    clearUnmannedContractMachineValidationError: (state: StateType) => {
      state.existingMembersValidationError = initialState.existingMembersValidationError;
    },
    clearVerificationCodeValidationError: (state: StateType) => {
      state.verificationCodeValidationError = initialState.verificationCodeValidationError;
    },
    clearTelVerificationCodeValidationError: (state: StateType) => {
      state.telVerificationCodeValidationError = initialState.telVerificationCodeValidationError;
    },
    clearPasswordResetValidationError: (state: StateType) => {
      state.passwordResetValidationError = initialState.passwordResetValidationError;
    },
    clearPasswordRegisterValidationError: (state: StateType) => {
      state.passwordRegisterValidationError = initialState.passwordRegisterValidationError;
    },
    clearIdValidationError: (state: StateType) => {
      state.idValidationError = initialState.idValidationError;
    },
    clearIdAndPasswordValidationError: (state: StateType) => {
      state.idAndPasswordValidationError = initialState.idAndPasswordValidationError;
    },
    clearResponseUserInfoValidationError: (state: StateType) => {
      state.responseUserInfo = initialState.responseUserInfo;
    },
    clearInputUserInfo: (state: StateType) => {
      state.inputUserInfo = initialState.inputUserInfo;
    },
    clearInputUserInfoValidationError: (state: StateType) => {
      state.inputUserInfoValidationError = initialState.inputUserInfoValidationError;
    },
    clearApplicationParams: (state: StateType) => {
      state.applicationParams = initialState.applicationParams;
    },
  },
});

export default slice;

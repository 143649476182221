/* eslint-disable object-shorthand */
import { configureStore, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import rootReducer from './rootReducer';
import ApiCommon from './utils/api-common';

const targetEnv = process.env.REACT_APP_TARGET_ENV;
const secretMainKey = 'shinsei';
const secretSubKey = process.env.REACT_APP_SUB_SECRET_KEY;
// console.log(`${targetEnv}/${secretMainKey}/${secretSubKey}`);
const webStorage = (ApiCommon.isSessionStorageAvailable())
  ? storageSession
  : storage;

export const persistConfig = (targetEnv === 'production' && secretSubKey != null)
  ? {
    key: 'root',
    storage: webStorage,
    stateReconciler: autoMergeLevel1,
    transforms: [
      encryptTransform({
        secretKey: `${secretMainKey}${secretSubKey}`,
        onError: () => {
        },
      }),
    ],
  }
  : {
    key: 'root',
    storage: webStorage,
    stateReconciler: autoMergeLevel1,
  };

const persistedReducer = persistReducer(persistConfig, rootReducer);
const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredActions: [
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  ],
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [serializableMiddleware],
  devTools: (targetEnv !== 'production'),
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch

export default { store, persistor };

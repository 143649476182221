/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StateType = {
  viewPattern: string,
  stylePattren: string,
};

// 初期値
export const initialState: StateType = {
  viewPattern: 'A',
  stylePattren: 'A',
};

const slice = createSlice({
  name: 'pattern',
  initialState,
  reducers: {
    updateViewPattern: (state: StateType, action: PayloadAction<string>) => {
      state.viewPattern = action.payload;
    },
    updateStylePattern: (state: StateType, action: PayloadAction<string>) => {
      state.stylePattren = action.payload;
    },
  },
});

export const {
  updateViewPattern,
  updateStylePattern,
} = slice.actions;

export default slice;

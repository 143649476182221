/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AlertDialog from '../basics/AlertDialog';
import Progress from '../basics/Progress';
import { RootState } from '../rootReducer';
import { AppDispatch } from '../store';
import appSlice from '../modules/app';

// フッタの定義
function Footer() {
  const dispatch: AppDispatch = useDispatch();
  const { loading, dialog } = useSelector((state: RootState) => state.app);
  const { updateDialog } = appSlice.actions;
  const handleCloseDialog = () => {
    dispatch(updateDialog({ ...dialog, open: false }));
  };

  return (
    <footer id="footer" className="footer-ja">
      <div className="footer_item">
        <div className="blockNarrow">
          <p className="mtFLAT mbL ptM fsXS">新生フィナンシャル株式会社<br />
            本社：東京都千代田区外神田三丁目12番8号<br />
            Tel:03-3525-9000(代表)<br />
            登録番号：関東財務局長(10) 第01024号<br />
            日本貸金業協会会員第000003号
          </p>
          <div className="aC">
            <img src="/img/A/img_footer_logo.png" alt="新生フィナンシャル株式会社" width="213" />
            <p className="copyright">Copyright Shinsei Financial Co.,ltd.</p>
          </div>
        </div>
      </div>
      <Progress open={loading} />
      <AlertDialog open={dialog.open} title={dialog.title} message={dialog.message} handleClose={handleCloseDialog} />
    </footer>
  );
}

export default Footer;

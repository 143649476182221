import Joi from 'joi';
import { Message } from '../../utils/messages';

// IDのスキーマ
const idSchema = () => ({
  ログインID: Joi.string()
    .label('ログインID')
    .empty()
    .required()
    // 半角英数字チェック
    // eslint-disable-next-line no-control-regex
    .regex(/^(?!.*[^\x01-\x7E]).+$/)
    // 記号チェック
    .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, { name: 'symbol' })
    .min(6)
    .messages({
      'any.required': Message.vM003(),
      'string.empty': Message.vM003(),
      'string.pattern.base': Message.vM032(),
      'string.pattern.name': Message.vM029(),
      'string.min': Message.vM012('6桁以上'),
    }),
});

// パスワードのスキーマ
const passwordSchema = () => ({
  パスワード: Joi.string()
    .label('パスワード')
    .empty()
    .required()
    // 半角英数字チェック
    // eslint-disable-next-line no-control-regex
    .regex(/^(?!.*[^\x01-\x7E]).+$/)
    // 記号チェック
    .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, { name: 'symbol' })
    .min(8)
    .max(16)
    .messages({
      'any.required': Message.vM003(),
      'string.empty': Message.vM003(),
      'string.pattern.base': Message.vM029(),
      'string.min': Message.vM012('8文字以上16文字以内'),
      'string.max': Message.vM012('8文字以上16文字以内'),
    }),
});

// パスワード確認用スキーマ
const passwordConfirmSchema = () => ({
  パスワード: Joi.string()
    .label('パスワード')
    .messages({
      'string.empty': Message.vM003(),
    }),
  パスワード確認用: Joi.string()
    .label('パスワード確認用')
    .valid(Joi.ref('パスワード'))
    .empty()
    .required()
    // 半角英数字チェック
    // eslint-disable-next-line no-control-regex
    .regex(/^(?!.*[^\x01-\x7E]).+$/)
    // 記号チェック
    .regex(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, { name: 'symbol' })
    .min(8)
    .max(16)
    .messages({
      'any.required': Message.vM003(),
      'string.empty': Message.vM003(),
      'string.pattern.base': Message.vM029(),
      'string.min': Message.vM012('8文字以上16文字以内'),
      'string.max': Message.vM012('8文字以上16文字以内'),
      'any.only': Message.vM022(),
    }),
});

// 検証コード用スキーマ
const verificationCodeSchema = () => ({
  認証コード: Joi.string()
    .label('認証コード')
    .empty()
    .required()
    .length(4)
    .regex(/^[0-9]*$/)
    .messages({
      'any.required': Message.vM003(),
      'string.empty': Message.vM003(),
      'string.length': Message.vM012('4桁'),
      'string.pattern.base': Message.vM032(),
    }),
});

// カナ氏名用スキーマ
const nameKanaSchema = (label?: string) => ({
  カナ氏名: Joi.string()
    .label('カナ氏名')
    .empty()
    .required()
    // カタカナのチェック
    // eslint-disable-next-line no-irregular-whitespace
    .regex(/^[ｦ-ﾟ ]*$/)
    // スぺースのチェック
    // eslint-disable-next-line no-irregular-whitespace
    .regex(/^(?=.* ).*$/, { name: 'space' })
    .max(20)
    .messages({
      'any.required': label ? Message.vM048(label) : Message.vM003(),
      'string.empty': label ? Message.vM048(label) : Message.vM003(),
      'string.pattern.base': '入力された文字は取り扱いできません。',
      'string.pattern.name': Message.vM044('{#label}'),
      'string.max': Message.vM012('20文字以内'),
    }),
});

// 生年月日用スキーマ
const birthdaySchema = () => ({
  生年月日: Joi.string()
    .label('生年月日')
    .regex(/^[0-9]{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/)
    .min(8)
    .max(8)
    .messages({
      'string.min': Message.vM005('{#label}'),
      'string.max': Message.vM005('{#label}'),
    }),
});

const birthYearSchema = () => ({
  生年月日年: Joi.string()
    .label('生年')
    .empty()
    .required()
    .messages({
      'any.required': Message.vM004(),
      'string.empty': Message.vM004(),
    }),
});

const birthMonthSchema = () => ({
  生年月日月: Joi.string()
    .label('誕生月')
    .empty()
    .required()
    .messages({
      'any.required': Message.vM004(),
      'string.empty': Message.vM004(),
    }),
});

const birthDateSchema = () => ({
  生年月日日: Joi.string()
    .label('誕生日')
    .empty()
    .required()
    .messages({
      'any.required': Message.vM004(),
      'string.empty': Message.vM004(),
    }),
});

// 電話番号用スキーマ
const phoneNumberSchema = (label?: string) => ({
  電話番号: Joi.string()
    .label('電話番号')
    .required()
    .regex(/^\d+$/, { name: 'numCheck' })
    .regex(/^((050|070|080|090)[0-9]{8}|([1-9][0-9]|[0-9][1-9])([1-9][0-9][0-9][0-9]|[0-9][1-9][0-9][0-9]|[0-9][0-9][1-9][0-9]|[0-9][0-9][0-9][1-9])[0-9]{4})$/)
    .regex(/^(?!0990|0120).*$/)
    .regex(/^\d{3}([1-9][0-9][0-9][0-9]|[0-9][1-9][0-9][0-9]|[0-9][0-9][1-9][0-9]|[0-9][0-9][0-9][1-9]).*$/)
    .messages({
      'any.required': label ? Message.vM048(label) : Message.vM003(),
      'string.empty': label ? Message.vM048(label) : Message.vM003(),
      'string.pattern.base': Message.vM047('{#label}'),
      'string.pattern.name': Message.vM067('{#label}'),
    }),
});

// ご希望の連絡先のスキーマ
const chosenContactSchema = () => ({
  ご希望の連絡先: Joi.string()
    .label('ご希望の連絡先')
    .empty()
    .required()
    .messages({
      'any.required': Message.vM005('{#label}'),
      'string.empty': Message.vM005('{#label}'),
    }),
});

// ご希望の再設定項目のスキーマ
const settingOptionSchema = () => ({
  ご希望の再設定項目: Joi.string()
    .label('ご希望の再設定項目')
    .empty()
    .required()
    .messages({
      'any.required': Message.vM004(),
      'string.empty': Message.vM004(),
    }),
});

// 設定完了通知送付先のスキーマ
const confirmationAccessSchema = () => ({
  設定完了通知送付先: Joi.string()
    .label('設定完了通知送付先')
    .empty()
    .required()
    .messages({
      'any.required': Message.vM005('{#label}'),
      'string.empty': Message.vM005('{#label}'),
    }),
});

export const passwordValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(passwordSchema());
  return schema.validate(data, options);
};

export const passwordConfirmValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(passwordConfirmSchema());
  return schema.validate(data, options);
};

export const verificationCodeValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(verificationCodeSchema());
  return schema.validate(data, options);
};

export const chosenAccessValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(chosenContactSchema());
  return schema.validate(data, options);
};

export const settingOptionValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(settingOptionSchema());
  return schema.validate(data, options);
};

export const confirmationAccessValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(confirmationAccessSchema());
  return schema.validate(data, options);
};

export const allLoginValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...idSchema(),
    ...passwordSchema(),
  });
  return schema.validate(data, options);
};

export const allPasswordIssuanceValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...passwordSchema(),
    パスワード確認用: passwordConfirmSchema().パスワード確認用,
  });
  return schema.validate(data, options);
};

export const allPasswordRegisterValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...verificationCodeSchema(),
    ...passwordSchema(),
    パスワード確認用: passwordConfirmSchema().パスワード確認用,
  });
  return schema.validate(data, options);
};

export const allUnmannedContractMachineValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...idSchema(),
    ...passwordSchema(),
    ...verificationCodeSchema(),
  });
  return schema.validate(data, options);
};

export const nameKanaValidator = (data: any, options?: Joi.ValidationOptions, label?: string) => {
  const schema = Joi.object(nameKanaSchema(label));
  return schema.validate(data, options);
};
export const birthdayValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(birthdaySchema());
  return schema.validate(data, options);
};
export const birthYearValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(birthYearSchema());
  return schema.validate(data, options);
};
export const birthMonthValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(birthMonthSchema());
  return schema.validate(data, options);
};
export const birthDateValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(birthDateSchema());
  return schema.validate(data, options);
};
export const phoneNumberValidator = (data: any, options?: Joi.ValidationOptions, label?: string) => {
  const schema = Joi.object(phoneNumberSchema(label));
  return schema.validate(data, options);
};
export const userInfoValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...nameKanaSchema(),
    ...birthdaySchema(),
    ...phoneNumberSchema(),
    ...confirmationAccessSchema(),
  });
  return schema.validate(data, options);
};
export const threeAuthValidator = (data: any, options?: Joi.ValidationOptions, labels?: {nameKana: string, phoneNumber: string}) => {
  const schema = Joi.object({
    ...nameKanaSchema(labels?.nameKana),
    ...birthdaySchema(),
    ...phoneNumberSchema(labels?.phoneNumber),
  });
  return schema.validate(data, options);
};

export const idValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(idSchema());
  return schema.validate(data, options);
};
export const idRegisterValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...idSchema(),
  });
  return schema.validate(data, options);
};
export const idAndPasswordRegisterValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...idSchema(),
    ...passwordSchema(),
    パスワード確認用: passwordConfirmSchema().パスワード確認用,
  });
  return schema.validate(data, options);
};
export const resetIDValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...idSchema(),
  });
  return schema.validate(data, options);
};

/**
 * 証明書受領状況区分
 */

export const notSubmitted = '0';
export const submitting = '1';
export const submitted = '2';
export const mustReResubmitted = '3';

export const certificateReceiptStatuses = [
  { code: notSubmitted, name: '未提出' },
  { code: submitting, name: '提出中' },
  { code: submitted, name: '提出済み' },
  { code: mustReResubmitted, name: '再提出' },
];

export function certificateReceiptStatusGetByCode(code: string) {
  const result = certificateReceiptStatuses.find((item) => item.code === code);
  if (typeof result === 'undefined') {
    return { code: '', name: '' };
  }
  return result;
}

export function isNotSubmitted(code: string) {
  return code === notSubmitted;
}

export function isSubmitting(code: string) {
  return code === submitting;
}

export function isSubmitted(code: string) {
  return code === submitted;
}

export function isMustReResubmitted(code: string) {
  return code === mustReResubmitted;
}

const appliErrorJson = {
  共通部: {
    リターンコード: '',
    エラーコード: '',
    エラーメッセージ: '',
    stack: '',
  },
};
const UNKNOWN_ERROR_CODE = 'WAG30001';
const UNKNOWN_ERROR_MESSAGE = 'システムでエラーが発生しました';
const COGNITO_AUTH_ERROR_CODE = 'WAG10001';
const COGNITO_AUTH_ERROR_MESSAGE = '認証に失敗しました';
/**
 * 共通エラーのハンドリングを行う。
 * @param err エラー
 * @param options 設定
 */
export const handleAppliCommonError = (err: any) => {
  let json = appliErrorJson;
  if (err.共通部) {
    // 共通部あり
    json.共通部.リターンコード = '9';
    json.共通部.エラーコード = err.共通部.エラーコード;
    json.共通部.エラーメッセージ = err.共通部.エラーメッセージ;
    json.共通部.stack = err.stack;
  } else {
    // その他のエラー
    json.共通部.リターンコード = '9';
    json.共通部.エラーコード = UNKNOWN_ERROR_CODE;
    json.共通部.エラーメッセージ = UNKNOWN_ERROR_MESSAGE;
    json.共通部.stack = err.stack;
  }
  if (window.ReactNativeWebView != null) {
    window.ReactNativeWebView.postMessage(JSON.stringify(json));
  }
};

export const postCognitoErrorMessage = (err: any, customMessage: string = '') => {
  let json = appliErrorJson;
  json.共通部.リターンコード = '9';
  json.共通部.エラーコード = COGNITO_AUTH_ERROR_CODE;
  json.共通部.エラーメッセージ = COGNITO_AUTH_ERROR_MESSAGE;
  json.共通部.stack = err.stack || `${err.name} ${err.message}`;
  if (customMessage !== '') {
    json.共通部.エラーメッセージ = customMessage;
  }
  if (window.ReactNativeWebView != null) {
    window.ReactNativeWebView.postMessage(JSON.stringify(json));
  }
};

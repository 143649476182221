import ConnectionError from '../errors/connection-error';
import SfApiException from '../errors/sf-api-exception';
import TimeoutError from '../errors/timeout-error';
import BackendClient from '../utils/backend-client';

/**
 * バックエンドを経由してMCS_APIの呼出し処理を行うサービスクラス。
 */
export default class McsBackendService {
  /**
   * MCS_API呼出しを行う。
   * 引数はMCS_API仕様書のリクエスト形式と同じように指定すること。
   * @param params MCS_APIのリクエスト内容
   * @param isThrowError エラーとしてThrowするかどうか
   * @return MCS_APIの処理結果(JSON)
   */
  static async request(params: any, isThrowError: boolean = true) {
    try {
      const response = await BackendClient.httpPost({ body: params, endpoint: '/mcs' });
      // JSON変換
      const resJson = await response.json();
      const sanitizedJson = await BackendClient.objectSanitize(resJson);
      const json: any = { ...sanitizedJson };
      // const json = await response.json();

      // エラー確認
      if (!response.ok && (json.共通部.リターンコード && json.共通部.リターンコード === '9')) {
        throw isThrowError
          ? new SfApiException({
            共通部: json.共通部,
            from: 'BACKEND',
            データ部: json.データ部,
            エラー部: json.エラー部,
          })
          : json;
      }
      return json;
    } catch (err) {
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        throw new ConnectionError({ from: 'FRONTEND' });
      } else if (err instanceof DOMException && err.name === 'AbortError') {
        throw new TimeoutError({ from: 'FRONTEND' });
      } else {
        throw err;
      }
    }
  }
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import urls from '../utils/urls';
import { isApp } from '../utils/device';

// 依頼済み 画面
const Stylesheets: React.FC = () => {
  const location = useLocation();
  const {
    stylePattren,
  } = useSelector((state: RootState) => state.pattern);

  // アプリの場合は読み込まない
  if ((location.pathname === urls.SPAPPLI || location.pathname === urls.SPAPPLI2) && isApp()) {
    return (<></>);
  }

  if (!stylePattren) {
    return (
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/css/A/import.css" />
        <link rel="stylesheet" type="text/css" href="/css/A/base_01.css" />
        <link rel="stylesheet" type="text/css" href="/css/A/common.css" />
        <link rel="stylesheet" type="text/css" href="/css/A/sub.css" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <link rel="stylesheet" type="text/css" href={`/css/${stylePattren}/import.css`} />
      <link rel="stylesheet" type="text/css" href={`/css/${stylePattren}/base_01.css`} />
      <link rel="stylesheet" type="text/css" href={`/css/${stylePattren}/common.css`} />
      <link rel="stylesheet" type="text/css" href={`/css/${stylePattren}/sub.css`} />
    </Helmet>
  );
};

export default Stylesheets;

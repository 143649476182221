/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CType,
  StateType,
  ValidationErrorType,
  RepaymentScheduleConfirmType,
  RepaymentScheduleCompleteType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    メールアドレス: '',
    自宅住所漢字: '',
    勤務先住所漢字: '',
    給料日: '',
    管理店連絡先: '',
    商品名: '',
    現在契約額: '',
    次回返済日: '',
    現在約定日: '',
    団信区分: '',
    処理日時: '',
  },
  accountBranchNumber: '',
  inputCompletionDeliveryMailAddress: '',
  inputCompletionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  inputTradeDate: '',
  inputContractDestination: 'a',
  inputGroupInsurance: '',

  repaymentScheduleConfirm: {
    変更後次回返済日: '',
    メールアドレス: '',
  },

  repaymentScheduleComplete: {
    会員番号: '',
    口座枝番: '',
    変更前約定日: '',
    変更後約定日: '',
    変更前次回返済日: '',
    変更後次回返済日: '',
    契約書送付先区分: '',
    メールアドレス: '',
    団信区分: '',
    連番: '',
    契約変更年月日: '',
    預り金: '',
    遅れ日数: '',
  },
  validationError: {
    変更後約定日: '',
    メールアドレス: '',
    メールアドレス確認: '',
    送付先区分: '',
    団信区分: '',
  },
};

const slice = createSlice({
  name: 'c',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<CType>) => {
      state.top = action.payload;
    },
    updateAccountBranchNumber: (state: StateType, action: PayloadAction<string>) => {
      state.accountBranchNumber = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateTradeDate: (state: StateType, action: PayloadAction<string>) => {
      state.inputTradeDate = action.payload;
    },
    updateInputContractDestination: (state: StateType, action: PayloadAction<string>) => {
      state.inputContractDestination = action.payload;
    },
    updateInputGroupInsurance: (state: StateType, action: PayloadAction<string>) => {
      state.inputGroupInsurance = action.payload;
    },
    updateRepaymentScheduleConfirm: (state: StateType, action: PayloadAction<RepaymentScheduleConfirmType>) => {
      state.repaymentScheduleConfirm = action.payload;
    },
    updateRepaymentScheduleComplete: (state: StateType, action: PayloadAction<RepaymentScheduleCompleteType>) => {
      state.repaymentScheduleComplete = action.payload;
    },
    clearTradeDate: (state: StateType) => {
      state.inputTradeDate = initialState.inputTradeDate;
    },
    clearRepaymentScheduleConfirm: (state: StateType) => {
      state.repaymentScheduleConfirm = initialState.repaymentScheduleConfirm;
    },
    clearInputContractDestination: (state: StateType) => {
      state.inputContractDestination = initialState.inputContractDestination;
    },
    clearInputGroupInsurancen: (state: StateType) => {
      state.inputGroupInsurance = initialState.inputGroupInsurance;
    },
    clearCompletionDeliveryMailAddress: (state: StateType) => {
      state.inputCompletionDeliveryMailAddress = initialState.inputCompletionDeliveryMailAddress;
    },
    clearCompletionDeliveryMailAddressConfirm: (state: StateType) => {
      state.inputCompletionDeliveryMailAddressConfirm = initialState.inputCompletionDeliveryMailAddressConfirm;
    },
    clearIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
  },
});

export default slice;

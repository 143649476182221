/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  StateType,
  IdValidationErrorType,
  PasswordValidationErrorType,
} from './types';

// 初期値
const initialState: StateType = {
  idValidationError: {
    現在のパスワード: '',
    ID: '',
    ID確認用: '',
  },
  passwordValidationError: {
    現在のパスワード: '',
    パスワード: '',
    パスワード確認用: '',
  },
  registeredEMailAddress: '',
};

const slice = createSlice({
  name: 'm-change-account-info',
  initialState,
  reducers: {
    updateIdValidationError: (state: StateType, action: PayloadAction<IdValidationErrorType>) => {
      state.idValidationError = action.payload;
    },
    updatePasswordValidationError: (state: StateType, action: PayloadAction<PasswordValidationErrorType>) => {
      state.passwordValidationError = action.payload;
    },
    updateRegisteredEMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.registeredEMailAddress = action.payload;
    },
    clearIdValidationError: (state: StateType) => {
      state.idValidationError = initialState.idValidationError;
    },
    clearPasswordValidationError: (state: StateType) => {
      state.passwordValidationError = initialState.passwordValidationError;
    },
    clearRegisteredEMailAddress: (state: StateType) => {
      state.registeredEMailAddress = initialState.registeredEMailAddress;
    },
  },
});

export default slice;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BType,
  BankInfoType,
  RepaymentScheduleConformType,
  BankDetailsType,
  StateType,
  ValidationErrorType,
  PayoffValidationErrorType,
  SimulationDateInfoType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    遷移先画面ID: '',
    管理店連絡先: '',
    処理日時: '',
  },
  bankInfo: {
    会員番号: '',
    口座枝番: '',
    商品名: '',
    請求日: '',
    請求金額: '',
    返済期日: '',
    返済必要額: '',
    返済予定日: '',
    返済方法: '',
    元金充当額: '',
    利息: '',
    手数料累計額: '',
    契約年月日: '',
    約定利率: '',
    最終貸付日: '',
    最終貸付直後残高: '',
    債務残高: '',
    元金残高: '',
    保留金: '',
    不足金: '',
    遅延損害金: '',
    遅れ日数: '',
    遷移先画面ID: '',
    管理店連絡先: '',
  },
  repaymentScheduleConform: {
    口座枝番: '',
    変更後返済期日: '',
    返済方法: '',
    変更後遅延損害金: '',
    変更後返済必要額: '',
  },
  bankDetails: {
    口座枝番: '',
    商品名: '',
    契約年月日: '',
    約定利率: '',
    損害金利率: '',
    約定返済日: '',
    最終貸付日: '',
    最終貸付直後残高: '',
    請求金額: '',
    元金充当額: '',
    利息: '',
    手数料累計額: '',
    遅延損害金: '',
    保留金: '',
    債務残高: '',
    元金残高: '',
    不足金: '',
    本日付遅延損害金: '',
  },
  simulationDateInfo: {
    次回支払日: '',
    処理日時: '',
  },
  repaymentMethod: '4',
  repaymentDays: '',
  payoffAmount: '',
  validationError: {
    変更後返済期日: '',
    返済方法: '',
  },
  payoffValidationError: {
    完済予定日: '',
  },
};

const slice = createSlice({
  name: 'b',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<BType>) => {
      state.top = action.payload;
    },
    updateBankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.bankInfo = action.payload;
    },
    updateRepaymentScheduleConform: (state: StateType, action: PayloadAction<RepaymentScheduleConformType>) => {
      state.repaymentScheduleConform = action.payload;
    },
    updateRepaymentMethod: (state: StateType, action: PayloadAction<string>) => {
      state.repaymentMethod = action.payload;
    },
    updateRepaymentDays: (state: StateType, action: PayloadAction<string>) => {
      state.repaymentDays = action.payload;
    },
    updatePayoffAmount: (state: StateType, action: PayloadAction<string>) => {
      state.payoffAmount = action.payload;
    },
    clearInput: (state: StateType) => {
      state.repaymentMethod = initialState.repaymentMethod;
      state.repaymentDays = initialState.repaymentDays;
      state.payoffAmount = initialState.payoffAmount;
    },
    updateBankDetails: (state: StateType, action: PayloadAction<BankDetailsType>) => {
      state.bankDetails = action.payload;
    },
    updateSimulationDateInfo: (state: StateType, action: PayloadAction<SimulationDateInfoType>) => {
      state.simulationDateInfo = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updatePayoffValidationError: (state: StateType, action: PayloadAction<PayoffValidationErrorType>) => {
      state.payoffValidationError = action.payload;
    },
    clearPayoffValidationError: (state: StateType) => {
      state.payoffValidationError = initialState.payoffValidationError;
    },
  },
});

export default slice;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * デバイス関連情報のステート
 */
export type DeviceInfoType = {
  distributionId: string, // 配信ID
  fromUrl: string, // 呼び出し元画面
}

/**
 * トークン情報のセット1
 */
export type MemberInfoType = {
  memberNo: string, // 会員番号
  token: string, // トークン
}

/**
 * ユーザに関するステート
 */
export type UserState = {
  loggedIn: boolean, // ログイン状態
  deviceInfo: DeviceInfoType, // iovation関連
  memberInfo: MemberInfoType, // MmeberInfo
  loginInfo: LoginInfoType, // ログイン情報
  tempLoginInfo: TempLoginInfoType, // 一時的なログイン情報（再認証用）
};

/**
 * ログインに関するステート
 */
export type LoginInfoType = {
  ログインID: string,
  パスワード: string,
  代表電話番号: string,
  和解客区分: string,
  ログイン停止区分: string,
  口座情報: Array<InfoType>,
};

export type InfoType = {
  口座枝番: string,
  解約区分: string,
};

export type TempLoginInfoType = {
  tempLoginId: string,
  tempPassword: string,
}

const initialState: UserState = {
  loggedIn: false,
  deviceInfo: {
    distributionId: '',
    fromUrl: '',
  },
  memberInfo: {
    memberNo: '',
    token: '',
  },
  loginInfo: {
    ログインID: '',
    パスワード: '',
    代表電話番号: '',
    和解客区分: '',
    ログイン停止区分: '',
    口座情報: [],
  },
  tempLoginInfo: {
    tempLoginId: '',
    tempPassword: '',
  },
};

/**
 * ユーザに関するステートを管理する部品。
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /**
     * ログイン状態を変更する。
     * @param state ステート
     * @param action アクション
     */
    updateLoggedIn: (state: UserState, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },

    /**
     * デバイス情報を変更する。
     * @param state ステート
     * @param action アクション
     * @returns ステート
     */
    updateDeviceInfo: (state: UserState, action: PayloadAction<DeviceInfoType>) => ({ ...state, deviceInfo: action.payload }),

    /**
     * ユーザー情報を変更する。
     * @param state ステート
     * @param action アクション
     * @returns ステート
     */
    updateMemberInfo: (state: UserState, action: PayloadAction<MemberInfoType>) => ({ ...state, memberInfo: action.payload }),

    /**
     * ログイン情報を変更する。
     * @param state ステート
     * @param action アクション
     * @returns ステート
     */
    updateLoginInfo: (state: UserState, action: PayloadAction<LoginInfoType>) => {
      state.loginInfo = action.payload;
    },

    /**
     * 一時的なログイン情報（再認証用）を変更する。
     * @param state ステート
     * @param action アクション
     * @returns ステート
     */
    updateTempLoginInfo: (state: UserState, action: PayloadAction<TempLoginInfoType>) => {
      state.tempLoginInfo = action.payload;
    },

    clearUserInfoInfo: (state: UserState) => {
      state.deviceInfo = initialState.deviceInfo;
      state.memberInfo = initialState.memberInfo;
      state.loginInfo = initialState.loginInfo;
      state.tempLoginInfo = initialState.tempLoginInfo;
    },
  },
});

export const {
  updateLoggedIn,
  updateDeviceInfo,
  updateMemberInfo,
  updateLoginInfo,
  updateTempLoginInfo,
} = userSlice.actions;

export default userSlice;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import { getPageIdIfNecessary } from '../utils/page-ids';

// ヘッダの定義
const LoginHeader: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>【レイク公式】会員ログイン | カードローン・キャッシングのレイク</title>
        <meta name="Description" content="お借入れ、ご返済、増額、Web明細の確認、お客さま情報などの手続きが可能な会員ログインについてご案内します。 | カードローン・キャッシングのレイク公式サイト" />
        <meta name="Keywords" content="会員ログイン,カードローン, お借入れ, キャッシング, レイク, 最短, 即日融資, 選べる無利息, 新生フィナンシャル" />
        <meta property="og:title" content="【レイク公式】会員ログイン | カードローン・キャッシングのレイク" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="お借入れ、ご返済、増額、Web明細の確認、お客さま情報などの手続きが可能な会員ログインについてご案内します。 | カードローン・キャッシングのレイク公式サイト" />
      </Helmet>
      <header id="header" className="header-ja">
        <div className="headerWrapper">
          <div className="headerLogo">
            <img src="/img/A/img_header_logo.png" alt="レイク" width="102" />
            <span className={classes.pageId}>{getPageIdIfNecessary(location.pathname)}</span> {/* 画面ID 本番では表示しない予定 */}
          </div>
        </div>
      </header>
    </>
  );
};

const useStyles = makeStyles(() => createStyles({
  pageId: {
    padding: '10px',
    color: 'white',
    fontSize: '14px',
  },
}));

export default LoginHeader;

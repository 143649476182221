/**
 * JSON ファイルの読み込み
 * @param path JSON ファイルのパス
 * @returns 読み込んだ JSON データ
 */
export async function loadJson(path: string) {
  let jsonData = null;
  try {
    const res = await fetch(path);
    jsonData = await res.json();
  } catch (e) {
    jsonData = null;
  }
  return jsonData;
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { LoginQA } from '../../basics/QA/x_loginQA';

// X004
const VerificationCodeNotArrived: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');

  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const { loggedIn } = useSelector((state: RootState) => state.user);

  // const [webId, setWebId] = useState('');
  // const [password, setPassword] = useState('');
  // const [verifyCode, setVerifyCode] = useState('');

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  // // 無人機ログイン(X004)
  // const handleClickChangePassword = async () => {
  //   dispatch(isLoading(true));
  //   // バリデーション
  //   if (!confirmValidation()) {
  //     return;
  //   }
  //   // dispatch(isLoading(true));
  //   // sendBlackbox();
  //   // CognitoBackendService.request({
  //   //   body: {
  //   //     webid,
  //   //     password,
  //   //     code,
  //   //   },
  //   //   endpoint: '/createUser',
  //   // })
  //   //   .catch((err) => console.log(err));
  //   dispatch(isLoading(false));
  //   history.push(urls.X005);
  // };

  // const handleValidationError = (err: any) => {
  //   handleJoiValidationError(err, {
  //     validationErrorState: unmannedContractMachineValidationError,
  //     updateValidationError: updateUnmannedContractMachineValidationError,
  //   });
  // };

  // const confirmValidation = () => {
  //   dispatch(clearUnmannedContractMachineValidationError());
  //   // バリデーション
  //   const validPramas: { ログインID: string, パスワード: string, 検証コード: string, [key: string]: string } = {
  //     ログインID: webId,
  //     パスワード: password,
  //     検証コード: verifyCode,
  //   };
  //   try {
  //     throwIfJoiValidationError(allUnmannedContractMachineValidator(validPramas, { abortEarly: false }));
  //   } catch (err) {
  //     handleValidationError(err);
  //     dispatch(isLoading(false));
  //     return false;
  //   }
  //   return true;
  // };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>認証コード未到着</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH2 mtFLAT">
                    <h2>お客さま情報の確認・変更</h2>
                  </div>
                  <p>お電話にて、ご登録のお客さま情報の確認・変更を承ります。フリーダイヤルよりお電話ください。</p>
                  <p className="fs2XL flexC mbFLAT">
                    <strong>
                      <a href="tel:0120-09-09-09" className="forSP fs3XL">0120-09-09-09</a>
                      <span className="forPC">0120-09-09-09</span>
                      <span className="fsXS">（受付時間 平日 9～18時）</span>
                    </strong>
                  </p>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default VerificationCodeNotArrived;

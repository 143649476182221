export type AbstractErrorFrom = 'FRONTEND' | 'BACKEND' | 'SF';
/*
export type AbstractErrorCommonPart = {
  エラーコード?: string;
  リターンコード?: string;
  エラーメッセージ?: string;
};
*/
export interface IAbstractError {
  共通部?: any;
  from?: AbstractErrorFrom;
}

export default class AbstractError extends Error implements IAbstractError {
  共通部: any;

  from?: AbstractErrorFrom;

  code: string;

  constructor(params?: IAbstractError) {
    super(params?.共通部?.エラーメッセージ);
    this.name = new.target.name;
    if (params?.共通部 == null) {
      this.共通部 = {};
    } else {
      this.共通部 = params?.共通部;
    }
    this.共通部.エラーコード = params?.共通部?.エラーコード;
    this.共通部.リターンコード = params?.共通部?.リターンコード || '9';
    this.code = this.共通部.エラーコード || '5000';
    this.共通部.エラーメッセージ = params?.共通部?.エラーメッセージ;
    this.from = params?.from;
  }
}

import { Message } from '../utils/messages';
import AbstractError, { IAbstractError } from './abstract-error';

export default class ConnectionError extends AbstractError {
  constructor(params?: IAbstractError) {
    super(params);
    this.共通部.エラーコード = params?.共通部?.エラーコード || '5002';
    this.共通部.エラーメッセージ = params?.共通部?.エラーメッセージ || Message.wM001();
  }
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SType,
  StateType,
  ValidationErrorType,
  ConfirmValuesType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    商品名: '',
    預り金残高: '',
    銀行名漢字: '',
    支店名漢字: '',
    振込年月日: '',
    振込時間: '',
    メールアドレス: '',
    処理日時: '',
  },
  usageStatementDeliveryAddress: '1',
  completionDeliveryMailAddress: '',
  completionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  confirmValues: {
    処理日時: '',
  },
  validationError: {
    メールアドレス: '',
    メールアドレス確認: '',
  },
};

const slice = createSlice({
  name: 's',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<SType>) => {
      state.top = action.payload;
    },
    updateUsageStatementDeliveryAddress: (state: StateType, action: PayloadAction<string>) => {
      state.usageStatementDeliveryAddress = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    clearCompletionDeliveryMailAddress: (state: StateType) => {
      state.completionDeliveryMailAddress = initialState.completionDeliveryMailAddress;
    },
    clearCompletionDeliveryMailAddressConfirm: (state: StateType) => {
      state.completionDeliveryMailAddressConfirm = initialState.completionDeliveryMailAddressConfirm;
    },
    clearIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateProcessingDateTime: (state: StateType, action: PayloadAction<string>) => {
      state.top.処理日時 = action.payload;
    },
    updateConfirmValues: (state: StateType, action: PayloadAction<ConfirmValuesType>) => {
      state.confirmValues = action.payload;
    },
  },
});

export default slice;

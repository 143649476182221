export type BackendClientHttpPostParams = {
  body?: any,
  endpoint?: string,
};

/**
 * バックエンドと通信を行うクラス。
 */
export default class BackendClient {
  // バックエンドのオリジン
  static origin = process.env.REACT_APP_BACKEND_ORIGIN || 'http://localhost:5001';

  /**
   * POSTメソッドでバックエンドとHTTP通信を行う。
   * @param params リクエスト設定
   * @returns レスポンス
   */
  static async httpPost(params: BackendClientHttpPostParams) {
    // 送信設定
    const method = 'POST';
    const credentials = 'include';
    const { signal } = BackendClient.getTimeoutController();
    const headers = BackendClient.getHeaders();
    const body = params.body ? JSON.stringify(params.body) : undefined;
    const api = BackendClient.getApi(params.endpoint);
    // 送信
    const response = await fetch(api, {
      method, credentials, signal, headers, body,
    });
    return response;
  }

  /**
   * タイムアウト時間を取得する。
   * @returns タイムアウト時間（ms）
   */
  static getTimeout() {
    return (Number(process.env.REACT_APP_TIMEOUT) || 30) * 1000;
  }

  /**
   * タイムアウト管理クラスのインスタンスを取得する。
   * @returns タイムアウト管理クラスのインスタンス
   */
  static getTimeoutController() {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), BackendClient.getTimeout());
    return controller;
  }

  /**
   * バックエンドのAPIを取得する。
   * @param endpoint バックエンドのエンドポイント
   * @returns バックエンドのAPI
   */
  static getApi(endpoint?: string) {
    const api = BackendClient.origin + BackendClient.getVersion() + (endpoint || '');
    return api;
  }

  /**
   * バックエンドのAPIバージョンを取得する。
   * @returns バックエンドのAPIバージョン
   */
  static getVersion() {
    return '/v1';
  }

  /**
   * ヘッダを取得する。
   * @param authorization 認証トークン
   * @returns ヘッダ
   */
  static getHeaders() {
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
    };
    return headers;
  }

  /**
   * オブジェクトのサニタイジング
   * @param obj サニタイジングするオブジェクト
   */
  static objectSanitize(obj: {}) {
    const o = { ...obj };

    Object.entries(o).forEach(([k, v]) => {
      if (typeof v === 'object' && v instanceof Object) {
        BackendClient.objectSanitizeOnce(v);
      }
    });

    return { ...o };
  }

  /**
   * オブジェクトのサニタイジング
   * @param obj サニタイジングするオブジェクト
   */
  static objectSanitizeOnce(obj: any) {
    Object.entries(obj).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach(BackendClient.objectSanitizeOnce);
      } else if (typeof v === 'object' && v instanceof Object) {
        BackendClient.objectSanitizeOnce(v);
      } else {
        obj[k] = BackendClient.sanitize(v as string);
      }
    });
  }

  /**
   * サニタイジング（<、>、"、'、& をエスケープ）
   * @param text サニタイズする文字列
   * @returns サニタイズされた文字列
   */
  static sanitize(text: string) {
    const s = text.replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#x27;')
      .replace(/&/g, '&amp;');
    return s;
  }
}

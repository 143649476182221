/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import SPFooter from '../../components/SPFooter';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { isLoading } from '../../modules/app';
import { updateViewPattern } from '../../modules/pattern';
import userSlice, { updateTempLoginInfo } from '../../modules/user';
import xSlice from '../../modules/x-login/module';
import topSlice from '../../modules/t-top/module';
import AwsAuthService from '../../services/aws-auth-service';
import McsBackendService from '../../services/mcs-backend-service';
import {
  allLoginValidator,
} from '../../validators/x-login/login-validator';
import ApiCommon from '../../utils/api-common';
import { handleAppliCommonError, postCognitoErrorMessage } from '../../utils/ap-handle-errors';
import urls from '../../utils/urls';
import { useUnmountRef, useSafeState } from '../../utils/safe-state';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import { loadJson } from '../../utils/load-json';
import CognitoBackendService from '../../services/cognito-backend-service';
import { isApp } from '../../utils/device';
import { Message } from '../../utils/messages';

let globalCognitoUser: CognitoUser;
let globalSessionUserAttributes: any;
let globalViewPattern: string;
let globalNextActionValue: string;
let globalAuthId: string;
let globalAuthPwd: string;

let globalLoginId: string;
let globalLoginParams: { [key: string]: string };

const SPAppliGateway: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    clearLoginValidationError,
    updateApplicationParams,
    clearApplicationParams,
    updateCognitoUserInfo,
  } = xSlice.actions;

  const {
    deviceInfo,
    memberInfo,
    loginInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    updateLoggedIn,
    updateDeviceInfo,
    updateMemberInfo,
    updateLoginInfo,
    clearUserInfoInfo,
  } = userSlice.actions;

  const {
    informations,
  } = useSelector((state: RootState) => state.top);

  const {
    updateTop,
    updateBankInfo,
    updateInformations,
    updateLastLoginDateTime,
  } = topSlice.actions;

  useEffect(() => {
    initiate();
    return () => {
      dispatch(isLoading(false));
    };
  }, []);

  const initiate = async () => {
    // ログイン画面遷移時、ログイン関連のユーザー情報をクリアする
    dispatch(updateLoggedIn(false));
    dispatch(clearUserInfoInfo());
    // ユーザーエージェントによる判定
    if (location.pathname === urls.SPAPPLI && isApp()) {
      const bodyElement = document.getElementsByTagName('body');
      bodyElement[0].setAttribute('aria-hidden', 'true');
    }
    await loadInformations();
  };

  const loadInformations = async () => {
    const jsonData = await loadJson('/notice/information.json');
    dispatch(updateInformations(jsonData));
  };

  const unmountRef = useUnmountRef();
  const [newPasswordRequired, setNewPasswordRequired] = useSafeState(unmountRef, false);

  const ACTION_GET_USER_INFO: string = ' ';

  /** アプリ呼び出し用 */
  window.webapi2 = {};
  /** ログイン→TOP */
  window.webapi2.login = async (loginUserId: any, loginPassword: any, isDebug: boolean = false) => {
    globalNextActionValue = ACTION_GET_USER_INFO;

    [globalLoginId, globalLoginParams] = await splitParams(loginUserId);
    if (validate(globalLoginId, loginPassword)) {
      await handleClickLogin(globalLoginId, loginPassword, globalLoginParams, true);
    }
  };

  /** ホームボタン */
  window.webapi2.gotoHome = async (loginUserId: any, loginPassword: any, isDebug: boolean = false) => {
    globalNextActionValue = ACTION_GET_USER_INFO;
    [globalLoginId, globalLoginParams] = await splitParams(loginUserId);
    if (validate(globalLoginId, loginPassword)) {
      await handleClickLogin(globalLoginId, loginPassword, globalLoginParams);
    }
  };

  /** 返済日入力 */
  window.webapi2.gotoRepaymentPlan = async (loginUserId: any, loginPassword: any, isDebug: boolean = false) => {
    globalNextActionValue = urls.B001;
    [globalLoginId, globalLoginParams] = await splitParams(loginUserId);
    await handleClickLogin(globalLoginId, loginPassword, globalLoginParams);
  };

  /** 増額 */
  window.webapi2.gotoLimitIncrease = async (loginUserId: any, loginPassword: any, isDebug: boolean = false) => {
    globalNextActionValue = urls.H001;
    [globalLoginId, globalLoginParams] = await splitParams(loginUserId);
    await handleClickLogin(globalLoginId, loginPassword, globalLoginParams);
  };

  /** 収入証明 */
  window.webapi2.gotoIncomeCertificate = async (loginUserId: any, loginPassword: any, isDebug: boolean = false) => {
    globalNextActionValue = urls.L001;
    [globalLoginId, globalLoginParams] = await splitParams(loginUserId);
    await handleClickLogin(globalLoginId, loginPassword, globalLoginParams);
  };

  /** TOP */
  window.webapi2.gotoTop = async (loginUserId: any, loginPassword: any, isDebug: boolean = false) => {
    globalNextActionValue = urls.TOP;
    [globalLoginId, globalLoginParams] = await splitParams(loginUserId);
    await handleClickLogin(globalLoginId, loginPassword, globalLoginParams);
  };

  /**
   * ログインボタン押下処理
   * @param e MouseEvent
   */
  const handleClickLogin = async (lid: any, lpwd: any, lprm: { [key: string]: string }, isLogin = false) => {
    dispatch(isLoading(true));
    cognitoLogin(lid, lpwd, lprm, isLogin)
      .catch((err) => {
        handleErrors(err);
        dispatch(isLoading(false));
      });
  };

  /**
   * 入力したログイン ID を ID 部分と固有情報部分で分離する
   * @param loginUserId
   */
  const splitParams = async (loginUserId: any) => {
    dispatch(clearApplicationParams());

    // 半角スペース 3 つで実際のログイン ID とアプリ固有 ID を分離する
    const [id, paramStr] = loginUserId.split('   ');
    if (paramStr) {
      const items = paramStr.split(',');
      const params: { [key: string]: string } = {};
      for (let i = 0, l = items.length; i < l; i += 1) {
        if (items[i] !== '') {
          const [key, value] = items[i].split('=');
          if (!Object.prototype.hasOwnProperty.call(params, key)) {
            params[key] = value;
          }
        }
      }

      dispatch(updateApplicationParams(params));
      return [id, params];
    }

    return [id, {}];
  };

  /**
   * Validationを実施する
   */
  const validate = (loginUserId: any, loginPassword: any): boolean => {
    dispatch(clearLoginValidationError());
    // バリデーション
    const validPramas: { ログインID: string, パスワード: string, [key: string]: string } = {
      ログインID: loginUserId,
      パスワード: loginPassword,
    };
    let ret: boolean = true;
    try {
      throwIfJoiValidationError(allLoginValidator(validPramas, { abortEarly: false }));
    } catch (err) {
      ret = false;
    }
    return ret;
  };

  /**
   * Cognitoへの認証処理。
   */
  const cognitoLogin = async (lid: any, lpwd: any, lprm: { [key: string]: string }, isLogin: boolean) => {
    globalAuthId = lid;
    globalAuthPwd = lpwd;
    const awsAuthService = new AwsAuthService();
    const authenticationDetails = new AuthenticationDetails({
      Username: lid, // webId,
      Password: lpwd, // password,
      ClientMetadata: { authKey: lid },
    });
    globalCognitoUser = new CognitoUser({
      Username: lid,
      Pool: awsAuthService.userPool,
      Storage: sessionStorage,
    });
    globalCognitoUser.setAuthenticationFlowType(AwsAuthService.AUTH_FLOW.USER_SRP_AUTH);
    globalCognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(session) {
        // 認証成功
        checkLoginUser(session, lprm, isLogin)
          .catch((err) => {
            handleErrors(err);
            dispatch(isLoading(false));
          });
      },
      async onFailure(err: any) {
        // 認証失敗
        const authErrorMessages = {
          E_ACCOUNT_LOCK: '一定回数以上の認証に失敗したため、アカウントがロックされました。「ID/パスワード設定」から設定をお願いします。',
          E_USER_NOT_FOUND: '認証に失敗しました。ID/パスワードをお忘れの場合は、「ID/パスワードがわからない場合」から再登録をお願いします。ID/パスワードを登録されていない場合は「ID/パスワード設定」から登録をお願いします。',
        };
        const memberId = await findCognitoUser(lid);
        let isDeleteUser = false;
        if (memberId === '') {
          // ユーザーが存在しない場合
          postCognitoErrorMessage(err, authErrorMessages.E_USER_NOT_FOUND);
        } else {
          if (err.message === 'Incorrect username or password.'
            || err.message === 'Missing required parameter USERNAME'
            || err.message === 'PreAuthentication failed with error Incorrect username or password (4016).'
          ) {
            // アカウントロックのチェック
            isDeleteUser = await checkAccountLock(lid, memberId);
          } else if (err.message === 'Password attempts exceeded') {
            // アカウントロックのチェック
            isDeleteUser = await checkAccountLock(lid, memberId);
          }

          // アカウントロックによるアカウント削除が行われた場合にメッセージを切り替える
          if (isDeleteUser) {
            postCognitoErrorMessage(err, authErrorMessages.E_ACCOUNT_LOCK);
          } else {
            postCognitoErrorMessage(err);
          }
        }
        dispatch(isLoading(false));
      },
      newPasswordRequired(userAttributes: any) {
        // 仮パスワード時
        globalSessionUserAttributes = userAttributes;
        delete globalSessionUserAttributes.email_verified;
        delete globalSessionUserAttributes.phone_number_verified;
        setNewPasswordRequired(true);
        dispatch(isLoading(false));
        history.push({ pathname: urls.X002, state: globalCognitoUser });
      },
    });
  };

  /**
   * ログインユーザーの情報と端末情報を確認する。
   */
  const checkLoginUser = async (session: CognitoUserSession, lprm: { [key: string]: string }, isLogin: boolean) => {
    // IEのトークが取得できない問題の対応
    dispatch(updateMemberInfo({ ...memberInfo, memberNo: await AwsAuthService.getUsername(), token: session.getIdToken().getJwtToken() }));
    const apiCommon: ApiCommon = new ApiCommon('X001', isLogin ? 'X-02' : 'X-03', urls.SPAPPLI, '');
    const isGetBlackbox = await apiCommon.updateBlackbox();
    // if (!isGetBlackbox) {
    //   // blackbox 情報を取得できていない場合
    //   history.push(urls.Z001, { message: Message.wM065, code: '5000', isLogin: true });
    // }
    // ログイン ID とパスワードを一時的に保存する
    dispatch(updateTempLoginInfo({
      tempLoginId: globalAuthId,
      tempPassword: globalAuthPwd,
    }));

    const commonPart = await apiCommon.getLoginCommonPart('', '');
    const dataPart = isLogin ? await apiCommon.getLoginInfoDataPart(globalAuthId, globalAuthPwd) : await apiCommon.getLoginInfoDataPartNoLogin(globalAuthId, globalAuthPwd);
    // const dataPart = await apiCommon.getLoginInfoDataPart(globalAuthId, globalAuthPwd);
    dataPart.データ部.会員番号 = await AwsAuthService.getUsername();
    if (lprm) {
      // アプリ固有 ID が渡されている場合はそれをデータ部にセットする
      const keys = Object.keys(lprm);
      for (let i = 0, l = keys.length; i < l; i += 1) {
        // @ts-ignore
        dataPart.データ部[keys[i]] = lprm[keys[i]];
      }
    }

    const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
    dispatch(updateLoggedIn(true));
    dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: json.共通部.配信ID, fromUrl: urls.X002 }));
    dispatch(updateViewPattern(json.共通部.ABテストパターン判定));
    dispatch(updateLoginInfo(json.データ部));

    // Cognito に保存されている customLastLoginDateTime を取得しステートに保持
    const lastLoginDate = await getLastLoginDateTime();

    // Cognito のユーザー情報を保持しておく
    dispatch(updateCognitoUserInfo({
      memberId: await AwsAuthService.getUsername(),
      userName: globalAuthId,
      lastLoginDate,
    }));

    // 処理日時を Cognito に保存
    await setLastLoginDateTime(json.共通部.処理日時);

    // ログイン失敗回数クリア
    await setFailureCount(await AwsAuthService.getUsername(), 0);

    if (globalNextActionValue === urls.TOP) {
      // TOPへの遷移の場合すぐに実施
      dispatch(isLoading(false));
      if (loginInfo.和解客区分 === '1' || loginInfo.ログイン停止区分 === '1') {
        history.push(urls.Q001);
      } else {
        history.push(urls.TOP);
      }
    } else {
      // NativeのTop と それ以外の遷移はTOPの情報を取得しアクションを実施
      globalViewPattern = json.共通部.ABテストパターン判定;
      const postJson = globalNextActionValue === ACTION_GET_USER_INFO ? await userInfoNative() : await userInfo();
      dispatch(updateTop({
        ...postJson.データ部,
        処理日時: postJson.共通部.処理日時,
      }));
      dispatch(updateBankInfo(postJson.データ部.口座情報[0]));
      if (globalNextActionValue === ACTION_GET_USER_INFO) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify(postJson));
        }
        dispatch(isLoading(false));
      } else {
        dispatch(isLoading(false));
        history.push(globalNextActionValue);
      }
    }
  };

  /**
   * 前回ログイン時間を取得する。
   */
  const getLastLoginDateTime = async (): Promise<string> => {
    try {
      const res = await CognitoBackendService.request({
        body: {
          username: await AwsAuthService.getUsername(),
        },
        endpoint: '/getUser',
      });

      for (let i = 0; i < res.UserAttributes.length; i += 1) {
        const element = res.UserAttributes[i];
        if (element.Name === process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_1) {
          dispatch(updateLastLoginDateTime(element.Value));
          return element.Value;
        }
      }
    } catch (err) {
      handleErrors(err);
    }

    return '';
  };

  /**
   * cognitoの前回ログイン時間を更新する。
   */
  const setLastLoginDateTime = async (time: string) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: await AwsAuthService.getUsername(),
          customAttributeValue: time || '',
          customAttribute: process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_1,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
  };

  /**
   * アカウントロックの判定
   * @param username
   */
  const checkAccountLock = async (username: string, memberId: string) => {
    const failureCount = await getFailureCount(username);
    const nextFailureCount = failureCount + 1;
    const currentFailureCount = await setFailureCount(username, nextFailureCount);
    // ログイン失敗回数が 3 回に達した場合はユーザーを削除する
    if (currentFailureCount >= 3) {
      await deleteAcountNotify(memberId);
      await deleteCognitoUser(memberId);
      return true;
    }
    return false;
  };

  /**
   * Cognito ユーザーを検索
   * @param username
   * @returns
   */
  const findCognitoUser = async (username: string) => {
    let memberId = '';
    try {
      const result = await CognitoBackendService.request({
        body: {
          preferredUsername: username,
        },
        endpoint: '/getListUser',
      });
      for (let i = 0, l = result.Users.length; i < l; i += 1) {
        const user = result.Users[i];
        for (let j = 0, m = user.Attributes.length; j < m; j += 1) {
          const element = user.Attributes[j];
          if (element.Name === 'preferred_username') {
            if (element.Value === username) {
              memberId = user.Username;
              break;
            }
          }
        }
        if (memberId !== '') {
          break;
        }
      }
    } catch (err) {
      handleErrors(err);
    }
    return memberId;
  };

  /**
   * Cognito ユーザーを検索し、現在のログイン失敗回数を取得する。
   */
  const getFailureCount = async (username: string) => {
    let customFailureCountValue = '0';
    try {
      const result = await CognitoBackendService.request({
        body: {
          preferredUsername: username,
        },
        endpoint: '/getListUser',
      });

      for (let i = 0, l = result.Users.length; i < l; i += 1) {
        const user = result.Users[i];
        for (let j = 0, m = user.Attributes.length; j < m; j += 1) {
          const element = user.Attributes[j];
          if (element.Name === process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_2) {
            customFailureCountValue = element.Value || '0';
          }
        }
      }
    } catch (err) {
      handleErrors(err);
    }
    return parseInt(customFailureCountValue, 10);
  };

  /**
   * Cognitoのログイン失敗回数をセットする
   */
  const setFailureCount = async (username: string, failureCount: number) => {
    try {
      await CognitoBackendService.request({
        body: {
          username,
          customAttributeValue: failureCount.toString(),
          customAttribute: process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_2,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
    return failureCount;
  };

  /**
   * Cognito ユーザーの削除
   * @param memberId
   * @returns
   */
  const deleteCognitoUser = async (memberId: string) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: memberId,
        },
        endpoint: '/deleteUser',
      });
    } catch (err) {
      handleErrors(err);
    }
  };

  /**
   * 【会員ログイン画面】アカウント削除時の情報連携処理
   */
  const deleteAcountNotify = async (memberId: string) => {
    dispatch(isLoading(true));
    try {
      const apiCommon: ApiCommon = new ApiCommon('X001', 'X-04', urls.LOGIN, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '', memberId);
      const dataPart = {
        データ部: {
          会員番号: memberId,
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * エラー用ハンドラー
   * @param err Error
   */
  const handleErrors = (err: any) => {
    switch (err.code) {
      // 新規デバイス
      case 'WAG10304':
        dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: err.共通部.配信ID, fromUrl: urls.X012 }));
        history.push(urls.X002);
        break;
      // メンテナンス中
      case 'ESGXX001':
        if (globalNextActionValue === ACTION_GET_USER_INFO) {
          if (window.ReactNativeWebView) {
            err.共通部.エラーメッセージ = `ただいまメンテナンス中です。\n${err.共通部.エラーメッセージ}`;
            window.ReactNativeWebView.postMessage(JSON.stringify(err));
          } else {
            history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          }
        } else {
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
        }
        break;
      default:
        handleAppliCommonError(err);
    }
  };

  const userInfo = async () => {
    try {
      const apiCommon: ApiCommon = new ApiCommon('T001', 'T-01', urls.TOP, globalViewPattern);
      const commonPart = await apiCommon.getCommonPart();
      let dataPart;
      if (informations !== null) {
        dataPart = await apiCommon.getUserInfoDataPart(informations.重要なお知らせ更新日時, informations.お知らせ更新日時);
      } else {
        dataPart = await apiCommon.getUserInfoDataPart('', '');
      }
      return await McsBackendService.request(Object.assign(commonPart, dataPart));
    } catch (err) {
      handleAppliCommonError(err);
      dispatch(isLoading(false));
      return '';
    }
  };

  const userInfoNative = async () => {
    try {
      const apiCommon: ApiCommon = new ApiCommon('T001', 'T-10', urls.TOP, globalViewPattern);
      const commonPart = await apiCommon.getCommonPart();
      let dataPart;
      if (informations !== null) {
        dataPart = await apiCommon.getUserInfoNativeDataPart(informations.重要なお知らせ更新日時, informations.お知らせ更新日時);
      } else {
        dataPart = await apiCommon.getUserInfoNativeDataPart('', '');
      }
      return await McsBackendService.request(Object.assign(commonPart, dataPart));
    } catch (err) {
      handleAppliCommonError(err);
      dispatch(isLoading(false));
      return '';
    }
  };

  return (
    <>
      <main className="l-main">
        <div>
          <section>
            <div className="c-ground-01">
              <div className="c-heading-01">
                <h1>
                  <></>
                </h1>
              </div>
              <div className="c-ground-01 mt16 pb10 border-color-06">
                <div className="c-input-text flexC">
                  <></>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <SPFooter />
    </>
  );
};

export default SPAppliGateway;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { getPageIdIfNecessary } from '../utils/page-ids';
import urls from '../utils/urls';

// ヘッダの定義
const Header: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex , nofollow" />
      </Helmet>
      <header id="header" className="header-ja">
        <div className="headerWrapper">
          <div className="headerLogo">
            <Link to={urls.LOGIN}>
              <img src="/img/A/img_header_logo.png" alt="レイク" width="102" />
            </Link>
            <span className={classes.pageId}>{getPageIdIfNecessary(location.pathname)}</span> {/* 画面ID 本番では表示しない予定 */}
          </div>
        </div>
      </header>
    </>
  );
};

const useStyles = makeStyles(() => createStyles({
  pageId: {
    padding: '10px',
    color: 'white',
    fontSize: '14px',
  },
}));

export default Header;

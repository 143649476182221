import { isKonyApp } from './device';

export function transferToNativeMethod(callbackType: string, dataList?: Array<any>) {
  if (!isKonyApp()) {
    return;
  }

  const arr = [];
  arr.push(callbackType);
  if (Array.isArray(dataList)) {
    dataList.forEach((data) => {
      arr.push(data);
    });
  }

  // @ts-ignore
  // eslint-disable-next-line no-undef
  kony.evaluateJavaScriptInNativeContext('callbackFromWebView', JSON.stringify(arr));
}

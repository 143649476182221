/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AType,
  BasicContractType,
  ChangeContractType,
  BasicContractDetailConfirmType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    商品名: '',
    利用可能額: '',
    自振状況: '',
    次回支払日: '',
    次回支払金額: '',
    完済年月日: '',
    完済金額: '',
    残高: '',
    利息: '',
    遅延損害金: '',
    不足金: '',
    手数料累計額: '',
    保留金: '',
    契約年月日: '',
    契約額: '',
    約定利率: '',
    損害金利率: '',
    毎回額: '',
    約定日: '',
    解約日: '',
    基本契約書ダウンロード可能フラグ: '',
    変更契約書ダウンロード可能フラグ: '',
    契約内容確認書ダウンロード可能フラグ: '',
    WEB明細サービス非表示フラグ: '',
    取引履歴: [],
    処理日時: '',
  },
  basicContract: {
    会員番号: '',
    口座枝番: '',
    契約変更履歴: [],
  },
  changeContract: {
    会員番号: '',
    口座枝番: '',
    契約変更履歴: [],
  },
  basicContractDetailConfirm: {
    会員番号: '',
    口座枝番: '',
    契約変更履歴: [],
    契約内容確認書: '0',
  },
  certificateTypeInfo: '',
};

const slice = createSlice({
  name: 'a',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<AType>) => {
      state.top = action.payload;
    },
    updateBasicContract: (state: StateType, action: PayloadAction<BasicContractType>) => {
      state.basicContract = action.payload;
    },
    updateChangeContract: (state: StateType, action: PayloadAction<ChangeContractType>) => {
      state.changeContract = action.payload;
    },
    updateBasicContractDetailConfirm: (state: StateType, action: PayloadAction<BasicContractDetailConfirmType>) => {
      state.basicContractDetailConfirm = action.payload;
    },
    clearContractDownload: (state: StateType) => {
      state.basicContract = initialState.basicContract;
      state.changeContract = initialState.changeContract;
    },
    updateCertificateTypeInfo: (state: StateType, action: PayloadAction<string>) => {
      state.certificateTypeInfo = action.payload;
    },
  },
});

export default slice;

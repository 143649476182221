/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DType,
  WebStatementRegistrationType,
  WebStatementServiceSuspensionType,
  WebStatementServiceCompleteType,
  WebStatementCancellationInfoType,
  ValidationErrorType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  webStatement: {
    会員番号: '',
    月次計算書交付区分: '',
    メールアドレス: '',
    前月明細ダウンロード可能フラグ: '',
    処理日時: '',
  },
  webStatementRegistration: {
    会員番号: '',
    メールアドレス: '',
    ダウンロード開始日: '',
  },
  webStatementServiceSuspension: {
    会員番号: '',
    自宅住所漢字: '',
    勤務先住所漢字: '',
  },
  webStatementServiceComplete: {
    会員番号: '',
    口座枝番: '',
    メールアドレス: '',
    メールアドレス変更区分: '',
    即振対象: '',
    ダウンロード開始日: '',
  },
  webStatementCancellationInfo: {
    会員番号: '',
    前月明細ダウンロード可能フラグ: '',
    処理日時: '',
  },
  validationError: {
    メールアドレス: '',
    メールアドレス確認: '',
    送付先区分: '',
    ご利用明細: [
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
    ],
  },
  completionDeliveryMailAddress: '',
  completionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  inputStatementMailingAddress: '1',
};

const slice = createSlice({
  name: 'd',
  initialState,
  reducers: {
    updateWebStatement: (state: StateType, action: PayloadAction<DType>) => {
      state.webStatement = action.payload;
    },
    updateWebStatementRegistration: (state: StateType, action: PayloadAction<WebStatementRegistrationType>) => {
      state.webStatementRegistration = action.payload;
    },
    updateWebStatementServiceSuspension: (state: StateType, action: PayloadAction<WebStatementServiceSuspensionType>) => {
      state.webStatementServiceSuspension = action.payload;
    },
    updateWebStatementServiceComplete: (state: StateType, action: PayloadAction<WebStatementServiceCompleteType>) => {
      state.webStatementServiceComplete = action.payload;
    },
    updateWebStatementCancellationInfo: (state: StateType, action: PayloadAction<WebStatementCancellationInfoType>) => {
      state.webStatementCancellationInfo = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateStatementMailingAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputStatementMailingAddress = action.payload;
    },
  },
});

export default slice;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { AppDispatch, store } from '../../store';

import SfApiException from '../../errors/sf-api-exception';
import { isLoading, updateDialog } from '../../modules/app';
import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';

import xSlice from '../../modules/x-login/module';
import userSlice from '../../modules/user';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import {
  nameKanaValidator,
  birthdayValidator,
  phoneNumberValidator,
  threeAuthValidator,
  birthYearValidator,
  birthMonthValidator,
  birthDateValidator,
} from '../../validators/x-login/login-validator';
import { LoginQA } from '../../basics/QA/x_loginQA';
import { convertHiraKanaToKataKana, convertZenkakuKanaToHankakuKana } from '../../utils/format';
import { isKonyApp } from '../../utils/device';
import { removeWarnReloading, warnReloading } from '../../utils/warnReloading';

// X008
const NewIDAndPassword: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const nextUrl = new URLSearchParams(location.search).get('next');
  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const [errorMessage, setErrorMessage] = useState('');

  const [view, setView] = useState(false);

  const {
    inputUserInfo,
    inputUserInfoValidationError,
    inputSettingItem,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    updateResponseUserInfo,
    updateInputUserInfoValidationError,
    clearInputUserInfoValidationError,
    updateInputSettingItem,
    updateWebIdRegistered,
    updateRegisteredEMailAddress,
    updateInputUserInfoNameKana,
    updateInputUserInfoBirthday,
    updateInputUserInfoBirthYear,
    updateInputUserInfoBirthMonth,
    updateInputUserInfoBirthDate,
    updateInputUserInfoPhoneNumber,
  } = xSlice.actions;

  const {
    loggedIn,
    deviceInfo,
    memberInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    updateDeviceInfo,
    updateMemberInfo,
  } = userSlice.actions;

  const app = Object.getOwnPropertyDescriptor(store.getState(), 'app')?.value;

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        case 'WAG10013':
        case 'ESG00032':
          history.push(urls.X009);
          break;
        case 'WAG10304': // 新規デバイス
          dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: err.共通部.配信ID, fromUrl: urls.X008 }));
          dispatch(updateMemberInfo({ ...memberInfo, memberNo: err.共通部.会員番号 }));
          dispatch(updateInputSettingItem(inputSettingItem));
          history.push(urls.X009);
          break;
        // HEL 会員の場合
        case 'WAG20098':
          handleCommonError(err);
          break;
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        case 'WAG10301':
        case 'WAG10302':
        case 'WAG10303':
        case 'WAG10305':
        case 'WAG10306':
        case 'WAG10307':
        case 'WAG10308':
          store.dispatch(updateDialog({
            ...app.dialog,
            title: `システムエラーが発生しました`,
            message: `${err.共通部.エラーコード}`,
            open: true,
          }));
          break;
        default:
          store.dispatch(updateDialog({
            ...app.dialog,
            title: `${err.共通部.エラーメッセージ}`,
            message: `${err.共通部.エラーコード}`,
            open: true,
          }));
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: inputUserInfoValidationError,
      updateValidationError: updateInputUserInfoValidationError,
    });
  };

  /**
   * 【初回ID・パスワード発行（お客さま情報入力）画面】認証コードを送信するボタン処理
   */
  const handleClickCodeSend = async () => {
    dispatch(isLoading(true));
    setView(true);

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    dispatch(updateInputUserInfoNameKana(convertZenkakuKanaToHankakuKana(convertHiraKanaToKataKana(inputUserInfo.カナ氏名))));
    dispatch(updateInputUserInfoPhoneNumber(convertToFormattedPhoneNumber(inputUserInfo.電話番号)));

    // 認証コードの送信
    try {
      warnReloading();
      const apiCommon: ApiCommon = await new ApiCommon('X008', 'X-02', urls.X008, '');
      if (!isKonyApp()) {
        await apiCommon.updateBlackbox();
      }
      const appBlackboxCheck = await apiCommon.checkAppBlackbox();
      if (appBlackboxCheck) {
        const commonPart = await apiCommon.getLoginCommonPart('', '');
        const dataPart = {
          データ部: {
            カナ氏名: convertZenkakuKanaToHankakuKana(convertHiraKanaToKataKana(inputUserInfo.カナ氏名)),
            生年月日: inputUserInfo.生年月日,
            電話番号: convertToFormattedPhoneNumber(inputUserInfo.電話番号),
            会員番号: '',
            メールアドレス: '',
          },
        };
        const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
        // 会員番号を保持しておく
        dispatch(updateResponseUserInfo({
          ...json.データ部,
          配信ID: json.共通部.配信ID,
          希望設定項目: inputSettingItem,
        }));
        dispatch(updateMemberInfo({ ...memberInfo, memberNo: json.データ部.会員番号 }));
        dispatch(updateWebIdRegistered(json.データ部.メールアドレス.toLowerCase()));
        dispatch(updateRegisteredEMailAddress(json.データ部.メールアドレス));
        history.push(urls.X010);
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  const confirmValidation = () => {
    dispatch(clearInputUserInfoValidationError());
    const birthdayValue = `${inputUserInfo.生年月日年}${inputUserInfo.生年月日月.padStart(2, '0')}${inputUserInfo.生年月日日.padStart(2, '0')}`;
    dispatch(updateInputUserInfoBirthday(birthdayValue));

    // バリデーション
    const validParams: {
      カナ氏名: string;
      生年月日: string;
      電話番号: string;
    } = {
      カナ氏名: convertZenkakuKanaToHankakuKana(convertHiraKanaToKataKana(inputUserInfo.カナ氏名)),
      生年月日: birthdayValue,
      電話番号: convertToFormattedPhoneNumber(inputUserInfo.電話番号),
    };
    try {
      throwIfJoiValidationError(threeAuthValidator(validParams, { abortEarly: false }, { nameKana: 'お名前', phoneNumber: '電話番号' }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }
    return true;
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updateInputUserInfoValidationError({ ...inputUserInfoValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      const birthdayValue = `${inputUserInfo.生年月日年}${inputUserInfo.生年月日月.padStart(2, '0')}${inputUserInfo.生年月日日.padStart(2, '0')}`;
      dispatch(updateInputUserInfoBirthday(birthdayValue));
      switch (name) {
        case 'カナ氏名':
          throwIfJoiValidationError(nameKanaValidator({
            [name]: convertZenkakuKanaToHankakuKana(convertHiraKanaToKataKana(inputUserInfo.カナ氏名)),
          }, undefined, 'お名前'));
          break;
        case '生年月日':
          throwIfJoiValidationError(birthdayValidator({
            [name]: birthdayValue,
          }));
          break;
        case '生年月日年':
          throwIfJoiValidationError(birthYearValidator({
            [name]: inputUserInfo.生年月日年,
          }));
          break;
        case '生年月日月':
          throwIfJoiValidationError(birthMonthValidator({
            [name]: inputUserInfo.生年月日月,
          }));
          break;
        case '生年月日日':
          throwIfJoiValidationError(birthDateValidator({
            [name]: inputUserInfo.生年月日日,
          }));
          break;
        case '電話番号':
          throwIfJoiValidationError(phoneNumberValidator({
            [name]: convertToFormattedPhoneNumber(inputUserInfo.電話番号),
          }, undefined, '電話番号'));
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const createBirthYearSelectBox = () => {
    const options: JSX.Element[] = [];
    const yearList: string[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1900; i -= 1) {
      yearList.push(i.toString());
    }

    options.push(<option value="" key="">--</option>);
    yearList.forEach((v, i) => {
      options.push(<option value={v} key={(i + 1).toString()}>{v}</option>);
    });

    return options;
  };

  const createBirthDateSelectBox = () => {
    const options: JSX.Element[] = [];

    options.push(<option value="" key="">--</option>);
    for (let i = 1; i <= 31; i += 1) {
      if (inputUserInfo?.生年月日月 !== '' && i === 31) {
        if ([1, 3, 5, 7, 8, 10, 12].includes(parseInt(inputUserInfo?.生年月日月, 10))) {
          options.push(<option value={i} key={i}>{i}</option>);
        }
      } else if (inputUserInfo?.生年月日月 !== '' && i === 30) {
        if (parseInt(inputUserInfo?.生年月日月, 10) !== 2) {
          options.push(<option value={i} key={i}>{i}</option>);
        }
      } else if (inputUserInfo?.生年月日月 !== '' && i === 29) {
        if (parseInt(inputUserInfo?.生年月日月, 10) !== 2) {
          options.push(<option value={i} key={i}>{i}</option>);
        } else {
          const year = parseInt(inputUserInfo?.生年月日年, 10);
          if (year % 4 === 0) {
            if (year % 100 === 0 && year % 400 > 0) {
              //
            } else {
              options.push(<option value={i} key={i}>{i}</option>);
            }
          }
        }
      } else {
        options.push(<option value={i} key={i}>{i}</option>);
      }
    }
    return options;
  };

  const createBirthdayValidationError = () => {
    if (inputUserInfoValidationError.生年月日年 || inputUserInfoValidationError.生年月日月 || inputUserInfoValidationError.生年月日日 || inputUserInfoValidationError.生年月日) {
      return (
        <p className="error-comment">生年月日を選択してください。</p>
      );
    }
    return (<></>);
  };

  const convertToFormattedPhoneNumber = (str: string): string => {
    // 文字列から全角と半角のハイフンを削除
    const removed = str.replace(/[－ー―‐]/g, '').replace(/[-]/g, '');
    // 半角に変換して返す
    return removed.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>ID/パスワード設定</h1>
                  </div>
                  <p className="mbFLAT">会員ログインの際に必要なIDとパスワードの設定をお願いします。</p>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="blockNarrow">
                  <h2 className="ttlH2 mtFLAT mbXL">お客さま情報の入力</h2>
                  <div className="form__inputBox mb05gd">
                    <div className={`form__inputBox__outer ${inputUserInfo.カナ氏名 !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                      <input
                        type="text"
                        maxLength={20}
                        name="カナ氏名"
                        value={inputUserInfo.カナ氏名}
                        onChange={(e) => dispatch(updateInputUserInfoNameKana(e.target.value))}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                      />
                      <span className="form__inputBox__placeholder">お名前（カナ）</span>
                    </div>
                    <p className="error-comment">{inputUserInfoValidationError.カナ氏名}</p>
                  </div>
                  <p className="label fs2XS mbXS">生年月日（西暦）</p>
                  <div className="mb05gd">
                    <div className="form__selctList form__selctList--date">
                      <div className={`form__selctList__item form__inputBox__outer ${inputUserInfo?.生年月日年 !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <select name="生年月日年" value={inputUserInfo?.生年月日年} onChange={(e) => dispatch(updateInputUserInfoBirthYear(e.target.value))} onBlur={(e) => onBlurValidate(e.target.name)}>
                          {createBirthYearSelectBox()}
                        </select>
                        <span className="form__inputBox__placeholder">年</span>
                      </div>
                      <div className={`form__selctList__item form__inputBox__outer ${inputUserInfo?.生年月日月 !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <select name="生年月日月" value={inputUserInfo?.生年月日月} onChange={(e) => dispatch(updateInputUserInfoBirthMonth(e.target.value))} onBlur={(e) => onBlurValidate(e.target.name)}>
                          <option value="">--</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        <span className="form__inputBox__placeholder">月</span>
                      </div>
                      <div className={`form__selctList__item form__inputBox__outer ${inputUserInfo?.生年月日日 !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <select name="生年月日日" value={inputUserInfo?.生年月日日} onChange={(e) => dispatch(updateInputUserInfoBirthDate(e.target.value))} onBlur={(e) => onBlurValidate(e.target.name)}>
                          {createBirthDateSelectBox()}
                        </select>
                        <span className="form__inputBox__placeholder">日</span>
                      </div>
                      {createBirthdayValidationError()}
                    </div>
                  </div>
                  <div className="form__inputBox mbXL">
                    <div className={`form__inputBox__outer ${inputUserInfo?.電話番号 !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                      <input
                        type="text"
                        maxLength={15}
                        name="電話番号"
                        value={inputUserInfo?.電話番号}
                        onChange={(e) => dispatch(updateInputUserInfoPhoneNumber(e.target.value))}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                      />
                      <span className="form__inputBox__placeholder">登録済みの電話番号</span>
                    </div>
                    <ul className="listNote">
                      <li className="listNote__item">数字&emsp;ハイフンなし</li>
                    </ul>
                    <p className="error-comment">{inputUserInfoValidationError.電話番号}</p>
                  </div>
                  <div className="mbFLAT flexC">
                    <button className="btn btn--cv" onClick={handleClickCodeSend}>送信</button>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default NewIDAndPassword;

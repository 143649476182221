import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import { createLinkUrlGeneral } from '../utils/create-link-url';
import BlankLink from './BlankLink';

const CashingBanner: React.FC = () => {
  const { top } = useSelector((state: RootState) => state.top);

  if (top.勤務先区分 === '3') {
    return (
      <BlankLink href={createLinkUrlGeneral('cashing/other/business/')}>
        <img src="img/A/T001/bn_alsadeomatome3.png" alt="レイクdeおまとめ" className="forSP" />
        <img src="img/A/T001/bn_alsadeomatome3-pc.png" alt="レイクdeおまとめ" className="forPC" />
      </BlankLink>
    );
  }
  return (
    <BlankLink href={createLinkUrlGeneral('cashing/other/omatome/')}>
      <img src="img/A/T001/bn_alsadeomatome2.png" alt="レイクdeおまとめ" className="forSP" />
      <img src="img/A/T001/bn_alsadeomatome2-pc.png" alt="レイクdeおまとめ" className="forPC" />
    </BlankLink>
  );
};

export default CashingBanner;

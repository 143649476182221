import { Message } from './messages';

export class CustomValidator {
  protected targetValue = '';

  protected errorMessage = '';

  /**
   * コンストラクタ
   * @param s カスタムバリデーションのチェック対象となる文字列
   */
  constructor(s: string) {
    this.targetValue = s;
  }

  set ErrorMessage(value: string) { this.errorMessage = value; }

  get ErrorMessage() { return this.errorMessage; }

  /**
   * 最終的なエラーメッセージの取得
   * @returns 最終的なエラーメッセージ。エラーなしの場合は空文字列が返却される
   */
  getLastErrorMessage(): string {
    return this.ErrorMessage;
  }

  /**
   * 同じ文字が 3 回以上連続している場合は NG
   * @returns this
   */
  tripleOver() {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }
    // 同じ文字が 3 回以上連続している場合は NG
    if (this.targetValue.match(/(.)\1{2,}/)) {
      this.ErrorMessage = Message.vM062();
    }
    return this;
  }

  /**
   * 文字数のチェック
   * @param min 有効な最小文字数
   * @param max 有効な最大文字数
   */
  checkBetweenLength(min: number, max: number, args: string) {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }

    // 文字数が許容範囲外の場合にエラー
    if (this.targetValue.length < min || this.targetValue.length > max) {
      this.ErrorMessage = Message.vM012(args);
    }
    return this;
  }

  /**
   * 入力文字列のポリシーチェック
   * @returns this
   */
  inputPolicyCheck() {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }

    // 英小文字、数字、記号を含む場合は OK
    if (this.targetValue.match(/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[a-z\d!-/:-@[-`{-~]{8,16}$/)) {
      return this;
    }

    // 英大文字、数字、記号を含む場合は OK
    if (this.targetValue.match(/^(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[A-Z\d!-/:-@[-`{-~]{8,16}$/)) {
      return this;
    }

    // 英小文字、英大文字、記号を含む場合は OK
    if (this.targetValue.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[!-/:-@[-`{-~])[a-zA-Z!-/:-@[-`{-~]{8,16}$/)) {
      return this;
    }

    // 英小文字、英大文字、数字を含む場合は OK
    if (this.targetValue.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,16}$/)) {
      return this;
    }

    // 英小文字、英大文字、数字、記号を含む場合は OK
    if (this.targetValue.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[a-zA-Z\d!-/:-@[-`{-~]{8,16}$/)) {
      return this;
    }

    this.ErrorMessage = Message.vM060();

    return this;
  }

  /**
   * 入力したチェック対象の文字列がログイン ID と同じ場合にエラー
   * @param s 比較対象の文字列
   * @returns this
   */
  invalidMatchLoginId(s: string) {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }

    // 入力したパスワードがログイン ID と異なっている場合は OK
    if (s && s === this.targetValue) {
      this.ErrorMessage = Message.vM061();
    }
    return this;
  }

  /**
   * 入力したチェック対象の文字列が現在のパスワードと同じ場合にエラー
   * @param s 比較対象の文字列
   * @returns this
   */
  invalidMatchCurrentPassword(s: string) {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }

    // 入力したパスワードが現在のパスワードと同じ場合は OK
    if (s && s === this.targetValue) {
      this.ErrorMessage = Message.vM063();
    }
    return this;
  }

  /**
   * 確認用パスワードと入力したパスワードが異なる場合はエラー
   * @param s 比較対象の文字列
   * @returns this
   */
  invalidMatchPassword(s: string) {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }

    // 確認用パスワードと入力したパスワードが同じ場合は OK
    if (s && s !== this.targetValue) {
      this.ErrorMessage = Message.vM022();
    }
    return this;
  }

  /**
   * 許容されない記号が使用されているかをチェックする
   * @returns this
   */
  checkNotSupportSymboles() {
    // 既に何らかのエラーがセットされている場合は後続処理を実施しない
    if (this.ErrorMessage !== '') {
      return this;
    }

    // 許容されない記号（｡｢｣､･）を含む場合は NG
    if (this.targetValue.match(/[｡-･]/)) {
      this.ErrorMessage = Message.vM029();
    }
    return this;
  }
}

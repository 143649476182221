/* eslint-disable lines-between-class-members */
/* eslint-disable linebreak-style */
/* eslint-disable comma-dangle */
import AwsAuthService from '../services/aws-auth-service';
import { store } from '../store';
import { isKonyApp } from './device';
import { transferToNativeMethod } from './transferToNativeMethod';
/**
 * APIの共通部を作成するクラス
 */
export default class ApiCommon {
  private screenId: string;
  private runId: string;
  private url: string;
  private viewPattern: string;
  private blackbox: string;

  /**
   * コンストラクタ
   * @param screenId 画面ID
   * @param runId 処理ID
   * @param url URLフルパス
   * @param viewPattern ABテストパターン
   */
  constructor(screenId: string, runId: string, url: string, viewPattern: string) {
    this.screenId = screenId;
    this.runId = runId;
    this.url = url;
    this.viewPattern = viewPattern;
    // 初期化時は保存されているBlackboxを利用する。
    let localBlackbox;
    if (ApiCommon.isSessionStorageAvailable()) {
      localBlackbox = sessionStorage.getItem('io_blackbox');
    } else {
      localBlackbox = localStorage.getItem('io_blackbox');
    }
    this.blackbox = (localBlackbox == null) ? '' : localBlackbox;
  }

  /**
   * APIのJSONの共通部を作成します。
   * @returns JSON共通部
   */
  public getCommonPart = async () => {
    // const user = Object.getOwnPropertyDescriptor(store.getState(), 'user')?.value;
    const json = {
      共通部: {
        画面ID: this.screenId,
        処理ID: this.runId,
        画面URL: window.location.origin + this.url,
        認証トークン: await AwsAuthService.getIdToken(),
        // 認証トークン: user.memberInfo.token, // await AwsAuthService.getIdToken(),
        クライアントIPアドレス: '',
        クライアントBlackbox情報: this.blackbox,
        会員番号: await AwsAuthService.getUsername(),
        // 会員番号: user.memberInfo.memberNo, // await AwsAuthService.getUsername(),
        ABテストパターン判定: this.viewPattern,
        処理日時: '',
        リターンコード: '',
        エラーコード: '',
        エラーメッセージ: '',
      }
    };
    return json;
  }

  /**
   * ログイン時のAPIのJSONの共通部を作成する。
   * @param ontimePassword ワンタイムパスワード
   * @param distributionId 配信ID
   * @param memberId 会員番号 userに保持されていない場合に使用
   * @returns JSON共通部
   */
  public getLoginCommonPart = async (ontimePassword: string, distributionId: string, memberId?: string, token?: string) => {
    const user = Object.getOwnPropertyDescriptor(store.getState(), 'user')?.value;
    const json = {
      共通部: {
        画面ID: this.screenId,
        処理ID: this.runId,
        画面URL: window.location.origin + this.url,
        認証トークン: token || user.memberInfo.token, // await AwsAuthService.getIdToken(),
        // 認証トークン: user.memberInfo.token, // await AwsAuthService.getIdToken(),
        クライアントIPアドレス: '',
        クライアントBlackbox情報: this.blackbox,
        会員番号: memberId || user.memberInfo.memberNo, // await AwsAuthService.getUsername(),
        ABテストパターン判定: this.viewPattern,
        処理日時: '',
        ワンタイムパスワード: ontimePassword,
        配信ID: distributionId,
        リターンコード: '',
        エラーコード: '',
        エラーメッセージ: '',
      }
    };
    return json;
  }

  /**
   * ログイン前のAPIのJSONの共通部を作成する。
   * @returns JSON共通部
   */
  public getLoginBeforeCommonPart = async () => {
    const json = {
      共通部: {
        画面ID: this.screenId,
        処理ID: this.runId,
        画面URL: window.location.origin + this.url,
        認証トークン: '',
        クライアントIPアドレス: '',
        クライアントBlackbox情報: this.blackbox,
        会員番号: '',
        ABテストパターン判定: '',
        処理日時: '',
        リターンコード: '',
        エラーコード: '',
        エラーメッセージ: '',
      }
    };
    return json;
  }

  /**
   * iovationのBlackboxを取得し保存する
   */
  public updateBlackbox = async (): Promise<boolean> => {
    try {
      // this.blackbox = await window.IGLOO.getBlackbox().blackbox;
      const bb = await window.IGLOO.getBlackbox();
      this.blackbox = bb.blackbox;
      // Storageに入れる
      if (ApiCommon.isSessionStorageAvailable()) {
        sessionStorage.setItem('io_blackbox', `${this.blackbox}`);
      } else {
        localStorage.setItem('io_blackbox', `${this.blackbox}`);
      }
      return true;
    } catch (err) {
      // console.log(`Unable to get blackbox. ${err}`);
      return false;
    }
  };

  /**
 * アプリからのアクセスの場合iovationのBlackboxの値があるか確認する
 */
  public checkAppBlackbox = async (): Promise<boolean> => {
    if (isKonyApp() && !this.blackbox) {
      const transferInfo = [];
      const errorCode = 'W9900001';
      const errorMessage = '処理の途中で問題が発生しました。ご迷惑をおかけしますが、最初からお手続きください。';
      transferInfo.push(errorCode, errorMessage);
      transferToNativeMethod('ERROR_LOGOUT', transferInfo);
      return false;
    }
    return true;
  }

  /**
   * APIのJSONの共通部を作成する。
   * @param screenId 画面ID
   * @param runId 処理ID
   * @param url URLフルパス
   * @param viewPattern ABテストパターン判定
   * @returns JSON共通部
   */
  public static getCommonPart =
    async (screenId: string, runId: string, url: string, viewPattern: string) => {
      const commonPart = new ApiCommon(screenId, runId, url, viewPattern);
      return commonPart.getCommonPart();
    };

  /**
   * 認証前のAPIのJSONの共通部を作成する。
   * @param screenId 画面ID
   * @param runId 処理ID
   * @param url URLフルパス
   * @returns JSON共通部
   */

  public static getCommonPartBefo =
    async (screenId: string, runId: string, url: string, viewPattern: string) => {
      const commonPart = new ApiCommon(screenId, runId, url, viewPattern);
      return commonPart.getLoginBeforeCommonPart();
    };

  public static isSessionStorageAvailable = () => ApiCommon.storageAvailable(sessionStorage);
  public static isLocalStorageAvailable = () => ApiCommon.storageAvailable(localStorage);

  private static storageAvailable = (st: any) => {
    const storage = st;
    try {
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
        // acknowledge QuotaExceededError only if there's something already stored
        && (storage && storage.length !== 0);
    }
  }

  public getUserInfoDataPart = async (impInfoUpdateDate: string, infoUpdateDate: string) => {
    const dataPart = {
      データ部: {
        会員番号: await AwsAuthService.getUsername(),
        勤務先区分: '',
        カード発行状況: '',
        ＴＣ口座登録有無: '',
        ABテストパターン判定: '',
        重要なお知らせの最終更新日時: impInfoUpdateDate || '',
        お知らせの最終更新日時: infoUpdateDate || '',
        新着メッセージ有無区分: '',
        融資一時停止区分: '',
        メッセージ重要: '',
        メッセージ重要遷移先機能: '',
        メッセージ重要ボタン名: '',
        債権ランク: '',
        口座情報: [
          {
            口座枝番: '0',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '1',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '2',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '3',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '4',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '5',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '6',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '7',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '8',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
          {
            口座枝番: '9',
            商品名: '',
            無利息期間: '',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            自振現状区分: '',
            自振状況区分: '',
            自振登録状況: '',
            毎回額: '',
            返済金額: '',
            メッセージ帯: '',
            メッセージＡ１タイトル: '',
            メッセージＡ１: '',
            メッセージＡ１遷移先機能: '',
            メッセージＡ１ボタン名: '',
            メッセージＡ２タイトル: '',
            メッセージＡ２: '',
            メッセージＡ２遷移先機能: '',
            メッセージＡ２ボタン名: '',
            メッセージＢ: '',
            メッセージＢ遷移先機能: '',
            メッセージＢボタン名: '',
            カード発行区分: '',
            解約区分: '',
          },
        ],
      },
    };
    return dataPart;
  }

  public getUserInfoNativeDataPart = async (impInfoUpdateDate: string, infoUpdateDate: string) => {
    const dataPart = {
      データ部: {
        会員番号: await AwsAuthService.getUsername(),
        重要なお知らせの最終更新日時: impInfoUpdateDate || '',
        お知らせの最終更新日時: infoUpdateDate || '',
        新着メッセージ有無区分: '',
        sn: '',
        融資一時停止区分: '',
        口座情報: [
          {
            口座枝番: '0',
            利用可能額: '',
            残高: '',
            次回支払年月日: '',
            返済金額: '',
          },
        ],
      },
    };
    return dataPart;
  }

  public getLoginInfoDataPart = async (webId: string, password: string) => {
    const dataPart = {
      データ部: {
        会員番号: '',
        ログインID: webId,
        パスワード: password,
        代表電話番号: '',
        和解客区分: '',
        ログイン停止区分: '',
        口座情報: [
          {
            口座枝番: '0',
            解約区分: '',
          },
          {
            口座枝番: '1',
            解約区分: '',
          },
          {
            口座枝番: '2',
            解約区分: '',
          },
          {
            口座枝番: '3',
            解約区分: '',
          },
          {
            口座枝番: '4',
            解約区分: '',
          },
          {
            口座枝番: '5',
            解約区分: '',
          },
          {
            口座枝番: '6',
            解約区分: '',
          },
          {
            口座枝番: '7',
            解約区分: '',
          },
          {
            口座枝番: '8',
            解約区分: '',
          },
          {
            口座枝番: '9',
            解約区分: '',
          },
        ],
      },
    };
    return dataPart;
  }

  public getLoginInfoDataPartNoLogin = async (webId: string, password: string) => {
    const dataPart = {
      データ部: {
        会員番号: '',
        ログインID: webId,
        パスワード: password,
        代表電話番号: '',
        口座情報: [
          {
            口座枝番: '0',
            解約区分: '',
          },
          {
            口座枝番: '1',
            解約区分: '',
          },
          {
            口座枝番: '2',
            解約区分: '',
          },
          {
            口座枝番: '3',
            解約区分: '',
          },
          {
            口座枝番: '4',
            解約区分: '',
          },
          {
            口座枝番: '5',
            解約区分: '',
          },
          {
            口座枝番: '6',
            解約区分: '',
          },
          {
            口座枝番: '7',
            解約区分: '',
          },
          {
            口座枝番: '8',
            解約区分: '',
          },
          {
            口座枝番: '9',
            解約区分: '',
          },
        ],
      },
    };
    return dataPart;
  }
}

import AbstractError, { IAbstractError } from './abstract-error';

export type ValidationErrorDetail = {
  field?: string,
  message: string,
}

export interface IValidationError extends IAbstractError {
  details?: ValidationErrorDetail[];
}

export default class ValidationError extends AbstractError implements IValidationError {
  details?: ValidationErrorDetail[]

  constructor(params?: IValidationError) {
    super(params);
    this.共通部.エラーコード = params?.共通部?.エラーコード || '4220';
    this.共通部.エラーメッセージ = params?.共通部?.エラーメッセージ || '';
    this.details = params?.details;
  }

  public messages = (): string => {
    if (!this.details) return '';
    let arr: Array<string> = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const detail of this.details) {
      arr.push(detail.message);
    }
    return arr.join('\n');
  }
}

/* eslint-disable prefer-destructuring */
import React from 'react';
import Crypto from 'crypto';
import { useHistory, useLocation } from 'react-router-dom';
import urls from '../../../utils/urls';

// I007
const FinancialInstitutionGateway: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const param = query.get('data');
  let skno = '';
  let bptn = '';
  let bill = '';
  let sn = '';
  let id = '';
  let bankSiteURL = '';

  const handleErrors = () => {
    history.push(urls.Z001, { message: 'システムエラーが発生しました。', code: '5000' });
  };

  const key = Buffer.from(process.env.REACT_APP_CRYPTO_KEY || '', 'hex');
  const iv = Buffer.from(process.env.REACT_APP_CRYPTO_IV || '', 'hex');
  if (param) {
    try {
      window.history.pushState(null, '', window.location.href.split('?')[0]);
      const decipher = Crypto.createDecipheriv('aes-128-cbc', key, iv);
      let decryptedInfo = decipher.update(param.replaceAll(' ', '+'), 'base64', 'utf8');
      decryptedInfo += decipher.final('utf8');
      const financialInstitutionInfo = JSON.parse(decryptedInfo);
      skno = financialInstitutionInfo[0];
      bptn = financialInstitutionInfo[1];
      bill = financialInstitutionInfo[2];
      sn = financialInstitutionInfo[3];
      id = financialInstitutionInfo[4];
      bankSiteURL = financialInstitutionInfo[5];

      document.addEventListener('DOMContentLoaded', () => {
        document.forms[0].submit();
      });
    } catch (err) {
      handleErrors();
    }
  } else {
    handleErrors();
  }

  return (
    <div className="flexC">
      <form name="outerLink" method="POST" action={bankSiteURL} acceptCharset="Shift_jis">
        <input type="hidden" name="skno" value={skno} />
        <input type="hidden" name="bptn" value={bptn} />
        <input type="hidden" name="bill" value={bill} />
        <input type="hidden" name="sn" value={sn} />
        <input type="hidden" name="id" value={id} />
      </form>
    </div>
  );
};

export default FinancialInstitutionGateway;

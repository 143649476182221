import Joi from 'joi';
import { Message } from '../../utils/messages';

export const yearlyincomeSchema = () => ({
  税込年収: Joi.number()
    .label('年収')
    .integer()
    .required()
    .min(1)
    .max(9999)
    .messages({
      'any.required': Message.vM003(),
      'number.empty': Message.vM003(),
      'number.base': Message.vM007('{#label}'),
      'number.integer': Message.vM007('{#label}'),
      'number.unsafe': Message.vM005('{#label}'),
      'number.min': Message.vM012('1以上'),
      'number.max': Message.vM012('4桁以内'),
    }),
});

export const housingLoanSchema = () => ({
  家賃住宅ローン: Joi.number()
    .label('家賃住宅ローン')
    .integer()
    .required()
    .min(0)
    .max(999)
    .messages({
      'any.required': Message.vM003(),
      'number.empty': Message.vM003(),
      'number.base': Message.vM007('{#label}'),
      'number.integer': Message.vM007('{#label}'),
      'number.unsafe': Message.vM005('{#label}'),
      'number.max': Message.vM012('3桁以内'),
      'number.min': Message.vM012('0以上'),
    }),
});

export const housemateNumberSchema = () => ({
  本人を除く同居人数: Joi.number()
    .label('本人を除く住居人数')
    .integer()
    .required()
    .min(0)
    .max(9)
    .messages({
      'any.required': Message.vM003(),
      'number.empty': Message.vM003(),
      'number.base': Message.vM007('{#label}'),
      'number.integer': Message.vM007('{#label}'),
      'number.unsafe': Message.vM005('{#label}'),
      'number.max': Message.vM012('1桁以内'),
      'number.min': Message.vM012('0以上'),
    }),
});

export const borrowingsAmountSchema = () => ({
  他社お借入れ総額: Joi.number()
    .label('他社お借入れ総額')
    .integer()
    .required()
    .min(0)
    .max(999)
    .messages({
      'any.required': Message.vM003(),
      'number.empty': Message.vM003(),
      'number.base': Message.vM007('{#label}'),
      'number.integer': Message.vM007('{#label}'),
      'number.unsafe': Message.vM005('{#label}'),
      'number.max': Message.vM012('3桁以内'),
      'number.min': Message.vM012('0以上'),
    }),
});

export const yearlyincomeValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(yearlyincomeSchema());
  return schema.validate(data, options);
};

export const housingLoanValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(housingLoanSchema());
  return schema.validate(data, options);
};

export const housemateNumberValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(housemateNumberSchema());
  return schema.validate(data, options);
};

export const borrowingsAmountValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object(borrowingsAmountSchema());
  return schema.validate(data, options);
};

export const allTogetherValidator = (data: any, options?: Joi.ValidationOptions) => {
  const schema = Joi.object({
    ...yearlyincomeSchema(),
    ...housingLoanSchema(),
    ...housemateNumberSchema(),
    ...borrowingsAmountSchema(),
  });
  return schema.validate(data, options);
};

import React, {
  useLayoutEffect,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { isLoading } from '../../modules/app';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import McsBackendService from '../../services/mcs-backend-service';
import userSlice from '../../modules/user';
import { updateStylePattern, updateViewPattern } from '../../modules/pattern';
import xSlice from '../../modules/x-login/module';
import topSlice from '../../modules/t-top/module';
import { verificationCodeValidator } from '../../validators/x-login/login-validator';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import ApiCommon from '../../utils/api-common';
import SfApiException from '../../errors/sf-api-exception';
import { loadJson } from '../../utils/load-json';
import { LoginQA } from '../../basics/QA/x_loginQA';

// X005
const VerificationCodeError: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const nextUrl = new URLSearchParams(location.search).get('next');

  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const {
    loggedIn,
    deviceInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    updateLoggedIn,
  } = userSlice.actions;

  const {
    verificationCodeValidationError,
  } = useSelector((state: RootState) => state.xLogin);

  const {
    updateVerificationCodeValidationError,
    clearVerificationCodeValidationError,
  } = xSlice.actions;

  const {
    informations,
  } = useSelector((state: RootState) => state.top);

  const {
    updateTop,
    updateBankInfo,
    updateInformations,
  } = topSlice.actions;

  const [verifyCode, setVerifyCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [view, setView] = useState(false);
  const proc = useRef(false);

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  useEffect(() => {
    initiate();
    return () => {
      dispatch(isLoading(false));
    };
  }, []);

  const initiate = async () => {
    await loadInformations();
  };

  const loadInformations = async () => {
    const jsonData = await loadJson('/notice/information.json');
    dispatch(updateInformations(jsonData));
  };

  /**
   * APIで検証コードの確認を行う。
   */
  const deviceCheck = async () => {
    // 確認用API呼び出し
    const apiCommon: ApiCommon = new ApiCommon('X005', 'X-10', urls.X005, '');
    const commonPart = await apiCommon.getLoginCommonPart(verifyCode, deviceInfo.distributionId);
    const dataPart = {
      データ部: {
      },
    };
    await McsBackendService.request(Object.assign(commonPart, dataPart))
      .then((response) => {
        dispatch(updateLoggedIn(true));
        dispatch(updateViewPattern(response.共通部.ABテストパターン判定));
        if (deviceInfo.fromUrl === urls.X012) {
          // TOPの情報を取得してpostMessageする。
          userInfo(response.共通部.ABテストパターン判定);
        } else {
          history.push(urls.TOP);
        }
      })
      .catch((err) => {
        handleErrors(err);
      });
  };

  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err, {
        validationErrorState: verificationCodeValidationError,
        updateValidationError: updateVerificationCodeValidationError,
      });
      setErrorMessage(err.message);
    }
  };

  const userInfo = async (viewPattern: string) => {
    try {
      dispatch(isLoading(true));
      const apiCommon: ApiCommon = new ApiCommon('T001', 'T-10', urls.TOP, viewPattern);
      const commonPart = await apiCommon.getCommonPart();
      let dataPart;
      if (informations !== null) {
        dataPart = await apiCommon.getUserInfoNativeDataPart(informations.重要なお知らせ更新日時, informations.お知らせ更新日時);
      } else {
        dataPart = await apiCommon.getUserInfoNativeDataPart('', '');
      }
      const postJson = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateTop(postJson.データ部));
      dispatch(updateBankInfo(postJson.データ部.口座情報[0]));
      if (window.ReactNativeWebView != null) {
        window.ReactNativeWebView.postMessage(JSON.stringify(postJson));
      }
      return postJson;
    } catch (err) {
    // handleErrors(err);
      return '';
    } finally {
      dispatch(isLoading(false));
    }
  };
  const confirmValidation = () => {
    dispatch(clearVerificationCodeValidationError());
    setErrorMessage('');
    // バリデーション
    const validPramas: { 検証コード: string, [key: string]: string } = {
      検証コード: verifyCode,
    };
    try {
      throwIfJoiValidationError(verificationCodeValidator(validPramas, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      proc.current = false;
      setView(false);
      return false;
    }
    return true;
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: verificationCodeValidationError,
      updateValidationError: updateVerificationCodeValidationError,
    });
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>認証コードエラー</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH2 mtFLAT">
                    <h2>お客さま情報の確認・変更</h2>
                  </div>
                  <p className="mbFLAT flexC">
                    認証コードが一致しませんでした。<br />
                    30分後に再度お試しください。
                  </p>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default VerificationCodeError;

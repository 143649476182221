import AbstractError, { IAbstractError } from './abstract-error';

export interface ISfApiException extends IAbstractError {
  データ部?: any;
  エラー部?: any;
}

export default class SfApiException extends AbstractError implements ISfApiException {
  /** */
  データ部: any;

  エラー部?: any;

  /** */
  constructor(params?: ISfApiException) {
    super(params);
    this.データ部 = params?.データ部;
    this.エラー部 = params?.エラー部 ? params?.エラー部 : {};
    // console.log(this);
  }
}

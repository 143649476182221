/**
 * userAgentからiphone端末か判定
 * @returns boolean
 */
export function isIphone(): boolean {
  return !!window.navigator.userAgent.match(/iPhone/);
}

/**
 * userAgent から iPad 端末かを判定
 * @return boolean
 */
export function isIPad(): boolean {
  return !!window.navigator.userAgent.match(/iPad/) || ('ontouchend' in document && !!window.navigator.userAgent.match(/Macintosh/));
}

/**
 * userAgent から Android 端末かを判定
 * @return boolean
 */
export function isAndroid(): boolean {
  return !!window.navigator.userAgent.match(/Android/i);
}

/**
 * アプリ（userAgent に sf_card_push_app が含まれているもの）を判定
 */
export function isApp(): boolean {
  return !!window.navigator.userAgent.match(/sf_card_push_app/i);
}

export function isKonyApp(): boolean {
  // @ts-ignore
  if (typeof kony !== 'undefined') {
    return appVersionCheck();
  }
  return false;
}

function appVersionCheck(): boolean {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('sf_card_push_app') > 0) {
    const version = parseFloat(ua.slice(ua.indexOf('sf_card_push_app') + 18, ua.indexOf('sf_card_push_app') + 21));
    if (version >= 4) {
      return true;
    }
  }
  return false;
}

/**
 * userAgent からスマホ端末かを判定
 * @return boolean
 */
export function isSP(): boolean {
  return isIphone() || isAndroid();
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PType,
  InquiryHistoryType,
  InquiryHistoriesType,
  InquiryByPhoneType,
  ValidationErrorType,
  StateType,
  inquiryFormResultType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    メールアドレス: '',
  },
  inquiryHistory: {
    会員番号: '',
    問合せ履歴: [],
  },
  inquiryHistories: {
    問合せ受付番号: '',
    問合せ管理ステータス: '',
    問合せ日: '',
    問合せ時間: '',
    問合せ種別区分: '',
    問合せ件名: '',
    問合せ内容: '',
    メール返信日: '',
    メール返信時間: '',
    メール返信内容: '',
  },
  inquiryByPhone: {
    会員番号: '',
    代表電話番号: '',
  },
  inquiryFormResult: {
    問合せ種別区分: '',
    問合せ件名: '',
    問合せ内容: '',
    メールアドレス: '',
    問合せ受付番号: '',
  },
  validationError: {
    問合せ種別区分: '',
    問合せ件名: '',
    問合せ内容: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  inquiryType: '',
  inquiryTitle: '',
  inquiryText: '',
  completionDeliveryMailAddress: '',
  completionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  confirmMailAddress: '',
};

const slice = createSlice({
  name: 'p',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<PType>) => {
      state.top = action.payload;
    },
    updateInquiryHistory: (state: StateType, action: PayloadAction<InquiryHistoryType>) => {
      state.inquiryHistory = action.payload;
    },
    updateInquiryHistories: (state: StateType, action: PayloadAction<InquiryHistoriesType>) => {
      state.inquiryHistories = action.payload;
    },
    updateInquiryByPhone: (state: StateType, action: PayloadAction<InquiryByPhoneType>) => {
      state.inquiryByPhone = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    updateInquiryType: (state: StateType, action: PayloadAction<string>) => {
      state.inquiryType = action.payload;
    },
    updateInquiryTitle: (state: StateType, action: PayloadAction<string>) => {
      state.inquiryTitle = action.payload;
    },
    updateInquiryText: (state: StateType, action: PayloadAction<string>) => {
      state.inquiryText = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateinquiryFormResult: (state: StateType, action: PayloadAction<inquiryFormResultType>) => {
      state.inquiryFormResult = action.payload;
    },
    clearinquiryInputValue: (state: StateType) => {
      state.inquiryType = initialState.inquiryType;
      state.inquiryTitle = initialState.inquiryTitle;
      state.inquiryText = initialState.inquiryText;
      state.completionDeliveryMailAddress = initialState.completionDeliveryMailAddress;
      state.completionDeliveryMailAddressConfirm = initialState.completionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    updateConfirmMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.confirmMailAddress = action.payload;
    },
  },
});

export default slice;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { AppDispatch } from '../../store';

import { isLoading } from '../../modules/app';
import SfApiException from '../../errors/sf-api-exception';
import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import xSlice from '../../modules/x-login/module';
import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import {
  idValidator,
  resetIDValidator,
} from '../../validators/x-login/login-validator';
import CognitoBackendService from '../../services/cognito-backend-service';
import { LoginQA } from '../../basics/QA/x_loginQA';
import { Message } from '../../utils/messages';
import { removeWarnReloading, warnReloading } from '../../utils/warnReloading';

// X014
const ResetID: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');
  const { stylePattren } = useSelector((state: RootState) => state.pattern);

  const {
    loggedIn,
    memberInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    idValidationError,
    deviceRegistered,
    webIdRegistered,
    registeredEMailAddress,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    clearIdValidationError,
    updateIdValidationError,
    updateTempCognitoUserInfo,
    updateWebIdRegistered,
    clearRegisteredEMailAddress,
  } = xSlice.actions;
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [view, setView] = useState(false);

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  /**
   * ID の再設定
   * @returns
   */
  const changeCognitoUserId = async () => {
    if (!memberInfo.memberNo && !webIdRegistered) {
      return false;
    }

    try {
      await CognitoBackendService.request({
        body: {
          username: memberInfo.memberNo,
          preferredUsername: webIdRegistered,
        },
        endpoint: '/changePreferredUsername',
      });
      // ログイン ID とパスワードを一時的に保持
      dispatch(updateTempCognitoUserInfo({
        memberId: memberInfo.memberNo,
        userName: webIdRegistered,
      }));
      // 変更した情報でログインする
      history.push(urls.X017);
      return true;
    } catch (err: any) {
      if (err.code === 'UserNotFoundException' && err.message === 'User does not exist.') {
        setErrorMessage(Message.vM059);
      } else {
        handleErrors(err);
      }
      return false;
    }
  };

  /**
   * 会員番号（username）によるユーザーを検索
   * @param memberNo
   * @returns
   */
  const findCognitoUserByMemberNo = async (memberNo: string) => {
    let resultMemberNo = '';

    try {
      // username からユーザー情報の取得
      const result = await CognitoBackendService.request({
        body: {
          username: memberNo,
        },
        endpoint: '/getUser',
      });
      resultMemberNo = result.Username;
    } catch (err: any) {
      if (err.code === 'UserNotFoundException' && err.message === 'User does not exist.') {
        setErrorMessage(Message.vM059);
      } else {
        handleErrors(err);
      }
    }

    return resultMemberNo;
  };

  // SFDM3_02-1554 の対応で封印
  // /**
  //  * ユーザー情報の取得（ID = メールアドレス照合用）
  //  */
  // async function getCognitoLoginId(): Promise<string> {
  //   try {
  //     const res = await CognitoBackendService.request({
  //       body: {
  //         username: memberInfo.memberNo,
  //       },
  //       endpoint: '/getUser',
  //     });

  //     for (let i = 0; i < res.UserAttributes.length; i += 1) {
  //       const element = res.UserAttributes[i];
  //       if (element.Name === 'preferred_username') {
  //         return element.Value;
  //       }
  //     }
  //   } catch (err: any) {
  //     if (err.code === 'UserNotFoundException' && err.message === 'User does not exist.') {
  //       // ユーザーがいない場合はエラー落ちさせないように空文字を返却
  //       return '';
  //     }
  //   }

  //   return '';
  // }

  /**
   * 【パスワード再発行】IDを再設定するボタン処理
   */
  const handleClickSetId = async () => {
    dispatch(isLoading(true));
    setView(true);

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    // ID再設定する
    try {
      warnReloading();
      // SFDM3_02-1554 の対応で封印
      // // ユーザー情報を取得
      // const cognitoLoginId = await getCognitoLoginId();
      // // 登録済みの WebID が登録済みメールアドレスと一致
      // let inputMailAddress = '';
      // if (cognitoLoginId === registeredEMailAddress) {
      //   // メールアドレスの更新が必要かを判定
      //   if (webIdRegistered.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z]{2,}$/)
      //       && !webIdRegistered.match(/^.*(--|__|-@).*$/)) {
      //     // メールアドレスのため Cogniot と登録済みメールを更新
      //     inputMailAddress = webIdRegistered;
      //   }
      // }
      const existsUserMemberNo = await findCognitoUserByMemberNo(memberInfo.memberNo);
      if (existsUserMemberNo !== '') {
        // Cognito 対象ユーザーの ID を更新する
        const isCompleted = await changeCognitoUserId();
        if (isCompleted) {
          const apiCommon: ApiCommon = new ApiCommon('X014', 'X-02', urls.X014, '');
          const commonPart = await apiCommon.getLoginCommonPart('', '');
          const dataPart = {
            データ部: {
              会員番号: '',
              端末登録有無: deviceRegistered,
              // メールアドレス更新用: inputMailAddress,  // SFDM3_02-1554 の対応で封印
            },
          };
          await McsBackendService.request(Object.assign(commonPart, dataPart));
          dispatch(clearRegisteredEMailAddress());
        }
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  const confirmValidation = () => {
    dispatch(clearIdValidationError());

    // バリデーション
    const validParams: { ログインID: string } = {
      ログインID: webIdRegistered,
    };

    try {
      throwIfJoiValidationError(resetIDValidator(validParams, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }
    return true;
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updateIdValidationError({ ...idValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        case 'ログインID':
          throwIfJoiValidationError(idValidator({
            [name]: webIdRegistered,
          }));
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: idValidationError,
      updateValidationError: updateIdValidationError,
    });
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>ID再設定</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd mtFLAT">
                <div className="blockNarrow">
                  <div className={`mbL mtFLAT ${errorMessage ? 'error-input' : ''}`}>
                    <p className="error-text">
                      <span className="icon--attention">{errorMessage}</span>
                    </p>
                    <div className="form__inputBox">
                      <div className="form__inputBox__outer form__inputBox__outer--valid">
                        <input
                          type="text"
                          placeholder="lake@shinseifinancial.co.jp"
                          minLength={6}
                          maxLength={45}
                          name="ログインID"
                          onChange={(e) => dispatch(updateWebIdRegistered(e.target.value))}
                          onBlur={(e) => onBlurValidate(e.target.name)}
                          value={webIdRegistered}
                        />
                        <span className="form__inputBox__placeholder">ID</span>
                      </div>
                      <p className="error-comment">{idValidationError.ログインID}</p>
                    </div>
                    <ul className="listNote mbFLAT">
                      <li className="listNote__item">IDはメールアドレスまたは、6桁以上の半角英数字を入力してください。</li>
                    </ul>
                  </div>
                  <div className="mbFLAT flexC">
                    <button className="btn btn--cv" onClick={handleClickSetId}>IDを再設定する</button>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default ResetID;

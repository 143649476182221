/**
 * URL直打ちを許可するページ一覧
 */
export const allowedUrl = [
  '/',
  '/spagw1',
  '/spagw2',
  '/t001',
  '/a001',
  '/b001',
  '/b015',
  '/c001',
  '/d004',
  '/e001',
  '/f001',
  '/g001',
  '/h001',
  '/i001',
  '/j001',
  '/j008d',
  '/k001',
  '/k002',
  '/k030',
  '/l001',
  '/m001',
  '/o001',
  '/p001',
  '/p002',
  '/q001',
  '/s001',
  '/tt001',
  '/w001',
];

export function isAllowedUrl(url: string) {
  return allowedUrl.includes(url);
}

const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'];
const monthStr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

type Kana = {
  [key: string]: string;
}
const toHankakuKanaMap: Kana = {
  ガ: 'ｶﾞ',
  ギ: 'ｷﾞ',
  グ: 'ｸﾞ',
  ゲ: 'ｹﾞ',
  ゴ: 'ｺﾞ',
  ザ: 'ｻﾞ',
  ジ: 'ｼﾞ',
  ズ: 'ｽﾞ',
  ゼ: 'ｾﾞ',
  ゾ: 'ｿﾞ',
  ダ: 'ﾀﾞ',
  ヂ: 'ﾁﾞ',
  ヅ: 'ﾂﾞ',
  デ: 'ﾃﾞ',
  ド: 'ﾄﾞ',
  バ: 'ﾊﾞ',
  ビ: 'ﾋﾞ',
  ブ: 'ﾌﾞ',
  ベ: 'ﾍﾞ',
  ボ: 'ﾎﾞ',
  パ: 'ﾊﾟ',
  ピ: 'ﾋﾟ',
  プ: 'ﾌﾟ',
  ペ: 'ﾍﾟ',
  ポ: 'ﾎﾟ',
  ヴ: 'ｳﾞ',
  ヷ: 'ﾜﾞ',
  ヺ: 'ｦﾞ',
  ア: 'ｱ',
  イ: 'ｲ',
  ウ: 'ｳ',
  エ: 'ｴ',
  オ: 'ｵ',
  カ: 'ｶ',
  キ: 'ｷ',
  ク: 'ｸ',
  ケ: 'ｹ',
  コ: 'ｺ',
  サ: 'ｻ',
  シ: 'ｼ',
  ス: 'ｽ',
  セ: 'ｾ',
  ソ: 'ｿ',
  タ: 'ﾀ',
  チ: 'ﾁ',
  ツ: 'ﾂ',
  テ: 'ﾃ',
  ト: 'ﾄ',
  ナ: 'ﾅ',
  ニ: 'ﾆ',
  ヌ: 'ﾇ',
  ネ: 'ﾈ',
  ノ: 'ﾉ',
  ハ: 'ﾊ',
  ヒ: 'ﾋ',
  フ: 'ﾌ',
  ヘ: 'ﾍ',
  ホ: 'ﾎ',
  マ: 'ﾏ',
  ミ: 'ﾐ',
  ム: 'ﾑ',
  メ: 'ﾒ',
  モ: 'ﾓ',
  ヤ: 'ﾔ',
  ユ: 'ﾕ',
  ヨ: 'ﾖ',
  ラ: 'ﾗ',
  リ: 'ﾘ',
  ル: 'ﾙ',
  レ: 'ﾚ',
  ロ: 'ﾛ',
  ワ: 'ﾜ',
  ヲ: 'ｦ',
  ン: 'ﾝ',
  ァ: 'ｧ',
  ィ: 'ｨ',
  ゥ: 'ｩ',
  ェ: 'ｪ',
  ォ: 'ｫ',
  ッ: 'ｯ',
  ャ: 'ｬ',
  ュ: 'ｭ',
  ョ: 'ｮ',
  '。': '｡',
  '、': '､',
  ー: 'ｰ',
  '「': '｢',
  '」': '｣',
  '・': '･',
};

const toZenkakuKanaMap: Kana = {
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾋﾞ: 'ビ',
  ﾌﾞ: 'ブ',
  ﾍﾞ: 'ベ',
  ﾎﾞ: 'ボ',
  ﾊﾟ: 'パ',
  ﾋﾟ: 'ピ',
  ﾌﾟ: 'プ',
  ﾍﾟ: 'ペ',
  ﾎﾟ: 'ポ',
  ｳﾞ: 'ヴ',
  ﾜﾞ: 'ヷ',
  ｦﾞ: 'ヺ',
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',
  1: '１',
  2: '２',
  3: '３',
  4: '４',
  5: '５',
  6: '６',
  7: '７',
  8: '８',
  9: '９',
  0: '０',
};

/**
 * Date型の日時をyyyymmddttmiss文字列に変換する。
 * @param dateVal Date型の日時
 * @returns yyyymmddttmiss形式の文字列
 */
export function getNewDateAndTimeAsString() {
  const dateVal = new Date();
  const yyyy = dateVal.getFullYear();
  const mm = dateVal.getMonth() + 1;
  const dd = dateVal.getDate();
  const hh = dateVal.getHours();
  const mi = dateVal.getMinutes();
  const ss = dateVal.getSeconds();

  function to2digit(s: Number) {
    return `0${s}`.slice(-2);
  }
  return `${yyyy}${(to2digit(mm))}${(to2digit(dd))}${(to2digit(hh))}${(to2digit(mi))}${(to2digit(ss))}`;
}

/**
 * 文字列型の日時を○○○○年○○月形式に変換する。
 * @param dateVal 文字列型の日時
 * @returns ○○○○年○○月形式の文字列
 */
export function yearMonthToString(dateVal: string) {
  if (dateVal != null && dateVal.length !== 8) {
    return '';
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const date = new Date(`${year}/${month}/`);

  return `${date.getFullYear()}年${monthStr[date.getMonth()]}月`;
}

/**
 * 文字列型の日時を○○○○年○○月形式に変換する。
 * @param dateVal 文字列型の日時
 * @returns ○○○○年○○月形式の文字列
 */
export function monthDayToString(dateVal: string) {
  if (dateVal != null && dateVal.length !== 8) {
    return '';
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const day = dateVal.substr(6, 2);
  const date = new Date(`${year}/${month}/${day}`);

  return `${monthStr[date.getMonth()]}月${date.getDate()}日`;
}

/**
 * 文字列型の日時を○○○○年○○月○○日形式に変換する。
 * @param dateVal 文字列型の日時
 * @returns ○○○○年○○月○○日形式の文字列
 */
export function dateToString(dateVal: string) {
  if (!dateVal || dateVal.length !== 8) {
    return '';
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const day = dateVal.substr(6, 2);
  const date = new Date(`${year}/${month}/${day}`);

  return `${date.getFullYear()}年${monthStr[date.getMonth()]}月${date.getDate()}日`;
}
/**
 * 文字列型の日時を、月と日を2桁にした○○○○年○○月○○日形式に変換する。
 * @param dateVal 文字列型の日時
 * @returns 月と日を2桁にした、○○○○年○○月○○日形式の文字列
 */
export function dateToString2digits(dateVal: string) {
  if (!dateVal || dateVal.length !== 8) {
    return '';
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const day = dateVal.substr(6, 2);
  const date = new Date(`${year}/${month}/${day}`);

  return `${date.getFullYear()}年${(`0${monthStr[date.getMonth()]}`).slice(-2)}月${(`0${date.getDate()}`).slice(-2)}日`;
}

/**
 * 文字列型の日時から月を数値で返す。
 * @param dateVal 文字列型の日時
 * @returns number
 */
export function dateToNumberOnlyMonth(dateVal: string) {
  if (dateVal != null && dateVal.length !== 8) {
    return 1;
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const day = dateVal.substr(6, 2);
  const date = new Date(`${year}/${month}/${day}`);

  return Number(monthStr[date.getMonth()]);
}

/**
 * 文字列型の日時を○○○○/○○/○○日形式に変換する。
 * @param dateVal 文字列型の日時
 * @returns ○○○○/○○/○○/形式の文字列
 */
export function dateToSlash(dateVal: string) {
  if (dateVal != null && dateVal.length !== 8) {
    return '';
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const day = dateVal.substr(6, 2);
  const date = new Date(`${year}/${month}/${day}`);

  return `${date.getFullYear()}/${monthStr[date.getMonth()]}/${date.getDate()}`;
}

/**
 * 文字列型の日時を○○○○年○○月○○日（○）形式に変換する。
 * @param dateVal 文字列型の日時
 * @returns ○○○○年○○月○○日（○）形式の文字列
 */
export function dateToStringWithDayOfWeek(dateVal: string) {
  if (dateVal != null && dateVal.length !== 8) {
    return '';
  }
  const year = dateVal.substr(0, 4);
  const month = dateVal.substr(4, 2);
  const day = dateVal.substr(6, 2);
  const date = new Date(`${year}/${month}/${day}`);

  return `${date.getFullYear()}年${monthStr[date.getMonth()]}月${date.getDate()}日(${dayOfWeekStr[date.getDay()]})`;
}

/**
 * 文字列型の時間を○○時○○分形式に変換する。
 * @param dateVal 文字列型の時間
 * @returns ○○時○○分形式の文字列
 */
export function timeToString(dateVal: string) {
  if (!dateVal || (dateVal.length !== 4 && dateVal.length !== 6)) {
    return '';
  }
  const hour = dateVal.substr(0, 2);
  const minutes = dateVal.substr(2, 2);

  return `${Number(hour)}時${minutes}分`;
}

/**
 * 文字列型の時間を○○時形式に変換する。
 * @param dateVal 文字列型の時間
 * @returns ○○時形式の文字列
 */
export function timeToStringOnlyHour(dateVal: string) {
  if (dateVal != null && dateVal.length !== 4) {
    return '';
  }
  const hour = dateVal.substr(0, 2);

  return `${Number(hour)}時`;
}

/**
 * 文字列型の時間を○○:○○形式に変換する。
 * @param dateVal 文字列型の時間
 * @returns ○○:○○形式の文字列
 */
export function timeToStringWithColon(dateVal: string) {
  if (dateVal != null && dateVal.length !== 4) {
    return '';
  }
  const hour = dateVal.substr(0, 2);
  const minutes = dateVal.substr(2, 2);

  return `${Number(hour)}:${minutes}`;
}

/**
 * 文字列型(○○○○/○○/○○ ○○:○○)の時間を○○○○年○月○○日 ○○:○○形式に変換する。
 * @param dateVal 文字列型の時間
 * @returns ○○○○年○月○○日 ○○:○○形式の文字列
 */
export function dateToStringWithShort(dateVal: string) {
  if (dateVal != null && dateVal.length !== 16) {
    return '';
  }
  const str = dateVal.replaceAll('/', '');
  const repStr = str.replaceAll(' ', '');
  const year = repStr.substr(0, 4);
  const month = repStr.substr(4, 2);
  const day = repStr.substr(6, 2);
  const hour = repStr.substr(8, 2);
  const minutes = repStr.substr(11, 2);

  return `${year}年${month}月${day}日 ${hour}:${minutes}`;
}

/**
 * 文字列型(○○○○/○○/○○ ○○:○○:○○)の時間を○○○○○○形式に変換する。
 * @param dateVal 文字列型の時間
 * @returns ○○○○○○形式の文字列
 */
export function dateToStringWithBerryShort(dateVal: string) {
  if (dateVal != null && dateVal.length !== 19) {
    return '';
  }
  const str = dateVal.replaceAll('/', '');
  const repStr = str.replaceAll(' ', '');
  const year = repStr.substr(0, 4);
  const month = repStr.substr(4, 2);

  return `${year}${month}`;
}

/**
 * 文字列型の時間をYYYY年MM月DD日 HH:mm形式に変換する。
 * @param dateVal YYYYMMDDHHMM型の時間
 * @returns YYYY年MM月DD日 HH:mm形式の文字列
 */
export function dateTimeToString(dateVal: string | null = null) {
  if (dateVal === null || (dateVal !== null && dateVal.length !== 12)) return '';
  let year;
  let month;
  let day;
  let hour;
  let minutes;
  year = dateVal.substr(0, 4);
  month = Number(dateVal.substr(4, 2)).toString();
  day = Number(dateVal.substr(6, 2)).toString();
  hour = Number(dateVal.substr(8, 2)).toString();
  minutes = dateVal.substr(10, 2);

  return `${year}年${month}月${day}日 ${hour}:${minutes}`;
}

/**
 * 文字列型の時間をYYYY年MM月DD日 HH:mm形式に変換する。
 * @param dateVal YYYY/MM/DD HH:MM:SS型の時間
 * @returns YYYY年MM月DD日 HH:mm形式の文字列
 */
export function processingDateTimeToString(processingDateTime: string) {
  if (processingDateTime === '' || processingDateTime.length !== 19) return '';

  const result = processingDateTime.split(' ');
  const dateList = result[0].split('/');
  const timeList = result[1].split(':');

  return `${dateList[0]}年${dateList[1]}月${dateList[2]}日 ${timeList[0]}:${timeList[1]}`;
}

/**
 * 文字列型の時間をYYYY年MM月DD日 HH時mm分形式に変換する。
 * @param dateVal YYYY/MM/DD HH:MM:SS型の時間
 * @returns YYYY年MM月DD日 HH時mm分形式の文字列
 */
export function processingDateTimeToStringV2(processingDateTime: string) {
  if (processingDateTime === '' || processingDateTime.length !== 19) return '';

  const result = processingDateTime.split(' ');
  const dateList = result[0].split('/');
  const timeList = result[1].split(':');

  return `${dateList[0]}年${dateList[1]}月${dateList[2]}日 ${timeList[0]}時${timeList[1]}分`;
}

/**
 * 文字列型の時間をHH時mm分形式に変換する。
 * @param dateVal YYYY/MM/DD HH:MM:SS型の時間
 * @returns HH時mm分形式の文字列
 */
export function processingDateTimeToStringOnlyTime(processingDateTime: string) {
  if (processingDateTime === '' || processingDateTime.length !== 19) return '';

  const result = processingDateTime.split(' ');
  const timeList = result[1].split(':');

  return `${timeList[0]}時${timeList[1]}分`;
}

export function processingDateTimeToDate(processingDateTime: string) {
  if (processingDateTime === '' || processingDateTime.length !== 19) return '';

  const result = processingDateTime.split(' ');
  const dateList = result[0].split('/');
  return new Date(`${dateList[0]}/${dateList[1]}/${dateList[2]}`);
}

export function processingDateToString(processingDateTime: string) {
  if (processingDateTime === '' || processingDateTime.length !== 19) return '';

  const result = processingDateTime.split(' ');
  const dateList = result[0].split('/');
  return `${dateList[0]}年${dateList[1]}月${dateList[2]}日`;
}

/**
 * 3桁カンマ区切りの数字文字列に変換する。
 * @param number 数字または数字文字列
 * @returns 3桁カンマ区切りの数字文字列
 */
export function numberWithComma(number: string | number) {
  return (typeof number === 'string' ? Number(number) : number).toLocaleString();
}

/**
 * %文字列に変換する。
 * @param number 5桁の数字または数字文字列
 * @returns %文字列
 */
export function convertToPercent(value: string | number, fixedNum: number = 3) {
  const num = typeof value === 'string' ? Number(value) : value;
  if (fixedNum === 1) {
    return (Math.floor((num / 100)) / 10).toFixed(fixedNum);
  }
  if (fixedNum === 2) {
    return (Math.floor((num / 10)) / 100).toFixed(fixedNum);
  }
  return (num / 1000).toFixed(fixedNum);
}

/**
 * ハイフン付き電話番号に変換する。
 * @param phoneNumber 電話番号
 * @returns ハイフン付き電話番号
 */
export function phoneNumberWithHyphen(phoneNumber: string | number) {
  const phone = String(phoneNumber);

  const fixedLinePhoneFormat = 'xxx-xxx-xxxx'; // 固定電話形式
  const cellPhoneFormat = 'xxx-xxxx-xxxx'; // 携帯電話形式

  if (phone.length === 10) {
    return convertToFormat(phone, fixedLinePhoneFormat);
  }
  if (phone.length === 11) {
    return convertToFormat(phone, cellPhoneFormat);
  }
  return phone;
}
/**
 * ハイフンなし電話番号に変換する。
 * @param phoneNumber ハイフン付き電話番号
 * @returns ハイフンなし電話番号
 */
export function phoneNumberWithoutHyphen(phoneNumber: string | number) {
  const numberList = String(phoneNumber).split('-');
  const phone = numberList[0] + numberList[1] + numberList[2];
  return phone;
}
/**
 * ハイフン付き郵便番号に変換する。
 * @param phoneNumber 郵便番号
 * @returns ハイフン付き郵便番号
 */
export function postNumberWithHyphen(postNumber: string | number) {
  const post = String(postNumber);

  const postFormat = 'xxx-xxxx';

  if (post && post.length === 7) {
    return convertToFormat(post, postFormat);
  }
  return post;
}

/**
 * 指定のフォーマットに変換する。
 * フォーマットのxに文字列を差し込む。ex)xxx-xxx-xxxx → 123-456-7890
 * @param str 変換前の文字列 ex)1234567890
 * @param format フォーマット ex)xxx-xxx-xxxx
 * @returns フォーマットに変換した文字列
 */
export function convertToFormat(str: string, format: string) {
  // xの数と変換前の文字列数を確認、一致していなければ変換しない。
  const length = format.match(/x/g)?.length;
  if (str.length !== length) return str;

  let ret = '';
  let count = 0;
  for (let i = 0; i < format.length; i += 1) {
    if (format[i] === 'x') {
      ret += str[count];
      count += 1;
    } else {
      ret += format[i];
    }
  }
  return ret;
}

/**
 * 先頭の0を除去する
 * @param value
 * @returns string
 */
export function suppressZero(value: string) {
  return value.replace(/^0+/, '');
}

/**
 * 複数行の場合のみ先頭の0を除去する
 * @param value
 * @returns string
 */
export function suppressZeroForMultiDigits(value: string) {
  if (value.length > 1) {
    return value.replace(/^0+/, '');
  }
  return value;
}

export function convertViewIdToLink(viewId: string) {
  return `/${viewId.toLowerCase()}`;
}

export function convertZenkakuKanaToHankakuKana(str: string) {
  const reg = new RegExp(`(${Object.keys(toHankakuKanaMap).join('|')})`, 'g');
  return str
    .replace(reg, (match) => toHankakuKanaMap[match])
    .replace(/゛/g, 'ﾞ')
    .replace(/゜/g, 'ﾟ')
    .replace('　', ' ');
}

export function convertHiraKanaToKataKana(str: string) {
  return str.replace(/[\u3041-\u3096]/g, (ch) => String.fromCharCode(ch.charCodeAt(0) + 0x60));
}

export function convertHankakuKanaToZenkakuKana(str: string) {
  const reg = new RegExp(`(${Object.keys(toZenkakuKanaMap).join('|')})`, 'g');
  return str
    .replace(/[!-~]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0xFEE0))
    .replace(reg, (match) => toZenkakuKanaMap[match])
    .replace(' ', '　');
}

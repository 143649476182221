/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type specialmove = {
  isBack: boolean
  backUrl: string
}

type StateType = {
  viaK001: specialmove
};

// 初期値
const initialState: StateType = {
  viaK001: {
    isBack: false,
    backUrl: '/',
  },
};

const slice = createSlice({
  name: 'specialmove',
  initialState,
  reducers: {
    updateViaK001: (state: StateType, action: PayloadAction<specialmove>) => {
      state.viaK001 = action.payload;
    },
    clearAllSpecialmove: (state: StateType) => {
      state.viaK001 = initialState.viaK001;
    },
  },
});

export const {
  updateViaK001,
  clearAllSpecialmove,
} = slice.actions;

export default slice;

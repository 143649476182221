/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { isLoading } from '../../modules/app';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { handleCommonError } from '../../utils/handle-errors';
import { updateStylePattern, updateViewPattern } from '../../modules/pattern';

import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import userSlice from '../../modules/user';
import SfApiException from '../../errors/sf-api-exception';

// X017
const SettingIDAndPasswordComplete: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const nextUrl = new URLSearchParams(location.search).get('next');

  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const {
    loggedIn,
    deviceInfo,
  } = useSelector((state: RootState) => state.user);
  const {
    tempCognitoUserInfo,
  } = useSelector((state: RootState) => state.xLogin);

  const [errorMessage, setErrorMessage] = useState('');

  const {
    updateLoggedIn,
    updateDeviceInfo,
    updateLoginInfo,
  } = userSlice.actions;

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  const handleClickHome = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!tempCognitoUserInfo.userName) {
      history.push(urls.LOGIN);
      return;
    }
    if (!tempCognitoUserInfo.password) {
      history.push(urls.LOGIN);
      return;
    }

    await loginUser(tempCognitoUserInfo.userName, tempCognitoUserInfo.password);
  };

  /**
   * ログインユーザーの情報と端末情報を確認する。
   */
  const loginUser = async (loginId: string, loginPassword: string) => {
    try {
      const apiCommon: ApiCommon = new ApiCommon('X001', 'X-02', urls.X011, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '');
      const dataPart = await apiCommon.getLoginInfoDataPart(loginId, loginPassword);
      const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: json.共通部.配信ID, fromUrl: urls.X002 }));
      dispatch(updateViewPattern(json.共通部.ABテストパターン判定));
      dispatch(updateLoginInfo(json.データ部));

      // ログインステータスを更新しレンダリング
      dispatch(updateLoggedIn(true));
      history.push(urls.TOP);
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * エラー用ハンドラー
   * @param err Error
   */
  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>ID/パスワード設定完了</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd mtFLAT">
                <div className="blockNarrow">
                  <p className="mtFLAT mbXL">
                    ご登録の連絡先に、設定通知を送付しました。<br />
                    ログインをお願いします。
                  </p>
                  <div className="mbFLAT flexC">
                    <a href="#####" className="btn" onClick={handleClickHome}>会員ログインへ</a>
                  </div>
                </div>
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default SettingIDAndPasswordComplete;

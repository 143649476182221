/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserInfoType,
  ValidationErrorType,
  StateType,
  MailChangeValidationErrorType,
} from './types';

// 初期値
const initialState: StateType = {
  isSawTutorial: false,
  agreement: false,
  selectedEmail: '',
  mailChangeValidationError: {
    メールアドレス: '',
    メールアドレス確認: '',
  },
  userInfo: {
    会員番号: '',
    ご希望契約額: '',
    メールアドレス: '',
    自宅漢字住所: '',
    家賃住宅ローン: '',
    本人を除く住居人数: '',
    漢字勤務先名: '',
    税込年収: '',
    他社借り入れ総額: '',
    合計振込金額: '',
    合計振込金融機関数: '',
    他社借入内容: [
      {
        振込先通番: '',
        銀行名: '',
        支店名: '',
        預金種目: '',
        預金口座番号: '',
        預金口座名義人: '',
        振込金額: '',
      },
    ],
    商品名: '',
    お振込み額: '',
    約定利率: '',
    損害金利率: '',
    約定日: '',
    返済方式: '',
    返済金額: '',
    プロセスID: '',
    タスクID: '',
    遷移先画面ID: '',
    チェックエラーコード: '',
    チェックメッセージ: '',
  },
  selectedBankInfo: {
    口座枝番: '',
    商品名: '',
    無利息期間: '',
    利用可能額: '',
    残高: '',
    次回支払年月日: '',
    自振現状区分: '',
    自振状況区分: '',
    自振登録状況: '',
    毎回額: '',
    返済金額: '',
    メッセージ帯: '',
    メッセージＡ１タイトル: '',
    メッセージＡ１: '',
    メッセージＡ１遷移先機能: '',
    メッセージＡ１ボタン名: '',
    メッセージＡ２タイトル: '',
    メッセージＡ２: '',
    メッセージＡ２遷移先機能: '',
    メッセージＡ２ボタン名: '',
    メッセージＢ: '',
    メッセージＢ遷移先機能: '',
    メッセージＢボタン名: '',
    カード発行区分: '',
    解約区分: '',
  },
  lastLoginDateTime: '',
  support: {
    会員番号: '',
    メッセージＡ１タイトル: '',
    メッセージＡ１: '',
    メッセージＡ１遷移先機能: '',
    メッセージＡ１ボタン名: '',
    メッセージＡ２タイトル: '',
    メッセージＡ２: '',
    メッセージＡ２遷移先機能: '',
    メッセージＡ２ボタン名: '',
    メッセージＡ３タイトル: '',
    メッセージＡ３: '',
    メッセージＡ３遷移先機能: '',
    メッセージＡ３ボタン名: '',
    メッセージＡ４タイトル: '',
    メッセージＡ４: '',
    メッセージＡ４遷移先機能: '',
    メッセージＡ４ボタン名: '',
    メッセージＡ５タイトル: '',
    メッセージＡ５: '',
    メッセージＡ５遷移先機能: '',
    メッセージＡ５ボタン名: '',
    メッセージＡ６タイトル: '',
    メッセージＡ６: '',
    メッセージＡ６遷移先機能: '',
    メッセージＡ６ボタン名: '',
    メッセージＡ７タイトル: '',
    メッセージＡ７: '',
    メッセージＡ７遷移先機能: '',
    メッセージＡ７ボタン名: '',
    メッセージＡ８タイトル: '',
    メッセージＡ８: '',
    メッセージＡ８遷移先機能: '',
    メッセージＡ８ボタン名: '',
  },
  validationError: {
    振込金額: '',
  },
  informations: {
    重要なお知らせ更新日時: '',
    重要なお知らせ内容: [],
    お知らせ更新日時: '',
    お知らせ内容: [],
  },
  bannerInfo: {
    キャンペーン情報: [],
  },
  isEmail: '0',
  paramsIsAllAgree: '0',
  confirmApplicationScreenName: '1',
  fromSpagw2: false,
  loginDate4ModalCheck: 'yyyy/mm/dd 00:00:00',
  editEmailAddress: '',
};

const slice = createSlice({
  name: 'collect',
  initialState,
  reducers: {
    updateUserInfo: (state: StateType, action: PayloadAction<UserInfoType>) => {
      state.userInfo = action.payload;
    },
    updateMailChangeValidationError: (state: StateType, action: PayloadAction<MailChangeValidationErrorType>) => {
      state.mailChangeValidationError = action.payload;
    },
    clearMailChangeValidationError: (state: StateType) => {
      state.mailChangeValidationError = initialState.mailChangeValidationError;
    },
    updateConfirmApplicationScreenName: (state: StateType, action: PayloadAction<string>) => {
      state.confirmApplicationScreenName = action.payload;
    },
    updateSelectedEmail: (state: StateType, action: PayloadAction<string>) => {
      state.selectedEmail = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    updateIsEmail: (state: StateType, action: PayloadAction<string>) => {
      state.isEmail = action.payload;
    },
    updateParamsIsAllAgree: (state: StateType, action: PayloadAction<string>) => {
      state.paramsIsAllAgree = action.payload;
    },
    updateEditEmailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.editEmailAddress = action.payload;
    },
  },
});

export default slice;

import AwsAuthService from '../services/aws-auth-service';
import ApiCommon from './api-common';
import McsBackendService from '../services/mcs-backend-service';
import urls from './urls';
import { loadJson } from './load-json';
import { TopType, BankInfoType as TopBankInfoType } from '../modules/t-top/types';

type CurrentTopInfo = {
  topInfo: TopType,
  selectedBankInfo: TopBankInfoType,
  informations: any,
};

type RepaymentInfo = {
  bankInfo: any;
  payoffInfo: any;
}

type CurrentMemberInfo = {
  memberInfo: any;
}

export class CommonFunctions {
  /**
   * お知らせ情報を取得する
   * @param path information.json のパス
   * @returns お知らせ情報
   */
  static async loadInformations(path: string) {
    const jsonData = await loadJson(path);
    return jsonData;
  }

  /**
   * MCS API を呼び出し TOP の情報とデフォルトの口座情報を取得する
   * @param viewPattern 画面表示パターン
   * @returns 本来 TOP 遷移時に取得される TOP の情報とデフォルトの口座情報
   */
  static async getCurrentTopInfo(viewPattern: string): Promise<CurrentTopInfo> {
    const informations = await CommonFunctions.loadInformations('/notice/information.json');
    const apiCommon: ApiCommon = new ApiCommon('T001', 'T-01', urls.TOP, viewPattern);
    const commonPart = await apiCommon.getCommonPart();
    let dataPart;
    if (informations !== null) {
      dataPart = await apiCommon.getUserInfoNativeDataPart(informations.重要なお知らせ更新日時, informations.お知らせ更新日時);
    } else {
      dataPart = await apiCommon.getUserInfoNativeDataPart('', '');
    }
    const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
    let selected: TopBankInfoType = json.データ部.口座情報.find(
      (bank: TopBankInfoType) => bank.解約区分 === '0',
    );
    if (!selected) {
      selected = json.データ部.口座情報[0] as TopBankInfoType;
    }
    // API から取得した TOP の情報とデフォルトの口座情報を返却する
    return {
      topInfo: json,
      selectedBankInfo: selected,
      informations,
    };
  }

  /**
   * MCS API を呼び出し返済関連の情報を取得する
   * @param viewPattern 画面表示パターン
   * @param memberNo 取得した顧客の会員番号
   * @param bankBranchNumber デフォルトの口座情報
   * @returns 取得した返済関連の情報
   */
  static async getRepaymentInfo(viewPattern: string, memberNo: string, bankBranchNumber: string): Promise<RepaymentInfo> {
    // B001_B-01 の呼び出し
    const repaymentInfoCommonPart = await ApiCommon.getCommonPart('B001', 'B-01', urls.B001, viewPattern);
    const repaymentInfoDataPart = {
      データ部: {
        会員番号: memberNo,
        口座枝番: bankBranchNumber,
        商品名: '',
        請求日: '',
        請求金額: '',
        返済期日: '',
        返済必要額: '',
        返済予定日: '',
        返済方法: '',
        元金充当額: '',
        利息: '',
        手数料累計額: '',
        契約年月日: '',
        約定利率: '',
        最終貸付日: '',
        最終貸付直後残高: '',
        債務残高: '',
        元金残高: '',
        保留金: '',
        不足金: '',
        遅延損害金: '',
        遅れ日数: '',
        遷移先画面ID: '',
        管理店連絡先: '',
      },
    };
    const repaymentInfoResJson = await McsBackendService.request(Object.assign(repaymentInfoCommonPart, repaymentInfoDataPart));

    // B001_B-04 の呼び出し
    const payoffCommonPart = await ApiCommon.getCommonPart('B001', 'B-04', urls.B001, viewPattern);
    const payoffDataPart = {
      データ部: {
        会員番号: memberNo,
        口座枝番: bankBranchNumber,
        次回支払日: repaymentInfoResJson.データ部.返済予定日,
      },
    };
    const payoffResJson = await McsBackendService.request(Object.assign(payoffCommonPart, payoffDataPart));

    // データを整理・返却
    return {
      bankInfo: repaymentInfoResJson,
      payoffInfo: payoffResJson,
    };
  }

  /**
   * MCS API を呼び出し顧客情報を取得する
   * @param viewPattern 画面表示パターン
   * @param memberNo 取得した顧客の会員番号
   * @param bankBranchNumber デフォルトの口座情報
   * @returns 取得した顧客情報
   */
  static async getMemberInfo(viewPattern: string, memberNo: string, bankBranchNumber: String): Promise<CurrentMemberInfo> {
    const commonPart = await ApiCommon.getCommonPart('K001', 'K-01', urls.K001, viewPattern);
    const dataPart = {
      データ部: {
        会員番号: memberNo,
        口座枝番: bankBranchNumber,
        カード番号: '',
        自宅郵便番号: '',
        自宅住所漢字: '',
        自宅住所漢字1: '',
        自宅住所漢字2: '',
        自宅住所カナ1: '',
        自宅住所カナ2: '',
        自宅電話番号: '',
        居住形態区分: '',
        居住開始年月年: '',
        居住開始年月月: '',
        家賃住宅ローン: '',
        本人を除く住居人数: '',
        電話番号有無: '',
        携帯電話番号: '',
        勤務先名漢字: '',
        勤務先名カナ: '',
        勤務先郵便番号: '',
        勤務先住所漢字: '',
        勤務先住所漢字1: '',
        勤務先住所漢字2: '',
        勤務先住所カナ1: '',
        勤務先住所カナ2: '',
        勤務先電話番号: '',
        内線番号: '',
        勤務形態区分: '',
        所属部署: '',
        勤務先業種コード: '',
        勤務先職種コード: '',
        入社年月年: '',
        入社年月月: '',
        勤務先従業員数: '',
        給料日: '',
        ボーナス月１: '',
        ボーナス月２: '',
        月収金額: '',
        税込年収: '',
        保険証の種類: '',
        顧客銀行名: '',
        顧客支店名: '',
        顧客預金種目: '',
        顧客預金口座番号: '',
        顧客口座名義人: '',
        振込人名義: '',
        架電承認確認日: '',
        自宅架電選択: '',
        携帯架電選択: '',
        勤務先架電選択: '',
        ＳＭＳ同意: '',
        メールアドレス: '',
        返済日事前お知らせメール: '',
        お得情報案内メール: '',
        生年月日: '',
        自宅属性情報確認年月日: '',
        勤務先属性情報確認年月日: '',
        携帯属性情報確認年月日: '',
      },
    };
    const memberInfoJson = await McsBackendService.request(Object.assign(commonPart, dataPart));

    // データを整理・返却
    return {
      memberInfo: memberInfoJson,
    };
  }
}

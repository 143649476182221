/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LType,
  ValidationErrorType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    勤務先名漢字: '',
    メールアドレス: '',
    証明書受領状況区分: '',
    証明書提出状況: '',
    証明書提出理由: '',
    証明書種類区分: '',
    証明書取得年月日: '',
    証明書有効期限年月日: '',
    証明書有効期限フラグ: '',
  },
  validationError: {
    メールアドレス: '',
    メールアドレス確認: '',
  },
  completionDeliveryMailAddressConfirm: '',
  completionDeliveryMailAddress: '',
  isChangeMailAddress: false,
  messagesError0: '',
  messagesError1: '',
  messagesError2: '',
  messagesError3: '',
  messagesError4: '',
  isFromH: false,
};

const slice = createSlice({
  name: 'l',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<LType>) => {
      state.top = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateErrorMessage0: (state: StateType, action: PayloadAction<string>) => {
      state.messagesError0 = action.payload;
    },
    updateErrorMessage1: (state: StateType, action: PayloadAction<string>) => {
      state.messagesError1 = action.payload;
    },
    updateErrorMessage2: (state: StateType, action: PayloadAction<string>) => {
      state.messagesError2 = action.payload;
    },
    updateErrorMessage3: (state: StateType, action: PayloadAction<string>) => {
      state.messagesError3 = action.payload;
    },
    updateErrorMessage4: (state: StateType, action: PayloadAction<string>) => {
      state.messagesError4 = action.payload;
    },
    updateIsFromH: (state: StateType, action: PayloadAction<boolean>) => {
      state.isFromH = action.payload;
    },
  },
});

export default slice;

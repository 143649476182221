/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addressPhoneNumberValidationErrorStateType,
  KType,
  mailAddressValidationErrorStateType,
  StateType,
  workPlaceAddressPhoneNumberValidationErrorStateType,
  webIdChangeValidationErrorStateType,
  searchedAddressesType,
  PhoneNumberValidationErrorType,
  WorkPlaceParamsType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    カード番号: '',
    自宅郵便番号: '',
    自宅住所漢字: '',
    自宅住所漢字1: '',
    自宅住所漢字2: '',
    自宅住所カナ1: '',
    自宅住所カナ2: '',
    自宅電話番号: '',
    居住形態区分: '',
    居住開始年月年: '',
    居住開始年月月: '',
    家賃住宅ローン: '',
    本人を除く住居人数: '',
    電話番号有無: '',
    携帯電話番号: '',
    勤務先名漢字: '',
    勤務先名カナ: '',
    勤務先郵便番号: '',
    勤務先住所漢字: '',
    勤務先住所漢字1: '',
    勤務先住所漢字2: '',
    勤務先住所カナ1: '',
    勤務先住所カナ2: '',
    勤務先電話番号: '',
    内線番号: '',
    勤務形態区分: '',
    所属部署: '',
    勤務先業種コード: '',
    勤務先職種コード: '',
    入社年月年: '',
    入社年月月: '',
    勤務先従業員数: '',
    給料日: '',
    ボーナス月１: '',
    ボーナス月２: '',
    月収金額: '',
    税込年収: '',
    保険証の種類: '',
    顧客銀行名: '',
    顧客支店名: '',
    顧客預金種目: '',
    顧客預金口座番号: '',
    顧客口座名義人: '',
    振込人名義: '',
    架電承認確認日: '',
    自宅架電選択: '',
    携帯架電選択: '',
    勤務先架電選択: '',
    ＳＭＳ同意: '',
    メールアドレス: '',
    返済日事前お知らせメール: '',
    お得情報案内メール: '',
    生年月日: '',
    自宅属性情報確認年月日: '',
    勤務先属性情報確認年月日: '',
    携帯属性情報確認年月日: '',
  },
  updatetAddressPhoneNumber: {
    自宅郵便番号: '',
    自宅住所漢字1: '',
    自宅住所カナ1: '',
    自宅住所漢字2: '',
    自宅住所カナ2: '',
    居住形態区分: '',
    居住開始年月年: '',
    居住開始年月月: '',
    家賃住宅ローン: '',
    本人を除く住居人数: '',
    電話番号有無: '0',
    電話番号: '',
    HP自宅連絡希望区分: '1',
    HP携帯電話連絡希望区分: '1',
    HP勤務先連絡希望区分: '1',
    HPSMS連絡希望区分: '1',
    処理日時: '',
    isChangeMailAddress: false,
  },
  addressPhoneNumberValidationError: {
    自宅郵便番号: '',
    自宅住所漢字1: '',
    自宅住所漢字2: '',
    自宅住所カナ1: '',
    自宅住所カナ2: '',
    居住形態区分: '',
    居住開始年月年: '',
    居住開始年月月: '',
    居住開始年月: '',
    家賃住宅ローン: '',
    本人を除く住居人数: '',
    自宅電話番号: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  updateWorkPlaceAddressPhoneNumber: {
    変更理由区分: '',
    勤務先郵便番号: '',
    勤務先漢字住所1: '',
    勤務先カナ住所1: '',
    勤務先漢字住所2: '',
    勤務先カナ住所2: '',
    漢字勤務先名: '',
    カナ勤務先名: '',
    勤務先電話番号: '',
    勤務先内線番号: '',
    勤務形態区分: '',
    勤務先所属部署: '',
    勤務先業種コード: '',
    勤務先職種コード: '',
    入社年月年: '',
    入社年月月: '',
    勤務先従業員数: '',
    給料日: '',
    ボーナス月１: '',
    ボーナス月２: '',
    月収金額: '',
    年収金額: '',
    保険種類: '',
    HP自宅連絡希望区分: '0',
    HP携帯電話連絡希望区分: '0',
    HP勤務先連絡希望区分: '0',
    HPSMS連絡希望区分: '0',
    isChangeMailAddress: false,
  },
  workPlaceAddressPhoneNumberValidationError: {
    変更理由区分: '',
    勤務先郵便番号: '',
    勤務先漢字住所1: '',
    勤務先カナ住所1: '',
    勤務先漢字住所2: '',
    勤務先カナ住所2: '',
    漢字勤務先名: '',
    カナ勤務先名: '',
    勤務先電話番号: '',
    勤務先内線番号: '',
    勤務形態区分: '',
    勤務先所属部署: '',
    勤務先業種コード: '',
    勤務先職種コード: '',
    入社年月年: '',
    入社年月月: '',
    勤務先従業員数: '',
    給料日: '',
    ボーナス月１: '',
    ボーナス月２: '',
    月収金額: '',
    年収金額: '',
    保険種類: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  updatePhoneNumber: {
    電話番号: '',
    HP自宅連絡希望区分: '0',
    HP携帯電話連絡希望区分: '0',
    HP勤務先連絡希望区分: '0',
    HPSMS連絡希望区分: '0',
  },
  topPhoneNumber: '',
  phoneNumberValidationError: {
    電話番号: '',
  },
  updateMailService: {
    HP自宅連絡希望区分: '2',
    HP携帯電話連絡希望区分: '2',
    HP勤務先連絡希望区分: '2',
    HPSMS連絡希望区分: '2',
  },
  updateMailAddress: {
    メールアドレス: '',
    メールアドレス確認: '',
  },
  confirmMailAddress: '',
  inputMailService: {
    お得情報案内メール: '',
    返済日事前お知らせメール: '',
    返済忘れ連絡メール: '',
    新サービス一連番号: '',
  },
  mailAddressValidationError: {
    メールアドレス: '',
    メールアドレス確認: '',
  },
  webId: '',
  webIdChange: {
    ログインID: '',
    新しいログインID確認: '',
  },
  webIdChangeValidationError: {
    ログインID: '',
    新しいログインID確認: '',
  },
  addressInfo: {
    選択住所漢字: '',
    選択住所かな: '',
    住所情報: [],
  },
  searchedAddresses: {
    自宅漢字住所: '',
    自宅カナ住所: '',
  },
  messageFlag: '',
  phoneNumberWithHyphenValue: '',
  registeredMailAddressNoMask: '',
  workPlaceParams: {
    漢字勤務先名: '',
    勤務先電話番号: '',
    処理日時: '',
  },
  changeButtonFlags: {
    addressChangeFlag: '',
    workPlaceChangeFlag: '',
    phoneChangeFlag: '',
  },
};

const slice = createSlice({
  name: 'customer-attributes',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<KType>) => {
      state.top = action.payload;
    },
    updateNewMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.updateMailAddress.メールアドレス = action.payload;
    },
    updateNewMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.updateMailAddress.メールアドレス確認 = action.payload;
    },
    clearNewMailAddress: (state: StateType) => {
      state.updateMailAddress = initialState.updateMailAddress;
    },
    updateConfirmMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.confirmMailAddress = action.payload;
    },
    // メールアドレス変更
    updateMailServiceAdvance: (state: StateType, action: PayloadAction<string>) => {
      state.inputMailService.返済日事前お知らせメール = action.payload;
    },
    updateMailServiceDeals: (state: StateType, action: PayloadAction<string>) => {
      state.inputMailService.お得情報案内メール = action.payload;
    },
    updateMailServiceDunning: (state: StateType, action: PayloadAction<string>) => {
      state.inputMailService.返済忘れ連絡メール = action.payload;
    },
    updateNewServiceNumber: (state: StateType, action: PayloadAction<string>) => {
      state.inputMailService.新サービス一連番号 = action.payload;
    },
    clearMailService: (state: StateType) => {
      state.inputMailService = initialState.inputMailService;
    },
    updateMailAddressValidationError: (state: StateType, action: PayloadAction<mailAddressValidationErrorStateType>) => {
      state.mailAddressValidationError = action.payload;
    },
    clearMailAddressValidationError: (state: StateType) => {
      state.mailAddressValidationError = initialState.mailAddressValidationError;
    },
    // 住所・電話番号変更
    updatePostalCode: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.自宅郵便番号 = action.payload;
    },
    updatePrefectureAndCity: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.自宅住所漢字1 = action.payload;
    },
    updatePrefectureAndCityKana: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.自宅住所カナ1 = action.payload;
    },
    updateAddressAndBuilding: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.自宅住所漢字2 = action.payload;
    },
    updateAddressAndBuildingKana: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.自宅住所カナ2 = action.payload;
    },
    updateHabition: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.居住形態区分 = action.payload;
    },
    updateInhabitedYear: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.居住開始年月年 = action.payload;
    },
    updateInhabitedMonth: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.居住開始年月月 = action.payload;
    },
    updateRentAndLoan: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.家賃住宅ローン = action.payload;
    },
    updateNumberOfLiveTogetherWith: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.本人を除く住居人数 = action.payload;
    },
    updatePhoneNumberExists: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.電話番号有無 = action.payload;
    },
    updatePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.電話番号 = action.payload;
    },
    updateDesiredContactInformationInPhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.HP自宅連絡希望区分 = action.payload;
    },
    updateDesiredContactInformationInSmartphoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.HP携帯電話連絡希望区分 = action.payload;
    },
    updateDesiredContactInformationInWorkPlacePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.HP勤務先連絡希望区分 = action.payload;
    },
    updateDesiredContactInformationInSMS: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.HPSMS連絡希望区分 = action.payload;
    },
    updateProcessingDateTime: (state: StateType, action: PayloadAction<string>) => {
      state.updatetAddressPhoneNumber.処理日時 = action.payload;
    },
    updateAddressPhoneNumberOfIsChangeMailAddress: (state: StateType) => {
      state.updatetAddressPhoneNumber.isChangeMailAddress = !state.updatetAddressPhoneNumber.isChangeMailAddress;
    },
    updateAddressPhoneNumberValidationError: (state: StateType, action: PayloadAction<addressPhoneNumberValidationErrorStateType>) => {
      state.addressPhoneNumberValidationError = action.payload;
    },
    clearAddressPhoneNumberValidationError: (state: StateType) => {
      state.addressPhoneNumberValidationError = initialState.addressPhoneNumberValidationError;
    },
    clearAddressPhoneNumberInputAll: (state: StateType) => {
      state.updatetAddressPhoneNumber = initialState.updatetAddressPhoneNumber;
    },
    // 勤務先住所・電話番号変更
    updateWorkPlaceChangeReason: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.変更理由区分 = action.payload;
    },
    updateWorkPlaceName: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.漢字勤務先名 = action.payload;
    },
    updateWorkPlaceNameKana: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.カナ勤務先名 = action.payload;
    },
    updateWorkPlacePostalCode: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先郵便番号 = action.payload;
    },
    updateWorkPlacePrefectureAndCity: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先漢字住所1 = action.payload;
    },
    updateWorkPlacePrefectureAndCityKana: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先カナ住所1 = action.payload;
    },
    updateWorkPlaceAddressAndBuilding: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先漢字住所2 = action.payload;
    },
    updateWorkPlaceAddressAndBuildingKana: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先カナ住所2 = action.payload;
    },
    updateWorkPlacePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先電話番号 = action.payload;
    },
    updateWorkPlaceInlinePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先内線番号 = action.payload;
    },
    updateWorkPlaceWorkStyle: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務形態区分 = action.payload;
    },
    updateWorkPlaceWorkDivision: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先所属部署 = action.payload;
    },
    updateWorkPlaceIndustryCode: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先業種コード = action.payload;
    },
    updateWorkPlaceOccupationCode: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先職種コード = action.payload;
    },
    updateWorkPlaceHiredYear: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.入社年月年 = action.payload;
    },
    updateWorkPlaceHiredMonth: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.入社年月月 = action.payload;
    },
    updateWorkPlaceNumberOfEmployees: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.勤務先従業員数 = action.payload;
    },
    updateWorkPlacePayday: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.給料日 = action.payload;
    },
    updateWorkPlaceBonusPayMonth1: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.ボーナス月１ = action.payload;
    },
    updateWorkPlaceBonusPayMonth2: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.ボーナス月２ = action.payload;
    },
    updateWorkPlaceMonthlyIncome: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.月収金額 = action.payload;
    },
    updateWorkPlaceYearlyIncome: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.年収金額 = action.payload;
    },
    updateWorkPlaceInsuranceType: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.保険種類 = action.payload;
    },
    updateWorkPlaceDesiredContactInformationInPhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.HP自宅連絡希望区分 = action.payload;
    },
    updateWorkPlaceDesiredContactInformationInSmartphoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.HP携帯電話連絡希望区分 = action.payload;
    },
    updateWorkPlaceDesiredContactInformationInWorkPlacePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.HP勤務先連絡希望区分 = action.payload;
    },
    updateWorkPlaceDesiredContactInformationInSMS: (state: StateType, action: PayloadAction<string>) => {
      state.updateWorkPlaceAddressPhoneNumber.HPSMS連絡希望区分 = action.payload;
    },
    updateWorkPlaceAddressPhoneNumberOfIsChangeMailAddress: (state: StateType) => {
      state.updateWorkPlaceAddressPhoneNumber.isChangeMailAddress = !state.updateWorkPlaceAddressPhoneNumber.isChangeMailAddress;
    },
    updateWorkPlaceAddressPhoneNumberValidationError: (state: StateType, action: PayloadAction<workPlaceAddressPhoneNumberValidationErrorStateType>) => {
      state.workPlaceAddressPhoneNumberValidationError = action.payload;
    },
    clearWorkPlaceAddressPhoneNumberValidationError: (state: StateType) => {
      state.workPlaceAddressPhoneNumberValidationError = initialState.workPlaceAddressPhoneNumberValidationError;
    },
    clearWorkPlaceAddressPhoneNumberInputAll: (state: StateType) => {
      state.updateWorkPlaceAddressPhoneNumber = initialState.updateWorkPlaceAddressPhoneNumber;
    },
    // WebId変更
    updateWebId: (state: StateType, action: PayloadAction<string>) => {
      state.webId = action.payload;
    },
    updateWebIdChange: (state: StateType, action: PayloadAction<string>) => {
      state.webIdChange.ログインID = action.payload;
    },
    updateWebIdChangeConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.webIdChange.新しいログインID確認 = action.payload;
    },
    updateWebIdChangeValidationError: (state: StateType, action: PayloadAction<webIdChangeValidationErrorStateType>) => {
      state.webIdChangeValidationError = action.payload;
    },
    clearWebIdChangeValidationError: (state: StateType) => {
      state.webIdChangeValidationError = initialState.webIdChangeValidationError;
    },
    clearWebIdChangeInputAll: (state: StateType) => {
      state.webIdChange = initialState.webIdChange;
    },
    // 電話番号変更
    updateNumberChangePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatePhoneNumber.電話番号 = action.payload;
    },
    updateNumberChangeDesiredContactInformationInPhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatePhoneNumber.HP自宅連絡希望区分 = action.payload;
    },
    updateNumberChangeDesiredContactInformationInSmartphoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatePhoneNumber.HP携帯電話連絡希望区分 = action.payload;
    },
    updateNumberChangeDesiredContactInformationInWorkPlacePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updatePhoneNumber.HP勤務先連絡希望区分 = action.payload;
    },
    updateNumberChangeDesiredContactInformationInSMS: (state: StateType, action: PayloadAction<string>) => {
      state.updatePhoneNumber.HPSMS連絡希望区分 = action.payload;
    },
    clearNumberChange: (state: StateType) => {
      state.updatePhoneNumber = initialState.updatePhoneNumber;
    },
    updatePhoneNumberValidationError: (state: StateType, action: PayloadAction<PhoneNumberValidationErrorType>) => {
      state.phoneNumberValidationError = action.payload;
    },
    clearPhoneNumberValidationError: (state: StateType) => {
      state.phoneNumberValidationError = initialState.phoneNumberValidationError;
    },
    updateTopPhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.topPhoneNumber = action.payload;
    },
    // メールサービス変更
    updateMailServiceDesiredContactInformationInPhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateMailService.HP自宅連絡希望区分 = action.payload;
    },
    updateMailServiceDesiredContactInformationInSmartphoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateMailService.HP携帯電話連絡希望区分 = action.payload;
    },
    updateMailServiceDesiredContactInformationInWorkPlacePhoneNumber: (state: StateType, action: PayloadAction<string>) => {
      state.updateMailService.HP勤務先連絡希望区分 = action.payload;
    },
    updateMailServiceDesiredContactInformationInSMS: (state: StateType, action: PayloadAction<string>) => {
      state.updateMailService.HPSMS連絡希望区分 = action.payload;
    },
    clearMailServiceDesiredContactInformation: (state: StateType) => {
      state.updateMailService = initialState.updateMailService;
    },
    // 住所検索
    updateSelectedAddressInfo: (state: StateType, action: PayloadAction<string>) => {
      state.addressInfo.選択住所漢字 = action.payload;
    },
    updateSelectedAddressKanaInfo: (state: StateType, action: PayloadAction<string>) => {
      state.addressInfo.選択住所かな = action.payload;
    },
    updateAddressInfo: (state: StateType, action: PayloadAction<Array<searchedAddressesType>>) => {
      state.addressInfo.住所情報 = action.payload;
    },
    clearAddressInfo: (state: StateType) => {
      state.addressInfo = initialState.addressInfo;
    },
    // 変更なし画面のメッセージ出しわけ
    updateMessageFlag: (state: StateType, action: PayloadAction<string>) => {
      state.messageFlag = action.payload;
    },
    // 確認＆送信用電話番号(ハイフン付)
    updatePhoneNumberWithHyphenValue: (state: StateType, action: PayloadAction<string>) => {
      state.phoneNumberWithHyphenValue = action.payload;
    },
    updateWorkPlaceParams: (state: StateType, action: PayloadAction<WorkPlaceParamsType>) => {
      state.workPlaceParams = action.payload;
    },
    // 登録済みのメールアドレス（マスクなし）
    updateRegisteredMailAddressNoMask: (state: StateType, action: PayloadAction<string>) => {
      state.registeredMailAddressNoMask = action.payload;
    },
    clearRegisteredMailAddressNoMask: (state: StateType) => {
      state.registeredMailAddressNoMask = initialState.registeredMailAddressNoMask;
    },
    updateAddressChangeFlag: (state: StateType, action: PayloadAction<string>) => {
      state.changeButtonFlags.addressChangeFlag = action.payload;
    },
    updateWorkPlaceChangeFlag: (state: StateType, action: PayloadAction<string>) => {
      state.changeButtonFlags.workPlaceChangeFlag = action.payload;
    },
    updatePhoneChangeFlag: (state: StateType, action: PayloadAction<string>) => {
      state.changeButtonFlags.phoneChangeFlag = action.payload;
    },
    clearChangeButtonFlags: (state: StateType) => {
      state.changeButtonFlags = initialState.changeButtonFlags;
    },
  },
});

export default slice;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AccountInformationStateType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  accountInformation: {
    パーフェクト漢字銀行名: '',
    パーフェクト漢字支店名: '',
    パーフェクト振込預金種目: '',
    パーフェクト振込口座番号: '',
    パーフェクト口座名義: '',
    処理日時: '',
  },
};

const slice = createSlice({
  name: 'w-account-information',
  initialState,
  reducers: {
    updateAccountInformation: (state: StateType, action: PayloadAction<AccountInformationStateType>) => {
      state.accountInformation = action.payload;
    },
  },
});

export default slice;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { AppDispatch } from '../../store';

import { isLoading } from '../../modules/app';
import SfApiException from '../../errors/sf-api-exception';
import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import xSlice from '../../modules/x-login/module';
import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import {
  passwordValidator,
  passwordConfirmValidator,
  allPasswordIssuanceValidator,
} from '../../validators/x-login/login-validator';
import CognitoBackendService from '../../services/cognito-backend-service';
import { LoginQA } from '../../basics/QA/x_loginQA';
import { Message } from '../../utils/messages';
import { CustomValidator } from '../../utils/custom-validators';
import { removeWarnReloading, warnReloading } from '../../utils/warnReloading';

// X015
const ResetPassword: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');
  const { stylePattren } = useSelector((state: RootState) => state.pattern);

  const {
    loggedIn,
    memberInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    passwordValidationError,
    deviceRegistered,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    clearPasswordValidationError,
    updatePasswordValidationError,
    updateTempCognitoUserInfo,
  } = xSlice.actions;
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageNewPassword, setErrorMessageNewPassword] = useState('');
  const [view, setView] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  /**
   * パスワードの再設定
   * @returns
   */
  const changeCognitoUserPassword = async () => {
    if (!memberInfo.memberNo && !password) {
      return false;
    }

    try {
      await CognitoBackendService.request({
        body: {
          username: memberInfo.memberNo,
          newPassword: password,
        },
        endpoint: '/setPassword',
      });
      // パスワードを一時的に保持
      dispatch(updateTempCognitoUserInfo({
        memberId: memberInfo.memberNo,
        password,
      }));
      history.push(urls.X017);
      return true;
    } catch (err: any) {
      if (err.code === 'UserNotFoundException' && err.message === 'User does not exist.') {
        setErrorMessage(Message.vM059);
      } else {
        handleErrors(err);
      }
      return false;
    }
  };

  /**
   * 会員番号（username）によるユーザーを検索
   * @param memberNo
   * @returns
   */
  const findCognitoUserByMemberNo = async (memberNo: string) => {
    let resultMemberNo = '';

    try {
      // username からユーザー情報の取得
      const result = await CognitoBackendService.request({
        body: {
          username: memberNo,
        },
        endpoint: '/getUser',
      });
      resultMemberNo = result.Username;
    } catch (err: any) {
      if (err.code === 'UserNotFoundException' && err.message === 'User does not exist.') {
        setErrorMessage(Message.vM059);
      } else {
        handleErrors(err);
      }
    }

    return resultMemberNo;
  };

  /**
   * 【パスワード再発行】パスワードを再設定するボタン処理
   */
  const handleClickSetPassword = async () => {
    dispatch(isLoading(true));
    setView(true);

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    // 認証コードの送信
    try {
      warnReloading();
      const existsUserMemberNo = await findCognitoUserByMemberNo(memberInfo.memberNo);
      if (existsUserMemberNo !== '') {
        // Cognito 対象ユーザーのパスワードを更新する
        const isCompleted = await changeCognitoUserPassword();
        if (isCompleted) {
          const apiCommon: ApiCommon = new ApiCommon('X015', 'X-02', urls.X015, '');
          const commonPart = await apiCommon.getLoginCommonPart('', '');
          const dataPart = {
            データ部: {
              会員番号: '',
              端末登録有無: deviceRegistered,
            },
          };
          await McsBackendService.request(Object.assign(commonPart, dataPart));
        }
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  /**
   * パスワードのカスタムバリデーション
   * @returns boolean
   */
  const customValidationPassword = () => {
    // カスタムバリデーション
    const customValidator = new CustomValidator(password);

    // パスワードの入力バリデーション
    const passwordError = customValidator.tripleOver()
      .checkNotSupportSymboles()
      .checkBetweenLength(8, 16, '8文字以上16文字以内')
      .inputPolicyCheck()
      .getLastErrorMessage();
    if (passwordError !== '') {
      dispatch(updatePasswordValidationError({
        ...passwordValidationError,
        パスワード: passwordError,
      }));
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    return true;
  };

  /**
   * パスワード確認用のカスタムバリデーション
   * @returns boolean
   */
  const customValidationConfirmPassword = () => {
    // カスタムバリデーション
    const customValidator = new CustomValidator(confirmPassword);

    // パスワード確認用の入力バリデーション
    const confirmPasswordError = customValidator.tripleOver()
      .checkNotSupportSymboles()
      .invalidMatchPassword(password)
      .checkBetweenLength(8, 16, '8文字以上16文字以内')
      .inputPolicyCheck()
      .getLastErrorMessage();
    if (confirmPasswordError !== '') {
      dispatch(updatePasswordValidationError({
        ...passwordValidationError,
        パスワード確認用: confirmPasswordError,
      }));
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    return true;
  };

  /**
   * カスタムバリデーションの実施
   * @returns boolean
   */
  const customValidations = () => {
    if (!customValidationPassword()) {
      return false;
    }
    if (!customValidationConfirmPassword()) {
      return false;
    }
    return true;
  };

  const confirmValidation = () => {
    dispatch(clearPasswordValidationError());

    // バリデーション
    const validPasswordParams: { パスワード: string, パスワード確認用: string } = {
      パスワード: password,
      パスワード確認用: confirmPassword,
    };

    try {
      throwIfJoiValidationError(allPasswordIssuanceValidator(validPasswordParams, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    // Joi のバリデーションを通過した場合にカスタムバリデーションを実施
    return customValidations();
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updatePasswordValidationError({ ...passwordValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        case 'パスワード':
          throwIfJoiValidationError(passwordValidator({
            [name]: password,
          }));

          // カスタムバリデーションの実施
          customValidationPassword();
          break;
        case 'パスワード確認用':
          throwIfJoiValidationError(passwordConfirmValidator({
            [name]: confirmPassword,
            パスワード: password,
          }, { abortEarly: false }));

          // カスタムバリデーションの実施
          customValidationConfirmPassword();
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: passwordValidationError,
      updateValidationError: updatePasswordValidationError,
    });
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>パスワード再設定</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="blockNarrow">
                  <div className={`mb05gd ${errorMessage ? 'error-input' : ''}`}>
                    <p className="error-text">
                      <span className="icon--attention">{errorMessage}</span>
                    </p>
                    <div className="form__inputBox">
                      <div className={`form__inputBox__outer ${password !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <input
                          type="password"
                          minLength={8}
                          maxLength={16}
                          name="パスワード"
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={(e) => onBlurValidate(e.target.name)}
                        />
                        <span className="form__inputBox__placeholder">パスワード</span>
                      </div>
                      <p className="error-comment">{passwordValidationError.パスワード || errorMessageNewPassword}</p>
                    </div>
                    <span className="fsXS icon--check">8桁以上で、半角英数字・記号を入力してください</span><br />
                    <span className="fsXS icon--check">英大文字・小文字、数字・記号から3種を含めてください（例：Aa1、a1!）</span><br />
                    <span className="fsXS icon--check">同じ文字は3つ以上連続して使用できません（例：aaa、111）</span><br />
                  </div>
                  <div className={`mbXL mtFLAT ${errorMessage ? 'error-input' : ''}`}>
                    <p className="error-text">
                      <span className="icon--attention">{errorMessage}</span>
                    </p>
                    <div className="form__inputBox">
                      <div className={`form__inputBox__outer ${confirmPassword !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <input
                          type="password"
                          minLength={8}
                          maxLength={16}
                          name="パスワード確認用"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          onBlur={(e) => onBlurValidate(e.target.name)}
                        />
                        <span className="form__inputBox__placeholder">パスワード（確認用）</span>
                      </div>
                      <p className="error-comment">{passwordValidationError.パスワード確認用}</p>
                    </div>
                  </div>
                  <div className="mbFLAT flexC">
                    <button className="btn btn--cv" onClick={handleClickSetPassword}>パスワードを再設定する</button>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default ResetPassword;

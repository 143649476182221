/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
  Link,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { AppDispatch } from '../../store';

import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import { isLoading } from '../../modules/app';
import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import SfApiException from '../../errors/sf-api-exception';
import xSlice from '../../modules/x-login/module';
import userSlice from '../../modules/user';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import {
  verificationCodeValidator,
} from '../../validators/x-login/login-validator';
import { LoginQA } from '../../basics/QA/x_loginQA';
import { removeWarnReloading, warnReloading } from '../../utils/warnReloading';
import { dateToString } from '../../utils/format';

// X009
const VerificationCodeInputFirstTime: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');
  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    loggedIn,
    deviceInfo,
    memberInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    updateDeviceInfo,
  } = userSlice.actions;

  const {
    inputUserInfo,
    verificationCodeValidationError,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    updateVerificationCodeValidationError,
    clearVerificationCodeValidationError,
    updateDeviceRegistered,
    updateWebIdRegistered,
    updateRegisteredEMailAddress,
  } = xSlice.actions;

  const [view, setView] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  /**
   * 【認証コード入力（初回ID・パスワード発行時）画面】送信ボタン処理
   */
  const handleClickCodeSend = async () => {
    dispatch(isLoading(true));
    setView(true);

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    // 認証コードの送信
    try {
      warnReloading();
      const apiCommon: ApiCommon = new ApiCommon('X009', 'X-02', urls.X009, '');
      const commonPart = await apiCommon.getLoginCommonPart(verificationCode, deviceInfo.distributionId);
      const dataPart = {
        データ部: {
          会員番号: '',
          端末登録有無: '',
          メールアドレス: '',
        },
      };
      const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateDeviceRegistered(json.データ部.端末登録有無));
      dispatch(updateWebIdRegistered(json.データ部.メールアドレス.toLowerCase()));
      dispatch(updateRegisteredEMailAddress(json.データ部.メールアドレス));
      // ID/パスワード
      history.push(urls.X010);
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  /**
   * 【認証コード入力（初回ID・パスワード発行時】認証コードを再送するボタン処理
   */
  const handleClickCodeResend = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(isLoading(true));
    setView(true);

    try {
      const apiCommon: ApiCommon = new ApiCommon('X009', 'X-03', urls.X009, '');
      const commonPart = await apiCommon.getLoginCommonPart(verificationCode, deviceInfo.distributionId);
      const dataPart = {
        データ部: {
          会員番号: memberInfo.memberNo,
          配信ID: '',
        },
      };
      const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: json.データ部.配信ID, fromUrl: urls.X009 }));
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * 【認証コード入力（初回ID・パスワード発行時】SMSをご利用できないお客様ボタン処理
   */
  const handleClickNotUsedSMS = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(isLoading(true));
    setView(true);
    try {
      const apiCommon: ApiCommon = new ApiCommon('X009', 'X-04', urls.X009, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '');
      const dataPart = {
        データ部: {
          会員番号: memberInfo.memberNo,
          自宅電話番号: '',
          携帯電話番号: '',
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateDeviceInfo({ ...deviceInfo, fromUrl: urls.X009 }));
      history.push(urls.X003);
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * 【認証コード入力（初回ID・パスワード発行時】認証コードが届かないお客様ボタン処理
   */
  const handleClicCodeNotReceive = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(isLoading(true));
    setView(true);
    try {
      const apiCommon: ApiCommon = new ApiCommon('X009', 'X-05', urls.X009, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '');
      const dataPart = {
        データ部: {
          会員番号: memberInfo.memberNo,
          代表電話番号: '',
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
      history.push(urls.X004);
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * エラー用ハンドラー
   * @param err Error
   */
  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        case 'WAG20064':
        case 'WAG20066':
          history.push(urls.X005);
          break;
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: verificationCodeValidationError,
      updateValidationError: updateVerificationCodeValidationError,
    });
  };

  const confirmValidation = () => {
    dispatch(clearVerificationCodeValidationError());

    // バリデーション
    const validParams: { 認証コード: string } = {
      認証コード: verificationCode,
    };
    try {
      throwIfJoiValidationError(verificationCodeValidator(validParams, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }
    return true;
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updateVerificationCodeValidationError({ ...verificationCodeValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        case '認証コード':
          throwIfJoiValidationError(verificationCodeValidator({
            [name]: verificationCode,
          }));
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>認証コード入力</h1>
                  </div>
                  <p className="mbFLAT">
                    ご登録の連絡先に、SMS認証コードを送信しています。記載の認証コード4桁を入力し、「送信」ボタンを押してください。<br />
                    なお、認証コードの有効期限は30秒です。
                  </p>
                </div>
              </div>
              <div className="blockSub mb05gd mtFLAT">
                <div className="blockNarrow">
                  <p className="label fs2XS mbXS">お名前</p>
                  <p className="mtFLAT mbL">{inputUserInfo.カナ氏名}</p>
                  <p className="label fs2XS mbXS">生年月日</p>
                  <p className="mtFLAT mbL">{dateToString(inputUserInfo.生年月日)}</p>
                  <p className="label fs2XS mbXS">届け出済みの電話番号</p>
                  <p className="mtFLAT mbL">{inputUserInfo.電話番号}</p>
                  <div className="form__inputBox mb2XL">
                    <div className={`form__inputBox__outer ${verificationCode !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                      <input
                        type="text"
                        minLength={4}
                        maxLength={4}
                        name="認証コード"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                      />
                      <span className="form__inputBox__placeholder">認証コード（4桁）</span>
                    </div>
                    <p className="error-comment">{verificationCodeValidationError.認証コード || errorMessage}</p>
                  </div>
                  <div className="mb05gd flexC">
                    <button className="btn btn--cv" onClick={handleClickCodeSend}>送信</button>
                  </div>
                  <div className="mbXL flexC">
                    <Link to={urls.X008} className="btn btn--text">お客さま情報の入力に戻る</Link>
                  </div>
                  <div className="aR mbM">
                    <a className="link linkArrowRight mbM" href="#####" onClick={handleClickNotUsedSMS}>SMSを利用できないお客さま</a>
                  </div>
                  <div className="aR mbFLAT">
                    <a className="link linkArrowRight" href="#####" onClick={handleClicCodeNotReceive}>認証コードが届かないお客さま</a>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA codeResendFunction={handleClickCodeResend} pathName={urls.X009} />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default VerificationCodeInputFirstTime;

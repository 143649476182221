/** @jsxRuntime classic */
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import 'abortcontroller-polyfill';
import 'regenerator-runtime/runtime';

import * as React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  Router, Route, Switch, useLocation,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/es/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { store, persistor } from './store';
import PatternRoutes from './PatternRoutes';

import AwsAuthService from './services/aws-auth-service';
import { updateLoggedIn } from './modules/user';
import { isLoading } from './modules/app';
import history from './utils/history';
import urls from './utils/urls';
import { updateViewPattern } from './modules/pattern';
import { updateDirectAccess } from './modules/transition';

import Login from './pages/x-login/Login';
import VerificationCodeInput from './pages/x-login/VerificationCodeInput';
import VerificationCodeInputIVR from './pages/x-login/VerificationCodeInputIVR';
import VerificationCodeNotArrived from './pages/x-login/VerificationCodeNotArrived';
import VerificationCodeError from './pages/x-login/VerificationCodeError';
import AccountLock from './pages/x-login/AccountLock';
import Maintenance from './pages/x-login/Maintenance';
import NewIDAndPassword from './pages/x-login/NewIDAndPassword';
import VerificationCodeInputFirstTime from './pages/x-login/VerificationCodeInputFirstTime';
import SettingFirstIDAndPassword from './pages/x-login/SettingFirstIDAndPassword';
import SettingIDAndPasswordComplete from './pages/x-login/SettingIDAndPasswordComplete';
import ResetIDAndPasswordInput from './pages/x-login/ResetIDAndPasswordInput';
import VerificationCodeInputAfterSetting from './pages/x-login/VerificationCodeInputAfterSetting';
import ResetID from './pages/x-login/ResetID';
import ResetPassword from './pages/x-login/ResetPassword';
import ResetIDAndPassword from './pages/x-login/ResetIDAndPassword';
import SettingIDAndPasswordCompleteNextLogin from './pages/x-login/SettingIDAndPasswordCompleteNextLogin';
//
import SPAppliGateway from './pages/x-login/SPAppliGateway';
import SPAppliGateway2 from './pages/x-login/SPAppliGateway2';

import Stylesheets from './components/Stylesheets';
import { isAllowedUrl } from './utils/allowedDirectAccessUrls';
import Analytics from './components/Analytics';
import { isApp } from './utils/device';
import ApiCommon from './utils/api-common';
import FinancialInstitutionGateway from './pages/A/i-web-repayment/FinancialInstitutionGateway';

declare global {
  interface Window {
    IGLOO: any; // Blackboxのオブジェクト取得用
    webapi2: any; // アプリ側へのPost用
    ReactNativeWebView: any; // アプリ側へのPost用
  }
}

/**
 * アプリケーション読み込み時に認証状態を設定する。
 * ログイン前のURL指定の場合は未認証扱いとする
 */
async function setIsloggedIn() {
  store.dispatch(isLoading(true));
  const authenticated = await AwsAuthService.isAuthenticated().finally(() => store.dispatch(isLoading(false)));
  const isBeforeLogin = (document.location.pathname !== urls.LOGIN) // ログイン画面
    && (document.location.pathname.indexOf('/x') === -1); // ログイン前に遷移できる画面
  store.dispatch(updateLoggedIn(authenticated && isBeforeLogin));
}

/**
 * 認証済みのユーザの場合は表示パターンを取得する。
 */
async function getViewPatternIfLoggedIn() {
  const states = store.getState();
  const user = Object.getOwnPropertyDescriptor(states, 'user')?.value;
  const pattern = Object.getOwnPropertyDescriptor(states, 'pattern')?.value;
  if (user.loggedIn) {
    // MCS_APIから表示パターンを取得する。
    store.dispatch(updateViewPattern(pattern.viewPattern));
  } else if (document.location.pathname === urls.X002
    || document.location.pathname === urls.X003) {
    // Cognitoでの認証が終わった後でくる画面の場合は何もしない
  } else {
    // blackbox 情報の退避
    let bb: string|null;
    if (ApiCommon.isSessionStorageAvailable()) {
      bb = sessionStorage.getItem('io_blackbox');
    } else {
      bb = localStorage.getItem('io_blackbox');
    }
    // ローカルストレージがクリアされるため事前にapplication_bannerを取得する
    const localApplicationBannerLifespan = localStorage.getItem('application_banner_lifespan');

    // セッションストレージ、ローカルストレージのクリア、AWS のサインアウト
    sessionStorage.clear();
    localStorage.clear();
    await AwsAuthService.signOut();
    // ローカルストレージがクリアされるため事前にapplication_bannerを取得したものを再設定する
    if (localApplicationBannerLifespan) {
      localStorage.setItem('application_banner_lifespan', `${new Date(localApplicationBannerLifespan)}`);
    }
    // 退避していた blackbox 情報を復元
    if (bb) {
      if (ApiCommon.isSessionStorageAvailable()) {
        sessionStorage.setItem('io_blackbox', `${bb}`);
      } else {
        localStorage.setItem('io_blackbox', `${bb}`);
      }
    }
  }
  return null;
}

/**
 * url直打ちアクセスを許可しないページがindexを通る場合にフラグを立てる
 */
async function accessCheck() {
  const targetEnv = process.env.REACT_APP_TARGET_ENV;
  if (!isAllowedUrl(document.location.pathname.toLocaleLowerCase()) && targetEnv === 'production') {
    store.dispatch(updateDirectAccess(true));
  }
}

/**
 * 画面遷移時に画面上部へ移動させる。
 * @returns null
 */
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const analyticsTag = () => {
  const { pathname } = document.location;
  if ((pathname === urls.SPAPPLI || urls.SPAPPLI2) && isApp()) {
    return (<></>);
  }
  return (<Route component={Analytics} />);
};

/**
 * 画面構成
 */
setIsloggedIn().then(getViewPatternIfLoggedIn).then(accessCheck).then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <ScrollToTop />
          <HelmetProvider>
            <Stylesheets />
            <Switch>
              {/* X */}
              <Route exact path={urls.LOGIN} component={Login} />
              <Route exact path={urls.X002} component={VerificationCodeInput} />
              <Route exact path={urls.X003} component={VerificationCodeInputIVR} />
              <Route exact path={urls.X004} component={VerificationCodeNotArrived} />
              <Route exact path={urls.X005} component={VerificationCodeError} />
              <Route exact path={urls.X006} component={AccountLock} />
              <Route exact path={urls.X007} component={Maintenance} />
              <Route exact path={urls.X008} component={NewIDAndPassword} />
              <Route exact path={urls.X009} component={VerificationCodeInputFirstTime} />
              <Route exact path={urls.X010} component={SettingFirstIDAndPassword} />
              <Route exact path={urls.X011} component={SettingIDAndPasswordComplete} />
              <Route exact path={urls.X012} component={ResetIDAndPasswordInput} />
              <Route exact path={urls.X013} component={VerificationCodeInputAfterSetting} />
              <Route exact path={urls.X014} component={ResetID} />
              <Route exact path={urls.X015} component={ResetPassword} />
              <Route exact path={urls.X016} component={ResetIDAndPassword} />
              <Route exact path={urls.X017} component={SettingIDAndPasswordCompleteNextLogin} />
              {/* X Appli */}
              <Route exact path={urls.SPAPPLI} component={SPAppliGateway} />
              {/* I */}
              <Route exact path={urls.I007} component={FinancialInstitutionGateway} />
              {/* ABテストパターン */}
              <PatternRoutes />
              {analyticsTag()}
            </Switch>
            {analyticsTag()}
          </HelmetProvider>
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('content'),
  );
});

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    商品名: '',
    振込種別: '',
    振込依頼年月日: '',
    振込予定年月日: '',
    振込金額: '',
    依頼状況: '',
    振込年月日: '',
    振込時間: '',
    銀行名漢字: '',
    支店名漢字: '',
    口座番号: '',
    預金種目: '',
    処理日時: '',
  },
  bankInfo: {
    口座枝番: '',
    商品名: '',
    振込種別: '',
    振込依頼年月日: '',
    振込予定年月日: '',
    振込金額: '',
    依頼状況: '',
  },
};

const slice = createSlice({
  name: 'f',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<FType>) => {
      state.top = action.payload;
    },
    updateProcessingDateTime: (state: StateType, action: PayloadAction<string>) => {
      state.top.処理日時 = action.payload;
    },
  },
});

export default slice;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { AppDispatch } from '../../store';

import { isLoading } from '../../modules/app';
import SfApiException from '../../errors/sf-api-exception';
import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import xSlice from '../../modules/x-login/module';
import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import {
  idValidator,
  passwordValidator,
  passwordConfirmValidator,
  idAndPasswordRegisterValidator,
} from '../../validators/x-login/login-validator';
import CognitoBackendService from '../../services/cognito-backend-service';
import { LoginQA } from '../../basics/QA/x_loginQA';
import { CustomValidator } from '../../utils/custom-validators';
import { removeWarnReloading, warnReloading } from '../../utils/warnReloading';

// X016
const ResetIDAndPassword: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');
  const { stylePattren } = useSelector((state: RootState) => state.pattern);

  const {
    loggedIn,
    memberInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    idAndPasswordValidationError,
    deviceRegistered,
    webIdRegistered,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    updateIdAndPasswordValidationError,
    clearIdAndPasswordValidationError,
    updateTempCognitoUserInfo,
    updateWebIdRegistered,
    clearRegisteredEMailAddress,
    clearInputUserInfo,
  } = xSlice.actions;
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageNewPassword, setErrorMessageNewPassword] = useState('');
  const [view, setView] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
    dispatch(clearInputUserInfo());
  }, []);

  /**
   * IDとパスワードの再設定（ユーザーの作成）
   * @returns
   */
  const createCognitoUser = async () => {
    if (!memberInfo.memberNo && !webIdRegistered && !password) {
      return false;
    }

    // Cognito ユーザーを検索し、ユーザーが存在していれば情報の更新、存在していなければ作成する
    try {
      // 既存ユーザーの取得
      const existsUserByWebId = await findCognitoUserByWebId(webIdRegistered);
      const existsUserByMemberNo = await findCognitoUserByMemberNo(memberInfo.memberNo);
      let isCreate = false;
      let isDelete = false;
      if (existsUserByMemberNo.memberId === '' && existsUserByMemberNo.memberNo === '') {
        // 今のユーザーの会員番号に一致するユーザーが Cognito に存在しない
        if (existsUserByWebId.memberId === webIdRegistered) {
          // 入力した ID が既存ユーザーの ID と一致した場合、ID 重複のためエラー
          handleErrors({
            code: 'AliasExistsException',
            message: 'Already found an entry for the provided username.',
          });
          return false;
        }
        isCreate = true;
      } else if (existsUserByMemberNo.memberId === '' && existsUserByMemberNo.memberNo !== '') {
        // 入力した ID が既存ユーザーの ID と一致しても会員番号が同じであれば登録可能
        if (existsUserByWebId.memberId === webIdRegistered) {
          // 入力した ID が既存ユーザーの ID と同じでも会員番号が一致していれば登録可能
          if (existsUserByWebId.memberNo === memberInfo.memberNo) {
            isDelete = true;
            isCreate = true;
          } else {
            // 入力した ID が既存ユーザーの ID と同じで、会員番号が異なる場合は重複エラー
            handleErrors({
              code: 'AliasExistsException',
              message: 'Already found an entry for the provided username.',
            });
            return false;
          }
        } else {
          isDelete = true;
          isCreate = true;
        }
      } else {
        // 今のユーザーの会員番号と WebID で検索したユーザーの会員番号が不一致の場合、ID 重複のためエラー
        handleErrors({
          code: 'AliasExistsException',
          message: 'Already found an entry for the provided username.',
        });
        return false;
      }

      // ユーザーの削除
      if (isDelete) {
        // ユーザーが存在する場合は先にユーザーを削除してから新たに作成する
        await CognitoBackendService.request({
          body: {
            username: memberInfo.memberNo,
          },
          endpoint: '/deleteUser',
        });
      }
      if (isCreate) {
        // ユーザーの登録
        await CognitoBackendService.request({
          body: {
            username: memberInfo.memberNo,
            password,
            preferredUsername: webIdRegistered,
          },
          endpoint: '/createUserForce',
        });
        // ログイン ID とパスワードを一時的に保持
        dispatch(updateTempCognitoUserInfo({
          memberId: memberInfo.memberNo,
          userName: webIdRegistered,
          password,
        }));
        history.push(urls.X011);
      }
      return true;
    } catch (err: any) {
      handleErrors(err);
      return false;
    }
  };

  /**
   * 会員番号（username）によるユーザーを検索
   * @param webId
   * @returns
   */
  const findCognitoUserByMemberNo = async (webId: string) => {
    const resultUser = {
      memberNo: '',
      memberId: '',
    };

    try {
      // username からユーザー情報の取得
      const result = await CognitoBackendService.request({
        body: {
          username: memberInfo.memberNo,
        },
        endpoint: '/getUser',
      });

      resultUser.memberNo = result.Username;
      for (let j = 0, m = result.Attributes.length; j < m; j += 1) {
        const element = result.Attributes[j];
        if (element.Name === 'preferred_username') {
          if (element.Value === webId) {
            resultUser.memberId = element.Value;
            break;
          }
        }
      }
    } catch (err: any) {
      //
    }

    return resultUser;
  };

  /**
   * WebID（preferredUsername）によるユーザーを検索
   * @param webId
   * @returns
   */
  const findCognitoUserByWebId = async (webId: string) => {
    const resultUser = {
      memberNo: '',
      memberId: '',
    };

    try {
      // preferredUsername からユーザー情報の取得
      const result = await CognitoBackendService.request({
        body: {
          preferredUsername: webId,
        },
        endpoint: '/getListUser',
      });
      for (let i = 0, l = result.Users.length; i < l; i += 1) {
        const user = result.Users[i];
        resultUser.memberNo = user.Username;
        for (let j = 0, m = user.Attributes.length; j < m; j += 1) {
          const element = user.Attributes[j];
          if (element.Name === 'preferred_username') {
            if (element.Value === webId) {
              resultUser.memberId = element.Value;
              break;
            }
          }
        }
        if (resultUser.memberId !== '') {
          break;
        }
      }
    } catch (err: any) {
      handleErrors(err);
    }

    return resultUser;
  };

  // SFDM3_02-1554 の対応で封印
  // /**
  //  * ユーザー情報の取得（ID = メールアドレス照合用）
  //  */
  // async function getCognitoLoginId(): Promise<string> {
  //   try {
  //     const res = await CognitoBackendService.request({
  //       body: {
  //         username: memberInfo.memberNo,
  //       },
  //       endpoint: '/getUser',
  //     });

  //     for (let i = 0; i < res.UserAttributes.length; i += 1) {
  //       const element = res.UserAttributes[i];
  //       if (element.Name === 'preferred_username') {
  //         return element.Value;
  //       }
  //     }
  //   } catch (err: any) {
  //     if (err.code === 'UserNotFoundException' && err.message === 'User does not exist.') {
  //       // ユーザーがいない場合はエラー落ちさせないように空文字を返却
  //       return '';
  //     }
  //     handleErrors(err);
  //   }

  //   return '';
  // }

  /**
   * 【IDPW再発行】ID/パスワードを再設定するボタン処理
   */
  const handleClickSetIdAndPassword = async () => {
    dispatch(isLoading(true));
    setView(true);

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    // 認証コードの送信
    try {
      warnReloading();
      // SFDM3_02-1554 の対応で封印
      // // ユーザー情報を取得
      // const cognitoLoginId = await getCognitoLoginId();
      // // 登録済みの WebID が登録済みメールアドレスと一致
      // let inputMailAddress = '';
      // if (cognitoLoginId === registeredEMailAddress) {
      //   // メールアドレスの更新が必要かを判定
      //   if (webIdRegistered.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z]{2,}$/)
      //       && !webIdRegistered.match(/^.*(--|__|-@).*$/)) {
      //     // メールアドレスのため Cogniot と登録済みメールを更新
      //     inputMailAddress = webIdRegistered;
      //   }
      // }
      // Cognito 対象ユーザーの ID とパスワードを更新する
      const isCompleted = await createCognitoUser();
      if (isCompleted) {
        const apiCommon: ApiCommon = new ApiCommon('X016', 'X-02', urls.X016, '');
        const commonPart = await apiCommon.getLoginCommonPart('', '');
        const dataPart = {
          データ部: {
            会員番号: '',
            端末登録有無: deviceRegistered,
            // メールアドレス更新用: inputMailAddress,  // SFDM3_02-1554 の対応で封印
          },
        };
        await McsBackendService.request(Object.assign(commonPart, dataPart));
        dispatch(clearRegisteredEMailAddress());
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  /**
   * パスワードのカスタムバリデーション
   * @returns boolean
   */
  const customValidationPassword = () => {
    // カスタムバリデーション
    const customValidator = new CustomValidator(password);

    // パスワードの入力バリデーション
    const passwordError = customValidator.tripleOver()
      .checkNotSupportSymboles()
      .invalidMatchLoginId(webIdRegistered)
      .checkBetweenLength(8, 16, '8文字以上16文字以内')
      .inputPolicyCheck()
      .getLastErrorMessage();
    if (passwordError !== '') {
      dispatch(updateIdAndPasswordValidationError({
        ...idAndPasswordValidationError,
        パスワード: passwordError,
      }));
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    return true;
  };

  /**
   * パスワード確認用のカスタムバリデーション
   * @returns boolean
   */
  const customValidationConfirmPassword = () => {
    // カスタムバリデーション
    const customValidator = new CustomValidator(confirmPassword);

    // パスワード確認用の入力バリデーション
    const confirmPasswordError = customValidator.tripleOver()
      .checkNotSupportSymboles()
      .invalidMatchLoginId(webIdRegistered)
      .invalidMatchPassword(password)
      .checkBetweenLength(8, 16, '8文字以上16文字以内')
      .inputPolicyCheck()
      .getLastErrorMessage();
    if (confirmPasswordError !== '') {
      dispatch(updateIdAndPasswordValidationError({
        ...idAndPasswordValidationError,
        パスワード確認用: confirmPasswordError,
      }));
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    return true;
  };

  /**
   * カスタムバリデーションの実施
   * @returns boolean
   */
  const customValidations = () => {
    if (!customValidationPassword()) {
      return false;
    }
    if (!customValidationConfirmPassword()) {
      return false;
    }
    return true;
  };

  const confirmValidation = () => {
    dispatch(clearIdAndPasswordValidationError());

    // バリデーション
    const validParams: { ログインID: string, パスワード: string, パスワード確認用: string } = {
      ログインID: webIdRegistered,
      パスワード: password,
      パスワード確認用: confirmPassword,
    };

    try {
      throwIfJoiValidationError(idAndPasswordRegisterValidator(validParams, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    // Joi のバリデーションを通過した場合にカスタムバリデーションを実施
    return customValidations();
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updateIdAndPasswordValidationError({ ...idAndPasswordValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        case 'ログインID':
          throwIfJoiValidationError(idValidator({
            [name]: webIdRegistered,
          }));
          break;
        case 'パスワード':
          throwIfJoiValidationError(passwordValidator({
            [name]: password,
          }));

          // カスタムバリデーションの実施
          customValidationPassword();
          break;
        case 'パスワード確認用':
          throwIfJoiValidationError(passwordConfirmValidator({
            [name]: confirmPassword,
            パスワード: password,
          }, { abortEarly: false }));

          // カスタムバリデーションの実施
          customValidationConfirmPassword();
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
          break;
      }
    } else {
      handleCommonError(err);
    }
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: idAndPasswordValidationError,
      updateValidationError: updateIdAndPasswordValidationError,
    });
  };

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT mbFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>ID/パスワード再設定</h1>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd mtFLAT">
                <div className="blockNarrow">
                  <div className="form__inputBox mbL">
                    <div className="form__inputBox__outer form__inputBox__outer--valid">
                      <input
                        type="text"
                        placeholder="lake@shinseifinancial.co.jp"
                        minLength={6}
                        maxLength={45}
                        name="ログインID"
                        onChange={(e) => dispatch(updateWebIdRegistered(e.target.value))}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                        value={webIdRegistered}
                      />
                      <span className="form__inputBox__placeholder">ID</span>
                    </div>
                    <p className="error-comment">{idAndPasswordValidationError.ログインID}</p>
                    <ul className="listNote mbFLAT">
                      <li className="listNote__item">IDはメールアドレスまたは、6桁以上の半角英数字を入力してください。</li>
                    </ul>
                  </div>
                  <div className="form__inputBox mbL">
                    <div className={`form__inputBox__outer ${password !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                      <input
                        type="password"
                        minLength={8}
                        maxLength={16}
                        name="パスワード"
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                      />
                      <span className="form__inputBox__placeholder">パスワード</span>
                    </div>
                    <p className="error-comment">{idAndPasswordValidationError.パスワード || errorMessageNewPassword}</p>
                    <span className="fsXS icon--check">8桁以上で、半角英数字・記号を入力してください</span><br />
                    <span className="fsXS icon--check">英大文字・小文字、数字・記号から3種を含めてください（例：Aa1、a1!）</span><br />
                    <span className="fsXS icon--check">同じ文字は3つ以上連続して使用できません（例：aaa、111）</span>
                  </div>
                  <div className="form__inputBox mbXL">
                    <div className={`form__inputBox__outer ${confirmPassword !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                      <input
                        type="password"
                        minLength={8}
                        maxLength={16}
                        name="パスワード確認用"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                      />
                      <span className="form__inputBox__placeholder">パスワード（確認用）</span>
                    </div>
                    <p className="error-comment">{idAndPasswordValidationError.パスワード確認用}</p>
                  </div>
                  <div className="mbFLAT flexC">
                    <button className="btn btn--cv" onClick={handleClickSetIdAndPassword}>ID/パスワードを再設定する</button>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default ResetIDAndPassword;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CardStopInputType,
  CardStopConfirmType,
  ValidationErrorType,
  ChangePinValidationErrorType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  cardStopInput: {
    会員番号: '',
    口座番号: '',
    メールアドレス: '',
    カード有無: '',
    カード停止理由区分: '',
  },
  cardStopConfirm: {
    会員番号: '',
  },
  validationError: {
    カード停止理由: '',
    会員機能継続: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  changePinValidationError: {
    現暗証番号: '',
    新暗証番号: '',
    新暗証番号確認: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  cardStopReason: '01',
  remainMemberFunction: '1',
  inputCompletionDeliveryMailAddress: '',
  inputCompletionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  confirmMailAddress: '',
};

const slice = createSlice({
  name: 'o',
  initialState,
  reducers: {
    updateCardStopInput: (state: StateType, action: PayloadAction<CardStopInputType>) => {
      state.cardStopInput = action.payload;
    },
    updateCardStopConfirm: (state: StateType, action: PayloadAction<CardStopConfirmType>) => {
      state.cardStopConfirm = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    updateChangePinValidationError: (state: StateType, action: PayloadAction<ChangePinValidationErrorType>) => {
      state.changePinValidationError = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    clearChangePinValidationError: (state: StateType) => {
      state.changePinValidationError = initialState.changePinValidationError;
    },
    updateCardStopReason: (state: StateType, action: PayloadAction<string>) => {
      state.cardStopReason = action.payload;
    },
    updateRemainMemberFunction: (state: StateType, action: PayloadAction<string>) => {
      state.remainMemberFunction = action.payload;
    },
    updateConfirmMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.confirmMailAddress = action.payload;
    },
  },
});

export default slice;

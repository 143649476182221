import Joi from 'joi';
import ValidationError, { ValidationErrorDetail } from '../errors/validation-error';

/**
 * バリデーションエラー内容
 */
export type SimpleValidationErrorReturnType = ValidationErrorDetail;

/**
 * Joiのバリデーション結果を画面に表示しやすい形式に変換する。
 * @param result Joiのバリデーション結果
 * @returns バリデーションエラー内容
 */
export function simpleValidationError(result?: Joi.ValidationResult): SimpleValidationErrorReturnType[] | null {
  if (!result) return null;
  const { error } = result;
  if (error) {
    return convValidationErrorDetail(error);
  }
  // success
  return null;
}
/**
 * ValidationErrorを変換する
 * @param error Joi.ValidationError
 * @returns SimpleValidationErrorReturnType
 */
export function convValidationErrorDetail(error: Joi.ValidationError): ValidationErrorDetail[] {
  return error.details.map((obj) => {
    const field = obj.context?.key || obj.context?.label;
    const { message } = obj;
    return { field, message };
  });
}
/**
 * エラー部を変換する
 * @param error エラー部
 * @returns SimpleValidationErrorReturnType
 */
export function convertSfValidationErrorDetail(error: object): ValidationErrorDetail[] {
  return Object.entries(error).map(([key, value]) => ({ field: key, message: value }));
}

/**
 * バリデーションエラーの場合は例外を投げる。
 * @param result Joiのバリデーション結果
 * @throws ValidationError
 */
export function throwIfValidationError(result?: Joi.ValidationResult) {
  const error = simpleValidationError(result);
  if (error) {
    throw new ValidationError({ details: error, from: 'FRONTEND' });
  }
}

export function throwIfJoiValidationError(result?: Joi.ValidationResult) {
  if (result == null) return;
  const { error } = result;
  if (error) throw error;
}

/**
 * リンク URL を生成する
 * @param domain ドメイン
 * @param path ドメインの後ろに続くパス
 * @returns リンク URL
 */
function createLinkUrl(domain: string, path: string) {
  return path === '#' ? '####' : `${domain}${path}`;
}

/**
 * 一般サイトのリンクを構築する
 * @param path ドメインの後ろに続くパス
 * @returns リンク URL
 */
export function createLinkUrlGeneral(path: string = '') {
  return createLinkUrl(process.env.REACT_APP_GENERAL_SITE_DOMAIN || 'lakealsa.com', path);
}

/**
 * 会員サイトのリンクを構築する
 * @param path ドメインの後ろに続くパス
 * @returns リンク URL
 */
export function createLinkUrlMember(path: string = '') {
  return createLinkUrl(process.env.REACT_APP_MEMBER_SITE_DOMAIN || 'lakealsa.com', path);
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  StateType,
  BankInfoType,
  WebRepaymentInfoType,
  ValidationErrorType,
  FinancialInstitutionSiteInfoType,
  BankListType,
  BankType,
} from './types';

// 初期値
const initialState: StateType = {
  bankInfo: {
    口座枝番: '',
    商品名: '',
    次回支払日: '',
    完済金額: '',
    利息: '',
    毎回額: '',
  },
  webRepaymentInfo: {
    会員番号: '',
    口座枝番: '',
    商品名: '',
    次回ご返済期日: '',
    ご請求金額: '',
    元金充当額: '',
    利息充当額: '',
    遅延損害金充当額: '',
    '保留金・不足金': '',
    手数料充当額: '',
    下限金額: '',
    上限金額: '',
    銀行コード: '',
    銀行名漢字: '',
    メールアドレス: '',
  },
  confirmMailAddress: '',
  validationError: {
    ご返済金額: '',
    金融機関: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  inputRepaymentAmount: '',
  inputCompletionDeliveryMailAddress: '',
  inputCompletionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  billingNumber: '',
  financialInstitutionSiteInfo: {
    金融機関サイトURL: '',
    skno: '',
    bptn: '',
    bill: '',
    sn: '',
    id: '',
  },
  bankList: {
    会員番号: '',
    金融機関一覧: [],
  },
  selectedBank: {
    銀行コード: '',
    カナ銀行名: '',
    漢字銀行名: '',
  },
};

const slice = createSlice({
  name: 'i',
  initialState,
  reducers: {
    updateBankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.bankInfo = action.payload;
    },
    updateWebRepaymentInfo: (state: StateType, action: PayloadAction<WebRepaymentInfoType>) => {
      state.webRepaymentInfo = action.payload;
    },
    updateConfirmMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.confirmMailAddress = action.payload;
    },
    updateRepaymentAmount: (state: StateType, action: PayloadAction<string>) => {
      state.inputRepaymentAmount = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateBillingNumber: (state: StateType, action: PayloadAction<string>) => {
      state.billingNumber = action.payload;
    },
    updateFinancialInstitutionSiteInfo: (state: StateType, action: PayloadAction<FinancialInstitutionSiteInfoType>) => {
      state.financialInstitutionSiteInfo = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateBankList: (state: StateType, action: PayloadAction<BankListType>) => {
      state.bankList = action.payload;
    },
    updateSelectedBank: (state: StateType, action: PayloadAction<BankType>) => {
      state.selectedBank = action.payload;
    },
    clearInputValues: (state: StateType) => {
      state.inputRepaymentAmount = initialState.inputRepaymentAmount;
      state.inputCompletionDeliveryMailAddress = initialState.inputCompletionDeliveryMailAddress;
      state.inputCompletionDeliveryMailAddressConfirm = initialState.inputCompletionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
      state.billingNumber = initialState.billingNumber;
      state.financialInstitutionSiteInfo = initialState.financialInstitutionSiteInfo;
      state.selectedBank = initialState.selectedBank;
    },
  },
});
export default slice;

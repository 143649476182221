/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updateStylePattern } from '../../modules/pattern';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { LoginQA } from '../../basics/QA/x_loginQA';

// X006
const AccountLock: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { stylePattren } = useSelector((state: RootState) => state.pattern);

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        <section>
          <div className="blockMain mb05gd">
            <div className="blockNarrow">
              <div className="ttlH1 mtFLAT mbFLAT">
                <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                <h1>アカウントロック</h1>
              </div>
            </div>
          </div>
          <div className="blockSub mb05gd">
            <div className="blockNarrow">
              <div className="imgBox--center mb05gd">
                <img src="/img/A/X006/icon_01.svg" alt="" className="imgBox__img" width="100px" />
              </div>
              <p className="mbL">
                一定回数ログインに失敗したため、セキュリティ保護の観点からアカウントをロックしました。
                ロックを解除するには、「ID/パスワードを再設定する」ボタンより、再設定をお願いします。
              </p>
              <div className="flexC mbFLAT">
                <Link to={urls.X008} className="btn btn--cv">ID/パスワードを再設定する</Link>
              </div>
            </div>
          </div>
          <div className="blockSub mb05gd">
            <div className="ttlH2">
              <h2>よくあるご質問</h2>
            </div>
            <LoginQA />
          </div>
        </section>
      </main>
      <Route component={Footer} />
    </>
  );
};

export default AccountLock;

import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../store';
import { RootState } from '../../../rootReducer';
import hSlice from '../../../modules/h-limit-increase/module';
import urls from '../../../utils/urls';
import { isLoading } from '../../../modules/app';
import { updateViaK001 } from '../../../modules/specialmove';
import ApiCommon from '../../../utils/api-common';
import AwsAuthService from '../../../services/aws-auth-service';
import McsBackendService from '../../../services/mcs-backend-service';
import { handleCommonError } from '../../../utils/handle-errors';
import { updateStylePattern } from '../../../modules/pattern';
import SimpleModal from '../../../basics/Modal';
import { throwIfJoiValidationError } from '../../../utils/validation-helper';
import {
  borrowingsAmountValidator,
  housemateNumberValidator,
  housingLoanValidator,
  yearlyincomeValidator,
  allTogetherValidator,
} from '../../../validators/h-limit-increase/increase-examination-validator';
import { isMustReResubmitted, isSubmitting } from '../../../repository/CertificateReceiptStatusClassification';
import CashingBanner from '../../../basics/CashingBanner';

import topSlice from '../../../modules/t-top/module';
import { CommonFunctions } from '../../../utils/common-functions';
import { removeWarnReloading, warnReloading } from '../../../utils/warnReloading';
import { convertViewIdToLink } from '../../../utils/format';

// H037 増額審査申込(24年9月追加予定)
const IncreaseForm: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { viewPattern, stylePattren } = useSelector((state: RootState) => state.pattern);
  const {
    top,
    inputYearlyIncome,
    inputHousingLoan,
    inputHousemateNumber,
    inputBorrowingsAmount,
    validationError,
  } = useSelector((state: RootState) => state.hLimitIncrease);

  const {
    updateTop,
    updateYearlyIncome,
    updateHousingLoan,
    updateHousemateNumber,
    updateBorrowingsAmount,
    updateValidationError,
    clearValidationError,
  } = hSlice.actions;

  const {
    selectedBankInfo,
  } = useSelector((state: RootState) => state.top);
  const {
    updateCurrentTopInfo,
    updateSelectedBankInfo,
    updateInformations,
  } = topSlice.actions;

  const [openNoteNo, setOpenNoteNo] = React.useState(0);

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  // 初期処理
  useEffect(() => {
    initiate();
    return () => {
      dispatch(isLoading(false));
    };
  }, []);

  const initiate = async () => {
    await readIncrease();
  };

  const handleErrors = (err: any) => {
    handleCommonError(err, {
      validationErrorState: validationError,
      updateValidationError,
    });
  };

  const readIncrease = async () => {
    try {
      dispatch(isLoading(true));
      let bankBranchNumber: string = '';
      if (!selectedBankInfo.口座枝番) {
        // TOP を経由していないため TOP の情報とデフォルトの口座情報を取得しステートに保持
        const currentTopInfo: any = await CommonFunctions.getCurrentTopInfo(viewPattern);
        bankBranchNumber = currentTopInfo.selectedBankInfo.口座枝番;
        dispatch(updateCurrentTopInfo({
          ...currentTopInfo.topInfo.データ部,
          処理日時: currentTopInfo.topInfo.共通部.処理日時,
        }));
        dispatch(updateSelectedBankInfo(currentTopInfo.selectedBankInfo));
        dispatch(updateInformations(currentTopInfo.informations));
      } else {
        bankBranchNumber = selectedBankInfo.口座枝番;
      }
    } catch (err: any) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const onClickConfirmButton = async () => {
    dispatch(isLoading(true));
    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    try {
      warnReloading();
      const commonPart = await ApiCommon.getCommonPart('H037', 'H-02', urls.H037, viewPattern);
      const dataPart = {
        データ部: {
          会員番号: await AwsAuthService.getUsername(),
          口座枝番: selectedBankInfo.口座枝番,
          税込年収: inputYearlyIncome,
          家賃住宅ローン: inputHousingLoan,
          本人を除く同居人数: inputHousemateNumber,
          他社お借入れ総額: inputBorrowingsAmount,
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
      history.push(urls.H009);
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  const confirmValidation = () => {
    dispatch(clearValidationError());
    // バリデーション
    const validPramas: {
      税込年収: string,
      家賃住宅ローン: string,
      本人を除く同居人数: string,
      他社お借入れ総額: string,
      [key: string]: string
    } = {
      税込年収: inputYearlyIncome,
      家賃住宅ローン: inputHousingLoan,
      本人を除く同居人数: inputHousemateNumber,
      他社お借入れ総額: inputBorrowingsAmount,
    };
    try {
      throwIfJoiValidationError(allTogetherValidator(validPramas, { abortEarly: false }));
    } catch (err) {
      handleErrors(err);
      dispatch(isLoading(false));
      return false;
    }
    return true;
  };

  const onBlurValid = async (name: string) => {
    dispatch(updateValidationError({ ...validationError, [name]: '' }));
    try {
      switch (name) {
        case '税込年収':
          throwIfJoiValidationError(yearlyincomeValidator({
            [name]: inputYearlyIncome,
          }));
          break;
        case '家賃住宅ローン':
          throwIfJoiValidationError(housingLoanValidator({
            [name]: inputHousingLoan,
          }));
          break;
        case '本人を除く同居人数':
          throwIfJoiValidationError(housemateNumberValidator({
            [name]: inputHousemateNumber,
          }));
          break;
        case '他社お借入れ総額':
          throwIfJoiValidationError(borrowingsAmountValidator({
            [name]: inputBorrowingsAmount,
          }));
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleErrors(err);
    }
  };

  const handleNoteModalOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, noteNo: number) => {
    e.preventDefault();
    setOpenNoteNo(noteNo);
  };
  const handleNoteModalClose = () => {
    setOpenNoteNo(0);
  };

  const onClickHomeAddressChange = () => {
    dispatch(updateViaK001({
      isBack: true,
      backUrl: urls.K005,
    }));
    history.push(urls.K001);
  };

  const onClickWorkPlaceChange = () => {
    dispatch(updateViaK001({
      isBack: true,
      backUrl: urls.K008,
    }));
    history.push(urls.K001);
  };

  const pageBack = () => {
    history.push(convertViewIdToLink(top.次画面ID));
  };

  const createInputBlock = () => {
    if (top.営業時間外フラグ === '1') {
      return (<></>);
    }
    return (
      <div className="blockSub mb05gd">
        <h2 className="ttlH2">審査のお申込み</h2>
        <div className="blockNarrow">
          <p className="mbXL">申込みに必要な項目を入力し、「入力内容の確認へ」ボタンを押してください。</p>
          <p className="label mbXS fs2XS">ご自宅住所</p>
          <div className="col mbL">
            <div className="col__2-2">
              {top.ご自宅住所}
            </div>
            <div className="col__2-1 aR">
              <button className="btn--small mtFLAT" onClick={onClickHomeAddressChange}>変更</button>
            </div>
          </div>
          <p className="label mbXS fs2XS mtFLAT">お勤め先</p>
          <div className="col mbL">
            <div className="col__2-2">
              {top.お勤め先}
            </div>
            <div className="col__2-1 aR">
              <button className="btn--small mtFLAT" onClick={onClickWorkPlaceChange}>変更</button>
            </div>
          </div>
          <div className="mbL">
            <p className="error-text"><span className="icon--attention">テキストテキストテキストテキストテキストテキストテキスト</span></p>
            <div className="form__inputBox c-input-any-text baloonBox">
              <div className={`form__inputBox__outer ${inputYearlyIncome !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                <input
                  type="number"
                  name="税込年収"
                  value={inputYearlyIncome}
                  onChange={(e) => dispatch(updateYearlyIncome(e.target.value))}
                  onBlur={(e) => onBlurValid(e.target.name)}
                />
                <span className="form__inputBox__placeholder">年収</span>
              </div>
              <span className="text-any">万円（税込）</span>
              <p className="error-comment">{validationError.税込年収}</p>
              <span className="baloonBox__icon">
                <a href="/" className="openModal no-scroll" onClick={(e) => handleNoteModalOpen(e, 1)}>
                  <img src="img/A/icon_question.png" alt="" />
                </a>
              </span>
              <SimpleModal
                open={openNoteNo === 1}
                onClose={handleNoteModalClose}
                id="modal3"
                className="modal"
              >
                <ul className="listNote">
                  <li className="listNote__item">ご本人さまの年収のみです。</li>
                  <li className="listNote__item">勤務年数が1年未満の場合は、月収からの想定を入力してください。</li>
                </ul>
              </SimpleModal>
            </div>
          </div>
          <div className="mbL">
            <p className="error-text">
              <span className="icon--attention">テキストテキストテキストテキストテキストテキストテキスト</span>
            </p>
            <div className="form__inputBox c-input-any-text">
              <div className={`form__inputBox__outer ${inputHousingLoan !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                <input
                  type="number"
                  name="家賃住宅ローン"
                  value={inputHousingLoan}
                  onChange={(e) => dispatch(updateHousingLoan(e.target.value))}
                  onBlur={(e) => onBlurValid(e.target.name)}
                />
                <span className="form__inputBox__placeholder">家賃・住宅ローン</span>
              </div>
              <span className="text-any">万円／月</span>
              <p className="error-comment">{validationError.家賃住宅ローン}</p>
            </div>
          </div>
          <div className="mbL">
            <p className="error-text">
              <span className="icon--attention">テキストテキストテキストテキストテキストテキストテキスト</span>
            </p>
            <div className="form__inputBox c-input-any-text">
              <div className={`form__inputBox__outer ${inputHousemateNumber !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                <input
                  type="number"
                  name="本人を除く同居人数"
                  value={inputHousemateNumber}
                  onChange={(e) => dispatch(updateHousemateNumber(e.target.value))}
                  onBlur={(e) => onBlurValid(e.target.name)}
                />
                <span className="form__inputBox__placeholder">本人を除く住居人数</span>
              </div>
              <span className="text-any">人</span>
              <p className="error-comment">{validationError.本人を除く同居人数}</p>
            </div>
          </div>
          <div className="mb2XL">
            <p className="error-text">
              <span className="icon--attention">テキストテキストテキストテキストテキストテキストテキスト</span>
            </p>
            <div className="form__inputBox c-input-any-text baloonBox">
              <div className={`form__inputBox__outer ${inputBorrowingsAmount !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                <input
                  type="number"
                  name="他社お借入れ総額"
                  value={inputBorrowingsAmount}
                  onChange={(e) => dispatch(updateBorrowingsAmount(e.target.value))}
                  onBlur={(e) => onBlurValid(e.target.name)}
                />
                <span className="form__inputBox__placeholder">他社お借入れ総額</span>
              </div>
              <span className="text-any">万円（税込）</span>
              <p className="error-comment">{validationError.他社お借入れ総額}</p>
              <span className="baloonBox__icon">
                <a href="/" className="openModal no-scroll" onClick={(e) => handleNoteModalOpen(e, 2)}>
                  <img src="img/A/icon_question.png" alt="" />
                </a>
              </span>
              <SimpleModal
                open={openNoteNo === 2}
                onClose={handleNoteModalClose}
                id="modal4"
                className="modal"
              >
                <ul className="listNote">
                  <li className="listNote__item">銀行、信販、消費者金融のキャッシングお借入れの合計金額を入力してください。</li>
                  <li className="listNote__item">住宅ローン、自動車ローンは除く。</li>
                </ul>
              </SimpleModal>
            </div>
          </div>
          <div className="aC">
            <button className="btn btn--cv" onClick={onClickConfirmButton}>入力内容の確認へ</button>
          </div>
          <div className="aC mtS">
            <button onClick={pageBack} className="btn btn--text">検討可能なご利用限度額に戻る</button>
          </div>
        </div>
      </div>
    );
  };

  const createHeaderMessageArea = () => {
    if (isSubmitting(top.証明書受領状況区分)) {
      return (
        <div className="card">
          <p className="mtFLAT mbFLAT">
            提出いただいた収入証明書類を当社で確認中です。
          </p>
        </div>
      );
    }
    if (isMustReResubmitted(top.証明書受領状況区分)) {
      return (
        <div className="card">
          <p className="mtFLAT mbFLAT">
            収入証明書類の再提出が必要です。
          </p>
        </div>
      );
    }
    return <></>;
  };

  return (
    <main className="l-main">
      <section>
        <div className="blockMain mb05gd">
          <h1 className="ttlH1 mtFLAT mbFLAT">ご利用限度額の増額</h1>
          <div className={`wizard ${!isSubmitting(top.証明書受領状況区分) && !isMustReResubmitted(top.証明書受領状況区分) ? 'mbFLAT' : ''}`}>
            <ul className="wizard__list">
              <li className="wizard__list__current" />
              <li />
              <li />
            </ul>
          </div>
          {createHeaderMessageArea()}
        </div>
        {createInputBlock()}
        <div className="gd10 mb05gd aC">
          <CashingBanner />
        </div>
      </section>
    </main>
  );
};

export default IncreaseForm;
